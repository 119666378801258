import { Box, Button, Collapse, Typography } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { RiAddCircleLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import ReactQuill from "react-quill";
import {
  convertDateIntoDDMMYYYY,
  getLocalTimeZone,
} from "../../../utils/helper";
import axios from "axios";
import "react-quill/dist/quill.bubble.css";
import { setFeedback } from "../../../store/slices/feedbackSlice";
import Loader from "../../../components/common/Loader";

const APP_ID = process.env.REACT_APP_APP_ID;
const CENTRAL_URL = process.env.REACT_APP_CENTRAL_URL;

const ReleaseBox = ({
  index,
  id,
  version,
  date,
  userRole,
  dispatch,
  removeReleaseNote,
}) => {
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState("");
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);

  const getReleaseNoteDescription = async () => {
    try {
      if (!open) {
        setLoader(true);
        const paramsData = {
          id: id,
          timezone: getLocalTimeZone(),
        };
        const response = await axios.get(
          `${CENTRAL_URL}api/release-note`,
          { params: paramsData },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response?.data?.status) {
          setDescription(response?.data?.data?.description);
        } else {
          setDescription("");
          const payload = {
            code: response?.status,
            status: "fail",
            message: response?.data?.message || "Something went wrong.",
          };
          dispatch(setFeedback(payload));
        }
        // const response = await getAllReleaseNotes(params);
        setOpen(true);
      } else {
        setOpen(false);
      }
    } catch (error) {
      console.log(error);
      const errPayload = {
        code: error?.status,
        status: "fail",
        message: error?.message || "Something went wrong.",
      };
      dispatch(setFeedback(errPayload));
    } finally {
      setLoader(false);
    }
  };

  // const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  // const deleteReleaseNoteHandler = async () => {
  //     try {
  //         removeReleaseNote(id);
  //         const response = await deleteReleaseNote(id);
  //         console.log(response);
  //         if(response.data.status === 200) {
  //             showSuccessToast(response.data.message);
  //         } else {
  //             const payload = {
  //                 code: response.data.status,
  //                 status:"fail",
  //                 message: response.data.message || "Something went wrong."
  //             };
  //             dispatch(setFeedback(payload));
  //         }
  //     } catch (error) {
  //         console.log(error);
  //         const errPayload = {
  //             code: error.status,
  //             status: 'fail',
  //             message:error.message || "Something went wrong."
  //         };
  //         dispatch(setFeedback(errPayload));
  //     } finally {
  //         dispatch(setLoading(false));
  //     }
  // }

  return (
    <>
      <Box className="release-note">
        <Box className="heading">
          <Typography variant="p" component={"p"} className="version-name">
            Version {version.replace("v", "")}
          </Typography>
          <Typography variant="p" component={"p"} className="date">
            {index === 0 && "Last update:"} {convertDateIntoDDMMYYYY(date)}
          </Typography>

          <Box className="table-action-btn release-action-container">
            <BiChevronDown
              onClick={getReleaseNoteDescription}
              className={"down-arrow rotate-transition"}
              style={{ transform: `rotate(${open ? "180deg" : "0deg"})` }}
            />
            {/* {
                        userRole === ROLES.admin && 
                        <>
                            <Tooltip title='Edit Release Note'>
                                <Button className='btn btn-primary' 
                                    onClick={() => navigate(`/edit-release-note/${id}`, { state: { version, date } })}
                                >
                                    <FiEdit3 />
                                </Button>
                            </Tooltip>                                        
                                                                    
                            <Tooltip title='Remove Release Note'>
                                <Button className='btn btn-primary' onClick={() => setIsConfirmationModalOpen(true)}>
                                    <FiTrash2 />
                                </Button>
                            </Tooltip>
                        </>
                    } */}
          </Box>
        </Box>

        <Collapse in={open} timeout="auto" unmountOnExit={true}>
          <Box className="content">
            <ReactQuill
              value={description || "<h4>NO NOTES AVAILABLE</h4>"}
              theme="bubble"
              readOnly={true}
            />
          </Box>
        </Collapse>
      </Box>

      {/* {isConfirmationModalOpen && 
            <ConfirmationModal 
                isOpen={isConfirmationModalOpen} 
                setIsOpen={setIsConfirmationModalOpen} 
                title={"Release note"} 
                onConfirmHandler={deleteReleaseNoteHandler}
            />
        } */}
    </>
  );
};

const ReleaseNotes = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.data);
  const userRole = userData?.user_role?.id;

  const [rows, setRows] = useState(5);
  const [loader, setLoader] = useState(5);
  const loadMoreHandler = () => {
    setRows((prev) => prev + 5);
  };

  const [releaseNotes, setReleaseNotes] = useState({
    totalCount: 0,
    data: [],
  });

  useLayoutEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        setLoader(true);
        const params = {
          allNotes: 1,
          app_id: APP_ID,
          page: 1,
          rows: rows,
          timezone: getLocalTimeZone(),
        };

        const response = await axios.get(
          `${CENTRAL_URL}api/release-note`,
          { params: params },
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response?.data?.status) {
          setReleaseNotes({
            totalCount: response?.data?.data?.totalCount,
            data: response?.data?.data?.findAll,
          });
        } else {
          const payload = {
            code: response?.status,
            status: "fail",
            message: response?.data?.message || "Something went wrong.",
          };
          dispatch(setFeedback(payload));
        }
        setLoader(false);
      } catch (error) {
        console.log(error);
        const errPayload = {
          code: error?.status,
          status: "fail",
          message: error?.message || "Something went wrong.",
        };
        dispatch(setFeedback(errPayload));
      } finally {
        setLoader(false);
      }
    };
    fetchReleaseNotes();
  }, [dispatch, rows]);

  // const removeReleaseNote = (id) => {
  //     console.log("removeReleaseNote", id);
  //     setReleaseNotes((prevReleaseNotes) => {
  //         return prevReleaseNotes.filter(row => row.id !== id);
  //     })
  // }

  return (
    <>
      <Box className="content-header project-list-header">
        <Typography variant="h2" component="h2">
          Release Notes
        </Typography>
      </Box>
      <Box className="content-layout release-page">
        {loader ? (
          <Loader />
        ) : (
          releaseNotes?.data?.map(({ id, version_name, date }, index) => {
            return (
              <Box className="card" key={index + "_releaseBox"}>
                <ReleaseBox
                  index={index}
                  id={id}
                  version={version_name}
                  date={date}
                  userRole={userRole}
                  dispatch={dispatch}
                  // removeReleaseNote={removeReleaseNote}
                />
              </Box>
            );
          })
        )}
        {!releaseNotes?.data?.length && (
          <p style={{ textAlign: "center" }}>No Release Notes</p>
        )}

        {releaseNotes?.totalCount > rows && (
          <Box className="load-more-section">
            <Button
              component={"button"}
              className="btn btn-primary"
              onClick={loadMoreHandler}
            >
              Load more &nbsp; <RiAddCircleLine />
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ReleaseNotes;
