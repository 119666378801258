import getuserDataFromCookies from "./userData";
import dayjs from "dayjs";

export const getUserRole = () => {
  const user = getuserDataFromCookies();
  let role = user?.app_roles?.["Asset Tracker"];  
  if (role === "Admin") {
    return "admin";
  } else if (role === "User") {
    return "user";
  } else {
    return "";
  }
};

export const getDateRangeOptions = () => {
  const today = new Date();
  const lastMonthStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
  const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastWeekStartDate = new Date(
    today.getTime() - (today.getDay() + 7) * 24 * 60 * 60 * 1000
  );
  const lastWeekEndDate = new Date(
    today.getTime() - (today.getDay() + 1) * 24 * 60 * 60 * 1000
  );
  const firstDayOfWeek = today.getDate() - today.getDay();
  const thisWeekStartDate = new Date(today.setDate(firstDayOfWeek));
  const thisWeekEndDate = new Date();

  const ranges = [
    {
      label: "Today",
      value: [new Date(), new Date()],
    },
    {
      label: "This week",
      value: [thisWeekStartDate, thisWeekEndDate],
    },
    {
      label: "Last week",
      value: [lastWeekStartDate, lastWeekEndDate],
    },
    {
      label: "Last 7 Days",
      value: [
        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
    },
    {
      label: "This Month",
      value: [thisMonthStartDate, new Date()],
    },
    {
      label: "Last Month",
      value: [lastMonthStartDate, lastMonthEndDate],
    },
    {
      label: "Last 30 Days",
      value: [
        new Date(new Date().getTime() - 29 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
    },
  ];

  return ranges;
};

export const convertDateIntoDDMMYYYY = (date) => {
  return dayjs(date).locale("en").format("DD-MM-YYYY");
};

export const getLocalTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
};

export const formatDefaultPrice = (priceToFormat) => {
  const formattedPrice = parseFloat(priceToFormat).toFixed(2);
  return formattedPrice.toString();
};

export const roundToTwoDecimal = (value) => {
  if (value && value !== "") {
    const valueWithoutCommas=value.replace(/,/g, '');
    const roundedValue = parseFloat(valueWithoutCommas).toFixed(2);
    let formattedNumber = new Intl.NumberFormat('en-IN').format(roundedValue)
    if(roundedValue%1===0){
      formattedNumber=formattedNumber+".00"
    }
    else if((roundedValue*10)%1===0){
      formattedNumber=formattedNumber+"0"
    }
    return formattedNumber
    }
  return value;
};

export const assetStatusList = [
  { value: "in_service", label: "In Service" },
  { value: "under_repair", label: "Under Repair" },
  { value: "out_of_service", label: "Out Of Service" },
  { value: "in_stock_available", label: "In Stock" },
  { value: "scrap", label: "Scrap" },
];
