import React, { useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";
import {
  RiAddCircleLine,
  RiCloseCircleLine,
  RiSearchLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import VendorList from "./VendorList";

const Vendors = () => {
  const navigate = useNavigate();
  const [searchVendor, setSearchVendor] = useState();

  const clearSearchHandler = () => {
    setSearchVendor("");
  };

  return (
    <>
      {/*  START :: ASSETS LISTING VIEW */}
      <Box className="content-header project-list-header vendor-module-wrapper">
        <Typography variant="h2" component="h2">
          Vendors
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic-search"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search"
              onChange={(e) => setSearchVendor(e.target.value)}
              value={searchVendor}
            />
            {searchVendor && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => navigate("/vendors/create")}
          >
            Create Vendor
          </Button>
        </Box>
      </Box>
      <Box className="content-layout">
        <VendorList searchVendor={searchVendor} />
      </Box>
      {/* END :: ASSETS LISTING VIEW */}
    </>
  );
};

export default Vendors;
