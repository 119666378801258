import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import assetCategory from "../../../assets/images/icons/AssetCategory.svg";
import branches from "../../../assets/images/icons/Braches.svg";
import activity from "../../../assets/images/icons/Frame.svg";

const Settings = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2">
          Settings
        </Typography>
      </Box>
      <Box className="content-layout settings-layout p-24">
          <Grid container spacing={3}>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                  <Box className="report-card-wrapper" onClick={() => navigate("/asset-category")}>
                    <Box class="app-logo" variant="div" component="div">
                      <img src={assetCategory} alt="app-logo" loading="lazy" />
                    </Box>
                    <Typography className='card-name' variant='h5' component='h5'>
                      Asset Categories
                    </Typography>
                  </Box>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                  <Box className="report-card-wrapper" onClick={() => navigate("/branches")}>
                    <Box class="app-logo" variant="div" component="div">
                      <img src={branches} alt="app-logo" loading="lazy" />
                    </Box>
                    <Typography className='card-name' variant='h5' component='h5'>
                    Company Branches
                    </Typography>
                  </Box>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                  <Box className="report-card-wrapper" onClick={() => navigate("/notifications")}>
                    <Box class="app-logo" variant="div" component="div">
                      <img src={activity} alt="app-logo" loading="lazy" />
                    </Box>
                    <Typography className='card-name' variant='h5' component='h5'>
                    Notifications
                    </Typography>
                  </Box>
              </Grid>
          </Grid>
      </Box>
    </>
  );
};

export default Settings;
