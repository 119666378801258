import React from 'react';
import { CircularProgress } from '@mui/material';

const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh',width: '100%' }}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default Loader;
