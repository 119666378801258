import React, { useState } from "react";
import { Box, Fade, Modal, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { deleteAsset } from "../../store/slices/assetSlice";
import { successToast } from "../../response/successToast";

const AssetsDeleteModal = ({
  assetsDelete,
  setAssetsDelete,
  assetId,
  setAssetId,
  fetchData,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const assetsDeleteModalClose = () => {
    setAssetsDelete(!assetsDelete);
    setAssetId("");
  };

  const onDeleteAsset = async () => {
    setLoader(true);
    try {
      const response = await dispatch(deleteAsset(assetId));

      if (response?.success) {
        setAssetId("");
        successToast(response?.message || "Asset Deleted Successfully");
        setAssetsDelete(!assetsDelete);
        fetchData();
      } else {
        console.error("Failed! Something went wrong.");
        setAssetsDelete(!assetsDelete);
        setAssetId("");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={assetsDelete}
        onClose={assetsDeleteModalClose}
        closeAfterTransition
      >
        <Fade in={assetsDelete}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to delete this asset?
                </Typography>
                <Typography variant="body1" component="p">
                  This action can’t be undone.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => {
                      onDeleteAsset();
                    }}
                    disabled={loader}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setAssetsDelete(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AssetsDeleteModal;
