import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";

const initialState = {
  dashboard: [],
  isLoading: false,
  success: false,
  error: null,
};

const DashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    createAssetStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createAssetSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.Asset.push(action.payload);
    },
    createAssetFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editAssetStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    editAssetSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      const categoryIndex = state.Asset.findIndex(
        (category) => category.id === action.payload.id
      );
      if (categoryIndex !== -1) {
        state.Asset[categoryIndex] = action.payload;
      }
    },
    fetchDashboardStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchDashboardSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.Asset = action.payload;
    },
    fetchDashboardFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDashboardStart,
  fetchDashboardSuccess,
  fetchDashboardFailure,  
} = DashboardSlice.actions;

export default DashboardSlice.reducer;

export const fetchDashboardDetails = () => async (dispatch) => {
  try {
    dispatch(fetchDashboardStart());
    let url = `/dashboard`;
    const response = await api.get(url);
    const asset = response;
    dispatch(fetchDashboardSuccess(asset?.data));
    return { success: true, data: asset?.data };
  } catch (error) {
    dispatch(fetchDashboardFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};

