import Cookies from "js-cookie";

const getuserDataFromCookies = () => {
  // const userData = JSON.parse(localStorage.getItem("token"));
  const userId = Cookies.get(process.env.REACT_APP_USER_ID_COOKIE);
  const UserToken = Cookies.get(process.env.REACT_APP_USER_DATA_COOKIE);
  //   const userData = JSON.parse(UserToken);
  // Now, you can work with the parsed userData object
  if (!UserToken) {
    return null; // Return null when userData is not available
  }
  const jsonData = UserToken?.substring(2);
  const userData = JSON.parse(jsonData);
//   console.log("userdata::",userData)
  const userDataWithId = {
    ...userData,
    id: userId // Include userId in the userData object
  };

  // Now, you can work with the parsed userData object
  return userDataWithId; //   return userData;
};

export default getuserDataFromCookies;
