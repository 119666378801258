import React, { useState, useEffect } from "react";
import {
  RiHome5Line,
  RiLogoutBoxLine,
  RiCoupon3Line,
  RiMacbookLine,
  // RiFunctionLine,
  RiUser3Line,
  RiFileList3Line,
  // RiGitMergeLine,
  RiGroupLine,
  RiSettings2Line,
  RiMenu3Fill,
} from "react-icons/ri";
import { CiShoppingTag } from "react-icons/ci";
import {
  Backdrop,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SignoutModal from "../common/Signoutmodal";
import { getUserRole } from "../../utils/helper";
import WithPermission from "../common/withPermission";
import { handleSidebar } from "../../store/slices/drawerHandlerSlice";
import logo from "../../assets/images/ams_logo.svg";
import Favlogo from "../../assets/images/icons/ams-icon.png";
import {
  viewAssetTicket,
  viewAssets,
  viewDashboard,
  viewReports,
  viewRequest,
  viewSettings,
  viewUsers,
  viewVendors,
} from "../../utils/permissions";
import { useDispatch, useSelector } from "react-redux";
// const UserMenuOptions = [
//   // { label: "Dashboard", path: "/dashboard", icon: <RiHome5Line /> },
//   { label: "Assets", path: "/assets", icon: <RiMacbookLine />,  permission: viewDashboard, },
//   {
//     label: "Asset Tickets",
//     path: "/asset-tickets",
//     icon: <RiCoupon3Line />,

//     subPage: "asset-ticket",
//   },
// ];

const AdminMenuOptions = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: <RiHome5Line />,
    permission: viewDashboard,
  },
  {
    label: "Assets",
    path: "/assets",
    icon: <RiMacbookLine />,
    permission: viewAssets,
    subPage: ["/assets", "/assets/create"],
  },
  // {
  //   label: "Asset Categories",
  //   path: "/asset-category",
  //   icon: <RiFunctionLine />,
  // },
  {
    label: "Asset Tickets",
    path: "/asset-tickets",
    icon: <RiCoupon3Line />,
    permission: viewAssetTicket,
    subPage: "asset-ticket",
  },
  {
    label: "Purchase Requests",
    path: "/purchase-request",
    permission: viewRequest,
    icon: <CiShoppingTag />,
    subPage: ["purchase-request", "purchase-request/create"],
  },
  {
    label: "Vendors",
    path: "/vendors",
    permission: viewVendors,
    icon: <RiUser3Line />,
    // subPage: "vendor",
  },
  // {
  //   label: "Company Branches",
  //   path: "/branches",
  //   icon: <RiGitMergeLine />,
  //   subPage: "branch",
  // },
  {
    label: "Reports",
    path: "/reports",
    icon: <RiFileList3Line />,
    permission: viewReports,
    subPage: "/reports/asset",
  },
  {
    label: "Users",
    path: "/users",
    permission: viewUsers,
    icon: <RiGroupLine />,
  },
  {
    label: "Settings",
    path: "/settings",
    icon: <RiSettings2Line />,
    permission: viewSettings,
    subPage: ["/asset-category", "/branches", "/notifications"],
  },
];

const SideMenu = ({ isMobile, handleDrawerClose }) => {
  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(path);
  };

  const role = getUserRole();
  const [open, setOpen] = useState(false);

  const [menuList, setMenuList] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (isMobile && sidebarState) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [sidebarState, isMobile]);
  return (
    <>
      <Drawer
        anchor="left"
        className={`sidebar-wrapper ${
          sidebarState ? "sidebar-menu-open" : "sidebar-menu-closed"
        }`}
        variant="persistent"
        open={sidebarState}
        ModalProps={{
          keepMounted: true,
          disableEnforceFocus: true,
        }}
      
      >
        
          <Box className="list-wrap">
            <Box className="logo-box" sx={{ display: "flex" }}>
              <img
                src={logo}
                alt="logo-main"
                loading="lazy"
                className="large-logo"
                style={{ border: "0px solid white" }}
                onClick={() => navigate("/dashboard")}
              />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => dispatch(handleSidebar(false))}
                className="toggle-btn"
                sx={{ border: "0px solid grey" }}
              >
                <RiMenu3Fill className="open-menu" fill="white" />
              </IconButton>
            </Box>
            <Box className="Menu-list-wrapper-sidebar">
              <List className="sidebar first-menu-list">
                {AdminMenuOptions.map((item, index) => {
                
                  return (
                    <WithPermission
                      key={`${item}-${index}`}
                      permission={item.permission}
                      element={
                        <ListItem
                          disablePadding
                          onClick={() => {
                            if (isMobile) {
                              dispatch(handleSidebar(false));
                              setTimeout(() => {
                                navigate(item.path);
                              }, 400);
                            } else {
                              navigate(item.path);
                            }
                          }}
                          key={`${item}-${index}`}
                          className={
                            window.location.pathname === item.path ||
                            (item.subPage &&
                              item.subPage.includes(
                                window.location.pathname
                              )) ||
                            window.location.pathname.startsWith(item.path) ||
                            (item.path.includes("application") &&
                              window.location.pathname.includes("role")) ||
                            (window.location.pathname.includes("branch") &&
                              item.path.includes("settings")) ||
                            (window.location.pathname.includes(
                              "asset-category"
                            ) &&
                              item.path.includes("settings"))
                              ? "activemenu"
                              : ""
                          }
                        >
                          <ListItemButton>
                            <ListItemIcon className="icon-wrap">
                              {item.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={item.label}
                              className="menu-text-wrap"
                            />
                          </ListItemButton>
                        </ListItem>
                      }
                    />
                  );
                })}
              </List>
              <List className="sidebar last-menu-list">
                <ListItem>
                  <ListItemButton onClick={handleOpen}>
                    <ListItemIcon className="icon-wrap">
                      <RiLogoutBoxLine />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Sign Out"}
                      className="menu-text-wrap"
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Box>
        
      </Drawer>
      {isMobile && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={sidebarState}
          onClick={handleDrawerClose}
        />
      )}
      <SignoutModal open={open} handleClose={handleClose} />
    </>
  );
};

export default SideMenu;
