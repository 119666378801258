import React, { useEffect, useState } from "react";
import { TbArrowsSort } from "react-icons/tb";
import { RiDeleteBinLine, RiEyeLine, RiPencilLine } from "react-icons/ri";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Tabs,
  Tab,
  Pagination,
  Badge,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AssetTicketsDeleteModal from "../../../components/common/AssetTicketsDeleteModal";
import { getUserRole } from "../../../utils/helper";
import { useDispatch } from "react-redux";
import { fetchAssetTicket } from "../../../store/slices/assetTicketSlice";
import moment from "moment";
import { IconButton, Loader } from "rsuite";
import { styled } from "@mui/material/styles";
import {
  fullAssetTicket,
  updateAssetTicket,
  viewAssetTicket,
} from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    position: "unset",
    transform: "unset",
    marginLeft: "4px",
    lineHeight: "16px",
    // right: -16,
    // top: -2,
    fontSize: "14px",
    padding: "0 4px",
    background: "#7cb900",
    fontWeight: "700",
    color: "#fff",
    zIndex: 0,
    marginTop: "-4px",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AssetTicketsList = ({ searchAssetTicket }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [loader, setLoader] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [assetTicket, setAssetTicket] = useState([]);
  const [assetTicketId, setAssetTicketId] = useState("");
  const [assetCount, setAssetCount] = useState({});
  const [order,setOrder]= useState("DESC");
  const [rowperPage,setRowsPerPage]=useState(10);
  const role = getUserRole();

  // START :: DELETE MODAL
  const [assetTicketsDelete, setAssetTicketsDelete] = useState(false);
  // END :: DELETE MODAL

  // START :: TAB WISE LISTING FOR ASSETS TICKET
  const [value, setValue] = useState(state || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate("/asset-tickets", { state: newValue });
  };

  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setcurrentPage(1);
};

  useEffect(() => {
    setValue(state || value);
  }, [state]);

  useEffect(() => {
    fetchData();
  }, [searchAssetTicket, currentPage, value,order,rowperPage]);

  useEffect(() => {
    if (currentPage > 1) {
      setcurrentPage(1);
    }
  }, [searchAssetTicket]);

  const getTabValueString = (tabValue) => {
    switch (tabValue) {
      case 0:
        return "new";
      case 1:
        return "pending";
      case 2:
        return "completed";
      case 3:
        return "rejected";
      default:
        return "new";
    }
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchAssetTicket(
          currentPage,
          searchAssetTicket,
          getTabValueString(value),
          order,
          rowperPage
        )
      );
      if (response?.success) {
        setAssetTicket(response?.data?.result);
        setAssetCount(response?.data);
        setCount(response?.data?.totalCount);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <Typography variant="body1" component="span" className="user-name">
          {role}
        </Typography>{" "}
        {modules !== 0 ? (
          <StyledBadge badgeContent={modules} color="success" max={9999}>
            {/* {tabLabel('Approved')} */}
          </StyledBadge>
        ) : null}
      </Box>
    );
  };

  // END :: TAB WISE LISTING FOR ASSETS TICKET
  useEffect(() => {
    setcurrentPage(1);
  }, [value]);

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        {/* START :: ASSETS TICKETS TABS NAME */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-area"
          >
            <Tab label={tabLabel("New", assetCount?.newdata)} {...a11yProps(0)}/>
            <Tab label={tabLabel("Pending", assetCount?.pendingdata)} {...a11yProps(1)}/>
            <Tab label={tabLabel("Completed", assetCount?.completeddata)} {...a11yProps(2)}/>
            <Tab label={tabLabel("Rejected", assetCount?.rejecteddata)} {...a11yProps(3)}/>
          </Tabs>
        </Box>
        {/* START :: ASSETS TICKETS TABS NAME */}

        {/* START :: NEW TAB CONTENT */}
        <CustomTabPanel
          value={value}
          index={value}
          className="user-listing-tables"
        >
          <TableContainer className="table-listing">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="table"
              className="listing-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{cursor: "pointer"}} onClick={() => setOrder(order==="DESC"?("ASC"): ("DESC"))}> 
                  <TbArrowsSort style={{stroke: "rgb(0 0 0 / 52%)"}}/> <span>Ticket # </span>
                  </TableCell>
                  <TableCell align="left">Asset</TableCell>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Created By</TableCell>
                  <TableCell align="left">Requested Date</TableCell>
                  <TableCell align="left">Updated Date</TableCell>
                  {value === 2 && role === "user" && (
                    <TableCell align="left">Resolution Date</TableCell>
                  )}
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              {loader ? (
                <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <Loader />
                  </TableCell>
                </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {assetTicket?.length === 0 ? (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="center"
                        sx={{ fontSize: "16px" }}
                        colSpan={7}
                      >
                        Asset Ticket Not Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    assetTicket?.map((item) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{item?.id}</TableCell>
                        <TableCell align="left">
                          {item?.assetId?.asset_code +
                            " - " +
                            item?.assetId?.asset_name}
                        </TableCell>
                        <TableCell align="left">{item?.title}</TableCell>
                        <TableCell align="left">
                          {item?.createdBy_user?.first_name +
                            " " +
                            item?.createdBy_user?.last_name}
                        </TableCell>
                        <TableCell align="left">
                          {item?.request_date
                            ? moment(item?.request_date).format("DD-MM-YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell align="left">{item?.updatedAt?moment(item?.updatedAt).format("DD-MM-YYYY"):"-"}</TableCell>
                        {value === 2 && role === "user" && (
                          <TableCell align="left">
                            {item?.resolution_date
                              ? moment(item?.resolution_date).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </TableCell>
                        )}
                        <TableCell align="left">
                          <Box className="action-btn-wrap">
                            <WithPermission
                              permission={viewAssetTicket}
                              element={
                                <Button
                                  variant="outlined"
                                  className="action-btn view-btn"
                                  startIcon={<RiEyeLine />}
                                  disableRipple
                                  onClick={() =>
                                    navigate(
                                      `/asset-tickets/view/${item?.id}`,
                                      {
                                        state: value,
                                      }
                                    )
                                  }
                                ></Button>
                              }
                            />
                            {role === "user" ? (
                              [0, 1].includes(value) ? (
                                <WithPermission
                                  permission={updateAssetTicket}
                                  element={
                                    <Button
                                      variant="outlined"
                                      className="action-btn edit-btn"
                                      startIcon={<RiPencilLine />}
                                      disableRipple
                                      onClick={() =>
                                        navigate(
                                          `/asset-tickets/edit/${item?.id}`,
                                          {
                                            state: value,
                                          }
                                        )
                                      }
                                    ></Button>
                                  }
                                />
                              ) : null
                            ) : (
                              <WithPermission
                                permission={updateAssetTicket}
                                element={
                                  <Button
                                    variant="outlined"
                                    className="action-btn edit-btn"
                                    startIcon={<RiPencilLine />}
                                    disableRipple
                                    onClick={() =>
                                      navigate(
                                        `/asset-tickets/edit/${item?.id}`,
                                        {
                                          state: value,
                                        }
                                      )
                                    }
                                  ></Button>
                                }
                              />
                            )}
                            <WithPermission
                              permission={fullAssetTicket}
                              element={
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  disableRipple
                                  onClick={() => {
                                    setAssetTicketId(item?.id);
                                    setAssetTicketsDelete(true);
                                  }}
                                ></Button>
                              }
                            />
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
            </TableContainer>
            <CustomTablePaginationToolbar
              count={count}
              page={currentPage}
              rowsPerPage={rowperPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </CustomTabPanel>
      </Box>
      <AssetTicketsDeleteModal
        assetTicketsDelete={assetTicketsDelete}
        setAssetTicketsDelete={setAssetTicketsDelete}
        assetTicketId={assetTicketId}
        setAssetTicketId={setAssetTicketId}
        fetchData={fetchData}
      />
    </>
  );
};

export default AssetTicketsList;
