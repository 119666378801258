import React, { useState } from "react";
import { RiDeleteBinLine, RiEyeLine, RiPencilLine } from "react-icons/ri";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import VendorDeleteModal from "../../../components/common/VendorDeleteModal";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchVendor } from "../../../store/slices/vendorSlice";
import Loader from "../../../components/common/Loader";
import {
  fullVendors,
  updateVendors,
  viewVendors,
} from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

const VendorList = ({ searchVendor }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [vendorData, setVendorData] = useState([]);
  const [vendorId, setVendorId] = useState("");
  const [rowperPage,setRowsPerPage]=useState(10);

  // START :: DELETE MODAL
  const [vendorDelete, setVendorDelete] = useState(false);
  // END :: DELETE MODAL

  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setcurrentPage(1);
};

  useEffect(() => {
    fetchData();
  }, [searchVendor, currentPage,rowperPage]);

  useEffect(() => {
    if (currentPage > 1) {
      setcurrentPage(1);
    }
  }, [searchVendor]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchVendor(false, currentPage, searchVendor,rowperPage)
      );
      if (response?.success) {
        setVendorData(response?.data?.result);
        setCount((response?.data?.pagination?.rows)*(response?.data?.pagination?.totalPages));
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Company Name</TableCell>
                <TableCell align="left">Contact Person Name</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">City</TableCell>
                <TableCell align="left">Contact No</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            {loader ? (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  {" "}
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {vendorData?.length === 0 ? (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" colSpan={7}>
                      Vendor Details Not Found.
                    </TableCell>
                  </TableRow>
                ) : (
                  vendorData?.map((item) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        <Box className="app-list">{item?.company_name}</Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box className="app-list">
                          {item?.contact_person.trim() &&
                          item?.contact_person !== "Nil"
                            ? item?.contact_person
                            : "-"}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <Box
                          variant="div"
                          component="div"
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "180px",
                          }}
                        >
                          {item?.address.trim() ? item?.address : "-"}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box className="app-list">
                          {item?.city.trim() ? item?.city : "-"}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box className="app-list">
                          {item?.phone_number === null ||
                          item?.phone_number.trim() === ""
                            ? "-"
                            : item?.phone_number}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box className="app-list">
                          {item?.email.trim() ? item?.email : "-"}
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box className="action-btn-wrap">
                          <WithPermission
                            permission={viewVendors}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn view-btn"
                                startIcon={<RiEyeLine />}
                                disableRipple
                                onClick={() =>
                                  navigate(`/vendors/view/${item?.id}`)
                                }
                              ></Button>
                            }
                          />
                          <WithPermission
                            permission={updateVendors}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn edit-btn"
                                startIcon={<RiPencilLine />}
                                disableRipple
                                onClick={() =>
                                  navigate(`/vendors/edit/${item?.id}`)
                                }
                              ></Button>
                            }
                          />
                          <WithPermission
                            permission={fullVendors}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() => {
                                  setVendorDelete(true);
                                  setVendorId(item?.id);
                                }}
                              ></Button>
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>

        </TableContainer>
      </Box>
      <CustomTablePaginationToolbar
          count={count}
          page={currentPage}
          rowsPerPage={rowperPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
       />

      <VendorDeleteModal
        vendorDelete={vendorDelete}
        setVendorDelete={setVendorDelete}
        vendorId={vendorId}
        setVendorId={setVendorId}
        fetchData={fetchData}
      />
    </>
  );
};

export default VendorList;
