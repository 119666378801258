import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  Drawer,
  Tabs,
  Tab,
  Link,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  RiAlarmLine,
  RiArrowLeftLine,
  RiCloseLine,
  RiCoupon3Line,
  RiPencilLine,
} from "react-icons/ri";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  fectchAssetTicketById,
  fetchAssetTicketActivity,
} from "../../../store/slices/assetTicketSlice";
import moment from "moment/moment";
import { getUserRole, roundToTwoDecimal } from "../../../utils/helper";
import Loader from "../../../components/common/Loader";
import WithPermission from "../../../components/common/withPermission";
import { updateAssetTicket } from "../../../utils/permissions";

// START :: SIDEBAR DRAWER TABBING PANEL
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// END :: SIDEBAR DRAWER TABBING PANEL

const AssetTicketsView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [assetTicket, setAssetTicket] = useState([]);
  const [activity, setActivity] = useState([]);
  const [updates, setUpdate] = useState([]);
  const { id } = useParams();
  const role = getUserRole();
  useEffect(() => {
    fetchData();
    fetchTicketActivity();
    fetchTicketUpdate();
  }, [id]);

  const getStatusValueString = (value) => {
    switch (value) {
      case "new":
        return "New";
      case "pending":
        return "Pending";
      case "completed":
        return "Completed";
      case "rejected":
        return "Rejected";
      default:
        return "New";
    }
  };

  const fetchData = async () => {
    try {
      setLoader(true);
      const response = await dispatch(fectchAssetTicketById(id));
      if (response?.success) {
        setAssetTicket(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchTicketActivity = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAssetTicketActivity(id, "update"));
      if (response?.success) {
        setActivity(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchTicketUpdate = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAssetTicketActivity(id, "status"));
      if (response?.success) {
        setUpdate(response?.data);
        console.log(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* START :: ASSETS VIEW HEADER */}
      <Box className="content-header view-lead-header asset-ticket-view-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/asset-tickets", { state: state })}
          ></Button>
          Asset Ticket - Ticket #{assetTicket?.id}
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAlarmLine />}
            disableRipple
            onClick={() => setOpen(!open)}
          >
            Activity Log
          </Button>
          {assetTicket?.status === "new" ||
          assetTicket?.status === "pending" ? (
            <WithPermission
              permission={updateAssetTicket}
              element={
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn only-icon-btn"
                  endIcon={<RiPencilLine />}
                  disableRipple
                  onClick={() => navigate(`/asset-tickets/edit/${id}`)}
                ></Button>
              }
            />
          ) : null}
        </Box>
      </Box>
      {/* END :: ASSETS VIEW HEADER */}

      {/* START :: ASSETS VIEW CONTENT */}
      {role === "admin" ? (
        <>
          <Box className="content-wrapper">
            <Box className="card-wrapper">
              <Grid container spacing={2} className="view-info">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                    sx={{ paddingBottom: "8px" }}
                  >
                    {assetTicket?.title}
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="description"
                  >
                    {assetTicket?.description}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Asset Code
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetTicket?.assetId?.asset_code +
                      " - " +
                      assetTicket?.assetId?.asset_name}
                  </Typography>
                </Grid>                
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                    <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Status
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {getStatusValueString(assetTicket?.status)}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Created By
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetTicket?.createdBy_user?.first_name +
                      " " +
                      assetTicket?.createdBy_user?.last_name}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                    <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Request Date
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {moment(assetTicket?.request_date).format("DD-MM-YYYY")}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Repairing Vendor
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetTicket?.vendor === null
                      ? "-"
                      : assetTicket?.vendor?.company_name}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                    <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Resolution Date
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetTicket?.resolution_date
                      ? moment(assetTicket?.resolution_date).format(
                          "DD-MM-YYYY"
                        )
                      : " - "}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Repairing Cost
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetTicket?.repairing_cost === null ||
                    assetTicket?.repairing_cost === "NaN"
                      ? "-"
                      : "₹ " +
                        roundToTwoDecimal(assetTicket?.repairing_cost + "")}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                    <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Repairing Bill
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      className="value text-link-btn"
                    >
                      {!assetTicket?.repairing_bill &&
                        !assetTicket?.repairing_bill_2 && (
                          <Typography
                            variant="body1"
                            component="span"
                            className="value"
                          >
                            No files uploaded
                          </Typography>
                        )}
                      {assetTicket?.repairing_bill && (
                        <div>
                          <Link
                            href={assetTicket?.repairing_bill}
                            target="_blank"
                            underline="always"
                          >
                            {`Click here to view Repairing-Bill 1`}
                          </Link>
                        </div>
                      )}
                      {assetTicket?.repairing_bill_2 && (
                        <div>
                          <Link
                            href={assetTicket?.repairing_bill_2}
                            target="_blank"
                            underline="always"
                          >
                            {`Click here to view Repairing-Bill 2`}
                          </Link>
                        </div>
                      )}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box className="content-wrapper">
            <Box className="card-wrapper">
              <Grid
                container
                rowSpacing={2}
                columnSpacing={3}
                className="view-info"
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                    sx={{ paddingBottom: "8px" }}
                  >
                    {assetTicket?.title}
                  </Typography>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Asset Code
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetTicket?.assetId?.asset_code +
                      " - " +
                      assetTicket?.assetId?.asset_name}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Requested Date
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {moment(assetTicket?.request_date).format("MMM DD, YYYY")}
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Asset Description
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetTicket?.description}
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Current Status
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {getStatusValueString(assetTicket?.status)}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Created For
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetTicket?.user?.first_name +
                      " " +
                      assetTicket?.user?.last_name}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}

      {/* END :: ASSETS VIEW CONTENT */}

      {/*  START :: ASSET TICKET  ACTIVITY PANEL*/}
      <Drawer
        className="side-drawer view-activity-log"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box className="sidebar-tab-wrapper activity-log-wrapper">
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={() => setOpen(false)}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab-label-btn"
                  style={{ color: "var(--primary-text-color)" }}
                  label="Activity Log"
                />
              </Tabs>
            </Box>
          </Box>
          <Box className="sidebar-tab-wrapper inner-tab-wrapper">
            <Box className="sidebar-tab" style={{ paddingLeft: "12px" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                label="Activity Log"
              >
                <Tab
                  className="tab-label-btn"
                  label="Updates"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Status"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              {loader ? (
                <Box className="activity-log-list-wrapper">
                  <Loader />
                </Box>
              ) : (
                <Box className="activity-log-list-wrapper">
                  {activity?.length === 0 ? (
                    <Box className="activity-card-wrapper">
                      <Box className="activity-log-icon activity-call">
                        <RiCoupon3Line />
                      </Box>
                      <Box className="activity-content-wrapper">
                      <Typography
                            variant="body1"
                            component="span"
                            className='date-time-drawer'
                            style={{
                                lineHeight:
                                    '19px !important',
                            }}
                        >
                            {assetTicket?.createdAt
                            ? moment(assetTicket?.createdAt).format("HH:mm")
                            : "-"}
                            <Typography variant="body1" component="span" className="mobile-time-drawer">
                                  | {assetTicket?.createdAt
                                ? moment(assetTicket?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </Typography>
                        </Typography>
                        <Box className="activity-content">
                          <Box className="activity-content-header">
                            <Typography variant="h6" component="h6">
                              {assetTicket?.title} asset ticket created.
                            </Typography>
                            <Typography variant="body1" component="span">
                              {assetTicket?.createdAt
                                ? moment(assetTicket?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <>
                        {activity?.map((activities) => (
                          <Box
                            className="activity-card-wrapper"
                            key={activities.id}
                          >
                            <Box className="activity-log-icon activity-call">
                              <RiCoupon3Line />
                            </Box>
                            <Box className="activity-content-wrapper">

                              <Typography
                                    variant="body1"
                                    component="span"
                                    className='date-time-drawer'
                                    style={{
                                        lineHeight:
                                            '19px !important',
                                    }}
                                >
                                    {activities?.updatedAt
                                    ? moment(activities?.updatedAt).format("HH:mm")
                                    : "-"}
                                    <Typography variant="body1" component="span" className="mobile-time-drawer">
                                          | {activities?.updatedAt
                                        ? moment(activities?.updatedAt).format(
                                            "MMM DD, YYYY"
                                          )
                                        : "-"}
                                    </Typography>
                                </Typography>
                              
                              <Box className="activity-content">
                                <Box className="activity-content-header">
                                  <Typography variant="h6" component="h6">
                                    {activities.details}
                                  </Typography>
                                  <Typography variant="body1" component="span">
                                    {activities?.updatedAt
                                      ? moment(activities?.updatedAt).format(
                                          "MMM DD, YYYY"
                                        )
                                      : "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </>
                      <>
                        <Box className="activity-card-wrapper">
                          <Box className="activity-log-icon activity-call">
                            <RiCoupon3Line />
                          </Box>
                          <Box className="activity-content-wrapper">
                              <Typography
                                variant="body1"
                                component="span"
                                className='date-time-drawer'
                                style={{
                                    lineHeight:
                                        '19px !important',
                                }}
                            >
                                {assetTicket?.createdAt
                                ? moment(assetTicket?.createdAt).format("HH:mm")
                                : "-"}
                                <Typography variant="body1" component="span" className="mobile-time-drawer">
                                      | {assetTicket?.createdAt
                                    ? moment(assetTicket?.createdAt).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "-"}
                                </Typography>
                            </Typography>
                            <Box className="activity-content">
                              <Box className="activity-content-header">
                                <Typography variant="h6" component="h6">
                                  {assetTicket?.title} asset ticket created.
                                </Typography>
                                <Typography variant="body1" component="span">
                                  {assetTicket?.createdAt
                                    ? moment(assetTicket?.createdAt).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "-"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    </>
                  )}
                </Box>
              )}
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              {loader ? (
                <Box className="activity-log-list-wrapper">
                  <Loader />
                </Box>
              ) : (
                <Box className="activity-log-list-wrapper">
                  {updates?.length === 0 ? (
                    <Box className="activity-card-wrapper">
                      <Box className="activity-log-icon activity-call">
                        <RiCoupon3Line />
                      </Box>
                      <Box className="activity-content-wrapper">
                      <Typography
                            variant="body1"
                            component="span"
                            className='date-time-drawer'
                            style={{
                                lineHeight:
                                    '19px !important',
                            }}
                        >
                            {assetTicket?.createdAt
                            ? moment(assetTicket?.createdAt).format("HH:mm")
                            : "-"}
                            <Typography variant="body1" component="span" className="mobile-time-drawer">
                                  | {assetTicket?.createdAt
                                ? moment(assetTicket?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </Typography>
                        </Typography>
                        <Box className="activity-content">
                          <Box className="activity-content-header">
                            <Typography variant="h6" component="h6">
                              {assetTicket?.title} asset ticket created and
                              status set to {assetTicket?.status}
                            </Typography>
                            <Typography variant="body1" component="span">
                              {assetTicket?.createdAt
                                ? moment(assetTicket?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <>
                        {updates?.map((activities) => (
                          <Box
                            className="activity-card-wrapper"
                            key={activities.id}
                          >
                            <Box className="activity-log-icon activity-call">
                              <RiCoupon3Line />
                            </Box>
                            <Box className="activity-content-wrapper">
                              <Typography
                                  variant="body1"
                                  component="span"
                                  className='date-time-drawer'
                                  style={{
                                      lineHeight:
                                          '19px !important',
                                  }}
                              >
                                  {activities?.updatedAt
                                  ? moment(activities?.updatedAt).format("HH:mm")
                                  : "-"}
                                  <Typography variant="body1" component="span" className="mobile-time-drawer">
                                        | {activities?.updatedAt
                                      ? moment(activities?.updatedAt).format(
                                          "MMM DD, YYYY"
                                        )
                                      : "-"}
                                  </Typography>
                              </Typography>
                              <Box className="activity-content">
                                <Box className="activity-content-header">
                                  <Typography variant="h6" component="h6">
                                    {activities.details}
                                  </Typography>
                                  <Typography variant="body1" component="span">
                                    {activities?.updatedAt
                                      ? moment(activities?.updatedAt).format(
                                          "MMM DD, YYYY"
                                        )
                                      : "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </>
                    </>
                  )}
                </Box>
              )}
            </Stack>
          </CustomTabPanel>
        </Box>
      </Drawer>
      {/* END :: ASSET TICKET  ACTIVITY PANEL*/}
    </>
  );
};

export default AssetTicketsView;
