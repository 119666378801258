import React, { useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";
import {
  RiAddCircleLine,
  RiCloseCircleLine,
  RiSearchLine,
  RiArrowLeftLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import AssetCategoryList from "./AssetCategoryList";

const AssetCategory = () => {
  const navigate = useNavigate();
  const [searchCategory, setSearchCategory] = useState();

  const clearSearchHandler = () => {
    setSearchCategory("");
  };

  return (
    <>
      {/*  START :: ASSETS LISTING VIEW */}
      <Box className="content-header project-list-header setting-asset-category-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/settings")}
          ></Button>
          Asset Categories
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic-search"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search"
              onChange={(e) => setSearchCategory(e.target.value)}
              value={searchCategory}
            />
            {searchCategory && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn add-btn"
              endIcon={<RiAddCircleLine />}
              disableRipple
              onClick={() => navigate("/asset-category/create")}
            >
              Create Asset Category
            </Button>
          </Box>
      </Box>
      <Box className="content-layout">
        <AssetCategoryList searchCategory={searchCategory} />
      </Box>
      {/* END :: ASSETS LISTING VIEW */}
    </>
  );
};

export default AssetCategory;
