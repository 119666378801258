import React, { useState, useEffect, useCallback } from "react";

import {
  Box,
  Button,
  TextField,
  Typography,
  Drawer,
  Stack,
  Tab,
  Tabs,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
  Fade,
  FormHelperText,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiCloseCircleLine,
  RiSearchLine,
  RiFilter2Line,
  RiCloseLine,
  RiArrowDownSLine,
  RiPencilLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import AssetsList from "./AssetsList";
import { getUserRole } from "../../../utils/helper";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { useFormik } from "formik";
import { successToast } from "../../../response/successToast";
import { useDispatch, useSelector } from "react-redux";
import {
  createAssetFilter,
  editAssetFilter,
  deleteAssetFilter,
  fetchAssetFilterList,
} from "../../../store/slices/assetSlice";
import { fetchAssetCategory } from "../../../store/slices/assetCategorySlice";
import { fetchUsers } from "../../../store/slices/userSlice";
import { fetchCompanyBranch } from "../../../store/slices/companyBranchSlice";
import api from "../../../components/common/commonfunctionforapi";
import { updateAssets } from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const atLeastOneString = (fields, message = "At least one field is required") =>
  Yup.mixed().test("atLeastOneString", message, function (value) {
    const { parent } = this;

    const hasAtLeastOneField = fields.some((field) => {
      const fieldValue = parent[field];
      return (
        fieldValue !== undefined &&
        fieldValue !== null &&
        typeof fieldValue === "string" &&
        fieldValue.trim() !== ""
      );
    });

    if (!hasAtLeastOneField) {
      return this.createError({ message, path: this.path });
    }

    return true;
  });

const Assets = () => {
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      childvalue: 0,
      currentPage: 1,
      rowperPage: 10,
      searchAsset: "",
      filterApplied: false,
      applyedFilter: {},
    };
  }
  const role = getUserRole();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [searchAsset, setSearchAsset] = useState(state.searchAsset);
  const [currentPage, setcurrentPage] = useState(state.currentPage);
  const [rowperPage, setRowsPerPage] = useState(state.rowperPage);
  const [value, setValue] = useState(0);
  const [childvalue, setChildValue] = useState(state.childvalue);
  const [SaveFilter, setSaveFilterModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState("");
  const [filterApplied, setFilterApplied] = useState(state.filterApplied);
  const [applyedFilter, setApplyedFilter] = useState(state.applyedFilter);
  const [DeleteSavedFilter, setDeleteSavedFilterModal] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const filters = useSelector((state) => state?.asset?.assetFilter);
  const [filterData, setFilterData] = useState({});
  const [categoryOptions, setCategoryOptions] = useState("");
  const [assigneeOPtions, setAssigneeOptions] = useState("");
  const [branchOptions, setBranchOptions] = useState("");
  const [nameerror, setNameError] = useState("");
  const companyOptions = [
    { value: 1, label: "Crest Infosystems Pvt. Ltd." },
    { value: 2, label: "Crest Skillserve Pvt. Ltd." },
  ];

  const clearSearchHandler = () => {
    setSearchAsset("");
    setcurrentPage(1);
  };
  const handleSearchRequest = (s) => {
    setSearchAsset(s);
    setcurrentPage(1);
  };
  const handlePageChange = (newPage) => {
    setcurrentPage(newPage);
  };

  const handleChildValueChange = (value) => {
    setChildValue(value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsEdit(false);
    // formik.resetForm();
  };

  const handleDrawer = async () => {
    setValue(0);
    if (!filterApplied || !applyedFilter) {
      formik.resetForm(); // Reset the form to its initial values
      formik.setTouched({}); // Clear the touched state
      formik.setErrors({});
    }

    dispatch(fetchAssetFilterList({ allFilters: "1", filter_id: "" }));
    setOpen(!open);
  };
  const onCloseDrawer = async () => {
    setOpen(false);
    if (!filterApplied || !applyedFilter) {
      setOpen(false);
      formik.resetForm(); // Reset the form to its initial values
      formik.setTouched({}); // Clear the touched state
      formik.setErrors({});
    }

    dispatch(fetchAssetFilterList({ allFilters: "1", filter_id: "" }));
  };

  const formik = useFormik({
    initialValues: {
      category_id: null,
      assignee_id: null,
      branch_id: null,
      company_id: null,

      filter_name: "",
    },
    // validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      if (!nameerror) {
        dispatch(
          isEdit
            ? editAssetFilter(selectedSavedFilter.id, values)
            : createAssetFilter(values)
        )
          .then((response) => {
            if (response?.success) {
              setSaveFilterModal(false);
              successToast(
                response?.message || isEdit
                  ? "Filter Updated Successfully"
                  : "Filter Added Successfully"
              );
              setValue(1);
              formik.resetForm();
              dispatch(
                fetchAssetFilterList({ allFilters: "1", filter_id: "" })
              );
              setIsEdit(false);
            } else {
              console.error("failed! Something went wrong");
            }
          })
          .catch((error) => {
            console.error("API call error:", error);
          });
      }
      setLoader(false);
    },
  });
  const fetchBranchList = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchCompanyBranch(false));
      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((branch) => ({
          value: branch.id,
          label: branch.name,
        }));
        setBranchOptions(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchUsersList = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchUsers({ pagination: false, allusers: "yes" })
      );
      if (response?.payload) {
        const formattedOptions = response?.payload?.results?.map((user) => ({
          value: user.id,
          label:
            user?.employee_number +
            " - " +
            user.first_name +
            " " +
            user.last_name,
        }));
        setAssigneeOptions(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchAssetCategoryList = async () => {
    setLoader(true);
    try {
      const listing = true;
      const response = await dispatch(fetchAssetCategory(1, "", listing));
      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((category) => ({
          value: category.id,
          label: category.name,
        }));
        setCategoryOptions(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const handleEditClick = async (id, name) => {
    setIsEdit(true);
    setValue(0);
    const response = await dispatch(
      fetchAssetFilterList({
        allFilters: "",
        filter_id: id,
      })
    );
    formik.setValues({
      filter_name: name,

      assignee_id: response?.data?.data?.user?.id,
      branch_id: response?.data?.data?.company_branch?.id,
      company_id: response?.data?.data?.company?.id,
      category_id: response?.data?.data?.asset_category?.id,
    });
  };

  const handleArrowOpen = async (id) => {
    let url = `/asset/filter?filter_id=${id}`;
    const response = await api.get(url);
    setFilterData((prevState) => ({
      ...prevState,
      [id]: response?.data,
    }));
  };

  const onDeleteAssetFilter = async (id) => {
    setLoader(true);
    try {
      const response = await dispatch(
        deleteAssetFilter(selectedSavedFilter.id)
      );

      if (response?.success) {
        successToast(response?.message || "filter Deleted Successfully");
        setDeleteSavedFilterModal(!DeleteSavedFilter);
        dispatch(fetchAssetFilterList({ allFilters: "1", filter_id: "" }));
      } else {
        console.error("Failed! Something went wrong.");
        setDeleteSavedFilterModal(!DeleteSavedFilter);
        setSelectedSavedFilter({});
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (role === "admin") {
      fetchAssetCategoryList();

      fetchBranchList();
      fetchUsersList();
    }
  }, []);

  useEffect(() => {
    if (role === "admin") {
      dispatch(fetchAssetFilterList({ allFilters: "1", filter_id: "" }));
    }
  }, [value]);

  useCallback(() => {
    if (currentPage > 1) {
      setcurrentPage(1);
    }
  }, [searchAsset, childvalue, value]);

  return (
    <>
      {/*  START :: ASSETS LISTING VIEW */}
      <Box className="content-header asset-module-wrapper">
        <Box className="filter-apply-wrapper">
          <Typography variant="h2" component="h2">
            Assets
          </Typography>
          {filterApplied && applyedFilter && (
            <>
              <Typography variant="h2" component="h2">
                {" "}
                - &nbsp;
                {applyedFilter ? applyedFilter?.filter_name : ""}
              </Typography>
              <Button
                variant="contained"
                size="large"
                className="outline-btn btn close-btn"
                endIcon={<RiCloseLine />}
                disableRipple
                onClick={() => {
                  setFilterApplied(false);
                  setApplyedFilter({});
                  formik.resetForm();
                  setIsEdit(false);
                }}
              >
                Remove filter
              </Button>
            </>
          )}
        </Box>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic-asset-search"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchRequest(e.target.value)}
              value={searchAsset}
            />
            {searchAsset && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
          <WithPermission
            permission={updateAssets}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                endIcon={<RiAddCircleLine />}
                disableRipple
                onClick={() => navigate("/assets/create")}
              >
                Add Asset
              </Button>
            }
          />
          {role === "admin" && (
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn add-btn"
              endIcon={<RiFilter2Line />}
              disableRipple
              onClick={handleDrawer}
            >
              <Typography
                variant="body1"
                component="span"
                className="filter-counte"
              >
                {filters?.length}
              </Typography>
              Filter
            </Button>
          )}
        </Box>
      </Box>
      <Box className="content-layout">
        <AssetsList
          searchAsset={searchAsset}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          rowperPage={rowperPage}
          onChangerowsPerPage={setRowsPerPage}
          applyedFilter={applyedFilter}
          onValueChange={handleChildValueChange}
          value={childvalue}
          filterApplied={filterApplied}
        />
      </Box>

      <Drawer
        className="side-drawer report-side-drawer"
        anchor="right"
        open={open}
        onClose={onCloseDrawer}
      >
        <Box className="sidebar-tab-wrapper">
          <Box className="close-btn">
            <RiCloseLine onClick={onCloseDrawer} />
          </Box>
          <Box className="sidebar-tab">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                className="tab-label-btn"
                label="Filters"
                {...a11yProps(0)}
              />
              <Tab
                className="tab-label-btn"
                label="Saved Filters"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </Box>
        <CustomTabPanel
          value={value}
          index={0}
          className="filter-content-wrapper"
        >
          <Stack spacing={2} sx={{ padding: "24px" }}>
            {isEdit && (
              <Box variant="div" component="div" className="body">
                <Typography variant="body1" component="label">
                  Name
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Save Filter Name"
                  name="filter_name"
                  value={formik.values.filter_name}
                  onChange={(e) => {
                    formik.setFieldValue("filter_name", e.target.value);

                    if (formik.values.filter_name) {
                      setNameError("");
                    }
                  }}
                />
                {nameerror && (
                  <Typography variant="body1" component="p" className="error">
                    {nameerror}
                  </Typography>
                )}
              </Box>
            )}
            <Box>
              <Typography variant="body1" component="label">
                Category
              </Typography>
              <Select
                placeholder="Select Category"
                name="category_id"
                className="muilt-select-field"
                options={categoryOptions}
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
                onChange={(selectedOption) => {
                  formik.setFieldValue("category_id", selectedOption?.value);
                  formik.setFieldTouched("category_id", false);
                  if (selectedOption) {
                    setError("");
                  }
                }}
                value={
                  categoryOptions &&
                  Array.isArray(categoryOptions) &&
                  categoryOptions.find(
                    (option) => option.value === formik.values.category_id
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.category_id &&
                  Boolean(formik.errors.category_id)
                }
                helperText={
                  formik.touched.category_id && (
                    <span className="error-message">
                      {formik.errors.category_id}
                    </span>
                  )
                }
              />
            </Box>

            <Box variant="div" component="div" className="body">
              <Typography variant="body1" component="label">
                Assignee
              </Typography>
              <Select
                placeholder="Select Assignee"
                name="assignee_id"
                className="muilt-select-field"
                options={assigneeOPtions}
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
                onChange={(selectedOption) => {
                  formik.setFieldValue("assignee_id", selectedOption?.value);
                  formik.setFieldTouched("assignee_id", false);
                  if (selectedOption) {
                    setError("");
                  }
                }}
                value={
                  assigneeOPtions &&
                  Array.isArray(assigneeOPtions) &&
                  assigneeOPtions.find(
                    (option) => option.value === formik.values.assignee_id
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.assignee_id &&
                  Boolean(formik.errors.assignee_id)
                }
                helperText={
                  formik.touched.assignee_id && (
                    <span className="error-message">
                      {formik.errors.assignee_id}
                    </span>
                  )
                }
              />
            </Box>
            <Box>
              <Typography variant="body1" component="label">
                Company
              </Typography>
              <Select
                placeholder="Select Company"
                name="company_id"
                options={companyOptions}
                className="muilt-select-field"
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
                onChange={(selectedOption) => {
                  formik.setFieldValue("company_id", selectedOption?.value);
                  formik.setFieldTouched("company_id", false);
                  if (selectedOption) {
                    setError("");
                  }
                }}
                value={
                  companyOptions &&
                  Array.isArray(companyOptions) &&
                  companyOptions.find(
                    (option) => option.value === formik.values.company_id
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.company_id && Boolean(formik.errors.company_id)
                }
              />
            </Box>
            <Box>
              <Typography variant="body1" component="label">
                Branch
              </Typography>
              <Select
                placeholder="Select Branch"
                name="branch_id"
                options={branchOptions}
                className="muilt-select-field"
                classNamePrefix="select"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
                onChange={(selectedOption) => {
                  formik.setFieldValue("branch_id", selectedOption?.value);
                  formik.setFieldTouched("branch_id", false);
                  if (selectedOption) {
                    setError("");
                  }
                }}
                value={
                  branchOptions &&
                  Array.isArray(branchOptions) &&
                  branchOptions.find(
                    (option) => option.value === formik.values.branch_id
                  )
                }
                onBlur={formik.handleBlur}
                error={
                  formik.touched.branch_id && Boolean(formik.errors.branch_id)
                }
              />
            </Box>
            {error && (
              <Typography variant="body1" component="p" className="error">
                {error}
              </Typography>
            )}
            {formik.errors.atLeastOne && (
              <FormHelperText className="error-message">
                {formik.errors.atLeastOne}
              </FormHelperText>
            )}
            <Box className="filter-button-wrapper-1 text-end">
              <Button
                variant="contained"
                size="large"
                type="submit"
                className="primary-btn btn green-btn"
                onClick={() => {
                  if (
                    !formik.values.category_id &&
                    !formik.values.assignee_id &&
                    !formik.values.company_id &&
                    !formik.values.branch_id
                  ) {
                    setError("AtLeast One field is required");
                    return;
                  }
                  setApplyedFilter({
                    category_id: formik.values.category_id,
                    assignee_id: formik.values.assignee_id,
                    company_id: formik.values.company_id,
                    branch_id: formik.values.branch_id,
                  });

                  setOpen(false);
                  setValue(0);
                  setFilterApplied(true);
                }}
                disableRipple
              >
                Apply Filter
              </Button>
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn"
                disableRipple
                onClick={() => {
                  if (
                    !formik.values.category_id &&
                    !formik.values.assignee_id &&
                    !formik.values.company_id &&
                    !formik.values.branch_id
                  ) {
                    setError("AtLeast One field is required");
                    return;
                  }
                  if (isEdit) {
                    if (formik.values.filter_name === "") {
                      setNameError("Please Enter Filter Name");
                      return;
                    }
                  }
                  isEdit ? formik.handleSubmit() : setSaveFilterModal(true);
                }}
              >
                {isEdit ? "Update" : "Save"}
              </Button>
              <Button
                variant="contained"
                size="large"
                className="secondary-btn btn ml-16"
                disableRipple
                onClick={() => {
                  setOpen(false);
                  !filterApplied && formik.resetForm(); // Reset the form to its initial values
                  formik.setTouched({}); // Clear the touched state
                  formik.setErrors({});
                }}
              >
                Cancel
              </Button>
            </Box>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel
          value={value}
          index={1}
          className="filter-content-wrapper"
        >
          <Box className="filer-accordion-wrapper">
            {filters && Array.isArray(filters) && filters?.length > 0 ? (
              filters &&
              Array.isArray(filters) &&
              filters?.map((item) => (
                <Accordion key={item?.id}>
                  <AccordionSummary
                    className="accordion-title"
                    expandIcon={<RiArrowDownSLine />}
                    onClick={() => handleArrowOpen(item?.id)}
                    aria-controls="filter-content-1"
                    id="filter-header-1"
                  >
                    <Typography className="title-content">
                      {item?.name}
                      {/* abcd */}
                    </Typography>
                    <Stack
                      className="action-btn-wrap"
                      direction="row"
                      spacing={2}
                    >
                      <Button
                        variant="outlined"
                        className="action-btn view-btn"
                        startIcon={<RiFilter2Line />}
                        disableRipple
                        onClick={async (e) => {
                          e.stopPropagation();
                          let url = `/asset/filter?filter_id=${item?.id}`;
                          const response = await api.get(url);

                          setApplyedFilter({
                            filter_name: item?.name,
                            category_id: response?.data?.asset_category?.id,
                            assignee_id: response?.data?.user?.id,
                            company_id: response?.data?.company?.id,
                            branch_id: response?.data?.company_branch?.id,
                          });
                          setIsEdit(true);
                          const response1 = await dispatch(
                            fetchAssetFilterList({
                              allFilters: "",
                              filter_id: item?.id,
                            })
                          );
                          formik.setValues({
                            filter_name: item?.name,

                            assignee_id: response1?.data?.data?.user?.id,
                            branch_id:
                              response1?.data?.data?.company_branch?.id,
                            company_id: response1?.data?.data?.company?.id,
                            category_id:
                              response1?.data?.data?.asset_category?.id,
                          });
                          setSelectedSavedFilter(item);
                          setOpen(false);
                          setValue(0);
                          setFilterApplied(true);
                        }}
                      />

                      <Button
                        variant="outlined"
                        className="action-btn edit-btn"
                        startIcon={<RiPencilLine />}
                        onClick={(e) => {
                          handleEditClick(item?.id, item?.name);
                          setSelectedSavedFilter(item);
                          e.stopPropagation();
                        }}
                        disableRipple
                      />
                      <Button
                        variant="outlined"
                        className="action-btn dlt-btn"
                        startIcon={<RiDeleteBinLine />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteSavedFilterModal(true);
                          setSelectedSavedFilter(item);
                        }}
                        disableRipple
                      />
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails className="filter-accordion-details">
                    <Stack spacing={2} className="saved-filter-wrapper">
                      <Box className="saved-filter-content">
                        <Typography
                          variant="body2"
                          component="span"
                          className="title"
                        >
                          Category
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          className="desc"
                        >
                          {filterData[item?.id]?.asset_category === null
                            ? "-"
                            : filterData[item?.id]?.asset_category?.name}
                        </Typography>
                      </Box>

                      <Box className="saved-filter-content">
                        <Typography
                          variant="body2"
                          component="span"
                          className="title"
                        >
                          Assignee
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          className="desc"
                        >
                          {filterData[item?.id]?.user
                            ? filterData[item?.id].user?.first_name +
                              " " +
                              filterData[item?.id].user?.last_name
                            : "-"}
                        </Typography>
                      </Box>
                      <Box className="saved-filter-content">
                        <Typography
                          variant="body2"
                          component="span"
                          className="title"
                        >
                          Company
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          className="desc"
                        >
                          {filterData[item?.id]?.company?.company_name === null
                            ? "-"
                            : filterData[item?.id]?.company?.company_name}
                        </Typography>
                      </Box>
                      <Box className="saved-filter-content">
                        <Typography
                          variant="body2"
                          component="span"
                          className="title"
                        >
                          Branch
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          className="desc"
                        >
                          {filterData[item?.id]?.company_branch === null
                            ? "-"
                            : filterData[item?.id]?.company_branch?.name}
                        </Typography>
                      </Box>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <Typography variant="body1" component="div" className="no-msg">
                No Filters Found.
              </Typography>
            )}
          </Box>
        </CustomTabPanel>
      </Drawer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={SaveFilter}
        closeAfterTransition
      >
        <Fade in={SaveFilter}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                <Box
                  variant="div"
                  component="div"
                  className="title"
                  sx={{ marginBottom: "24px" }}
                >
                  <Typography variant="h3" component="h3">
                    Save Filter
                  </Typography>
                </Box>
                <Box variant="div" component="div" className="body">
                  <Typography variant="body1" component="label">
                    Name
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Save Filter Name"
                    name="filter_name"
                    value={formik.values.filter_name}
                    onChange={(e) => {
                      formik.setFieldValue("filter_name", e.target.value);
                      if (formik.values.filter_name) {
                        setNameError("");
                      }
                    }}
                  />
                  {nameerror && (
                    <Typography variant="body1" component="p" className="error">
                      {nameerror}
                    </Typography>
                  )}
                </Box>
                <Box variant="div" component="div" className="footer">
                  <Box variant="div" component="div" className="btn-group">
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      className="primary-btn btn"
                      disableRipple
                      onClick={() => {
                        if (formik.values.filter_name === "") {
                          setNameError("Please Enter Filter Name");
                          return;
                        }
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      className="secondary-btn btn"
                      disableRipple
                      onClick={() => setSaveFilterModal(false)}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={DeleteSavedFilter}
        closeAfterTransition
      >
        <Fade in={DeleteSavedFilter}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Typography variant="h3" component="h3">
                Do you want to delete this saved filter?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => onDeleteAssetFilter()}
                  >
                    Delete Filter
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setDeleteSavedFilterModal(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* END :: ASSETS LISTING VIEW */}
    </>
  );
};

export default Assets;
