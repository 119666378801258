import { useMemo } from "react";
import { useSelector } from "react-redux";
import { makeSelectUserPermissions } from "./selector"; // Adjust the import path
import { getHasPermission } from "../../utils/userPermissions";

const WithPermission = ({ permission: requiredPermission, element }) => {
  const selectUserPermissions = useMemo(makeSelectUserPermissions, []);
  const { userPermissions } = useSelector(selectUserPermissions);

  const hasAccess = useMemo(() => {
    return getHasPermission(requiredPermission, userPermissions);
  }, [requiredPermission, userPermissions]);

  return hasAccess ? element : null;
};

export default WithPermission;
