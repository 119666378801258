// feedbackSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "",
  message: "",
  code: 0,
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {
    setFeedback: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.code = action.payload.code;
    },
    clearFeedback: (state) => {
      state.status = "";
      state.message = "";
      state.code = 0;
    },
  },
});

export const { setFeedback, clearFeedback } = feedbackSlice.actions;
export default feedbackSlice.reducer;
