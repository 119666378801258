import axios from "axios";
import getTokenFromCookies from "../../utils/token";

axios.defaults.withCredentials = true;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Your API base URL
  headers: {
    common: {
      'Content-Type': 'application/json', // Set the default content type for non-file requests
    },
  },
});

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    const token = getTokenFromCookies();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Handle successful responses
    return response.data;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);

export default api;
