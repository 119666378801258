import React, { useState, useEffect, useCallback } from "react";
import { RiDeleteBinLine, RiEyeLine, RiPencilLine } from "react-icons/ri";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Tabs,
  Tab,
  Badge,
  Typography,
  Tooltip,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import AssetsDeleteModal from "../../../components/common/AssetsDeleteModal";
import { fetchAsset } from "../../../store/slices/assetSlice";
import { useDispatch } from "react-redux";
import Loader from "../../../components/common/Loader";
import moment from "moment/moment";
import { getUserRole, roundToTwoDecimal } from "../../../utils/helper";
import {
  fullAsset,
  updateAssets,
  viewAssets,
} from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    position: "unset",
    transform: "unset",
    marginLeft: "4px",
    lineHeight: "16px",
    fontSize: "14px",
    padding: "0 4px",
    background: "#7cb900",
    fontWeight: "700",
    color: "#fff",
    zIndex: 0,
    marginTop: "-4px",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AssetsList = ({
  searchAsset,
  applyedFilter,
  onPageChange,
  onValueChange,
  currentPage,
  value,
  rowperPage,
  onChangerowsPerPage,
  filterApplied,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      childvalue: 0,
      currentPage: 1,
      rowperPage: 10,
      searchAsset: "",
      filterApplied: false,
    };
  }
  const [loader, setLoader] = useState(true);

  const [count, setCount] = useState(1);
  const [asset, setAsset] = useState([]);
  const [assetId, setAssetId] = useState("");
  const [assetCount, setAssetCount] = useState("");
  const role = getUserRole();
  // START :: TAB WISE LISTING FOR ASSETS TICKET

  const handleChange = (event, newValue) => {
    onValueChange(newValue);
    onPageChange(1);
  };

  const handleChangeRowsPerPage = (event) => {
    onChangerowsPerPage(parseInt(event.target.value, 10));
    onPageChange(1);
  };

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };

  useEffect(() => {
    fetchData();
  }, [
    searchAsset,
    currentPage,
    value,
    applyedFilter,
    filterApplied,
    rowperPage,
  ]);

  const getTabValueString = (tabValue) => {
    switch (tabValue) {
      case 1:
        return "in_service";
      case 2:
        return "under_repair";
      case 3:
        return "out_of_service";
      case 0:
        return "in_stock_available";
      case 4:
        return "scrap";
      default:
        return "in_service";
    }
  };

  const getStatusString = (tabValue) => {
    switch (tabValue) {
      case "in_service":
        return "In Service";
      case "under_repair":
        return "Under repair";
      case "out_of_service":
        return "Out of service";
      case "in_stock_available":
        return "In Stock";
      case "scrap":
        return "Scrap";
      default:
        return "In Service";
    }
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      if (role === "admin") {
        const response = await dispatch(
          fetchAsset(
            getTabValueString(value),
            currentPage,
            searchAsset,
            applyedFilter,
            rowperPage
          )
        );
        if (response?.success) {
          setAsset(response?.data?.results);
          setAssetCount(response?.data);
          setCount(response?.data?.totalCount);
        } else {
          console.error("Failed! Something went wrong.");
        }
      } else {
        const response = await dispatch(
          fetchAsset("", currentPage, searchAsset, "", rowperPage)
        );
        if (response?.success) {
          setAsset(response?.data?.results);
          setCount(response?.data?.totalCount);
        } else {
          console.error("Failed! Something went wrong.");
        }
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  // START :: DELETE MODAL
  const [assetsDelete, setAssetsDelete] = useState(false);

  // END :: DELETE MODAL

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <Typography variant="body1" component="span" className="user-name">
          {role}
        </Typography>{" "}
        {modules !== 0 ? (
          <StyledBadge
            badgeContent={modules}
            color="success"
            max={9999}
          ></StyledBadge>
        ) : null}
      </Box>
    );
  };
  // END :: TAB WISE LISTING FOR ASSETS TICKET

  // useCallback(() => {
  //   onPageChange(1);
  // }, [value]);

  useEffect(() => {
    onValueChange(value);
    // onPageChange(1);
  }, [state]);

  return (
    <>
      <>
        <Box variant="div" component="div" className="tab-wrapper user-listing">
          {role === "user" ? null : (
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="tab-area"
              >
                <Tab
                  label={tabLabel(
                    "In Stock",
                    assetCount?.countOfinStockAvailable
                  )}
                  {...a11yProps(3)}
                />
                <Tab
                  label={tabLabel("In Service", assetCount?.countOfInService)}
                  {...a11yProps(0)}
                />
                <Tab
                  label={tabLabel(
                    "Under Repair",
                    assetCount?.countOfunderRepair
                  )}
                  {...a11yProps(1)}
                />
                <Tab
                  label={tabLabel(
                    "Out Of Service",
                    assetCount?.countOfoutOfService
                  )}
                  {...a11yProps(2)}
                />
                <Tab
                  label={tabLabel("Scrap", assetCount?.countOfscrap)}
                  {...a11yProps(4)}
                />
              </Tabs>
            </Box>
          )}

          <CustomTabPanel
            value={value}
            index={value}
            className="user-listing-tables"
          >
            <TableContainer className="table-listing">
              <Table
                sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Asset Code</TableCell>
                    <TableCell align="left">Asset Name</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Company</TableCell>
                    <TableCell align="left">Branch</TableCell>
                    {role === "user" ? (
                      <TableCell align="left">Current Status</TableCell>
                    ) : null}
                    <TableCell align="left">Assign To</TableCell>
                    <TableCell align="left">Assign Date</TableCell>
                    {role === "admin" ? (
                      <TableCell align="left">Purchase Price (₹)</TableCell>
                    ) : null}
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                {loader ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        {" "}
                        <Loader />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody>
                    {asset?.length === 0 ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="center"
                          sx={{ fontSize: "16px" }}
                          colSpan={8}
                        >
                          Assets Not Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      asset?.map((item) => {
                        const assigned_user =
                          item?.assigned_user?.first_name +
                          " " +
                          item?.assigned_user?.last_name;
                        return (
                          <TableRow
                            key={item.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">
                              {item?.asset_code}
                            </TableCell>
                            <TableCell align="left">
                              {item?.asset_name}
                            </TableCell>
                            <TableCell align="left">
                              {item?.asset_category?.name}
                            </TableCell>
                            <TableCell align="left">
                              <Tooltip
                                title={
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item?.asset_company?.company_name}
                                  </div>
                                }
                                arrow
                              >
                                {/* {item?.company_branches?.name} */}
                                {item?.asset_company?.company_name.length < 16
                                  ? item?.asset_company?.company_name
                                  : item?.asset_company?.company_name.slice(
                                      0,
                                      15
                                    ) + "..."}
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left">
                              {item?.company_branches?.name}
                            </TableCell>

                            {role === "user" ? (
                              <TableCell align="left">
                                {getStatusString(item?.current_status)}
                              </TableCell>
                            ) : null}
                            <TableCell align="left">
                              <Tooltip
                                title={
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {assigned_user}
                                  </div>
                                }
                                arrow
                              >
                                {assigned_user.length < 20
                                  ? assigned_user
                                  : assigned_user.slice(0, 19) + "..."}
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left">
                              {item?.assign_date
                                ? moment(item?.assign_date).format("DD-MM-YYYY")
                                : "-"}
                            </TableCell>
                            {role === "admin" ? (
                              <TableCell align="left">
                                {roundToTwoDecimal(item?.purchase_price + "")}
                              </TableCell>
                            ) : null}
                            <TableCell align="left">
                              <Box className="action-btn-wrap">
                                <WithPermission
                                  permission={viewAssets}
                                  element={
                                    <Button
                                      variant="outlined"
                                      className="action-btn view-btn"
                                      startIcon={<RiEyeLine />}
                                      disableRipple
                                      onClick={() =>
                                        navigate(`/assets/view/${item?.id}`, {
                                          state: {
                                            // asset: item?.id,
                                            // category: value,
                                            childvalue: value,
                                            currentPage: currentPage,
                                            searchAsset: searchAsset,
                                            rowperPage: rowperPage,
                                            filterApplied: filterApplied,
                                            applyedFilter: applyedFilter,
                                          },
                                        })
                                      }
                                    ></Button>
                                  }
                                />
                                <WithPermission
                                  permission={updateAssets}
                                  element={
                                    <Button
                                      variant="outlined"
                                      className="action-btn edit-btn"
                                      startIcon={<RiPencilLine />}
                                      disableRipple
                                      onClick={() =>
                                        navigate(`/assets/edit/${item?.id}`, {
                                          state: {
                                            childvalue: value,
                                            currentPage: currentPage,
                                            searchAsset: searchAsset,
                                            rowperPage: rowperPage,
                                            filterApplied: filterApplied,
                                            applyedFilter: applyedFilter,
                                          },
                                        })
                                      }
                                    ></Button>
                                  }
                                />
                                <WithPermission
                                  permission={fullAsset}
                                  element={
                                    <Button
                                      variant="outlined"
                                      className="action-btn dlt-btn"
                                      startIcon={<RiDeleteBinLine />}
                                      disableRipple
                                      onClick={() => {
                                        setAssetId(item?.id);
                                        setAssetsDelete(true);
                                      }}
                                    ></Button>
                                  }
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
            <CustomTablePaginationToolbar
              count={count}
              page={currentPage}
              rowsPerPage={rowperPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CustomTabPanel>
        </Box>

        <AssetsDeleteModal
          assetsDelete={assetsDelete}
          setAssetsDelete={setAssetsDelete}
          assetId={assetId}
          setAssetId={setAssetId}
          fetchData={fetchData}
        />
      </>
    </>
  );
};
export default AssetsList;
