import React from "react";
import { RiEyeLine } from "react-icons/ri";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { Loader } from "rsuite";
import { useNavigate } from "react-router-dom";

const OpenTicketInfoList = ({ openTicketInfo, loader }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <TableContainer className="table-listing">
          <Box className="tickets-listing" style={{ minHeight: "420px" }}>
            <Table
              sx={{ minWidth: 580 }}
              aria-label="table"
              className="listing-table"
            >
              <TableHead
                className="table-header"
                sx={{ zIndex: "1", backgroundColor: "white" }}
              >
                <TableRow>
                  <TableCell align="left">Ticket #</TableCell>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Created By</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      {" "}
                      <Loader />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {openTicketInfo?.length === 0 ? (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="center"
                        sx={{ fontSize: "16px" }}
                        colSpan={7}
                      >
                        Open Ticket Information Not Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    openTicketInfo?.map((item) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left">{item?.id}</TableCell>
                          <TableCell align="left">{item?.title}</TableCell>
                          <TableCell align="left">
                            {item?.createdBy_user?.first_name +
                              " " +
                              item?.createdBy_user?.last_name}
                          </TableCell>
                          <TableCell align="left">
                            <Box className="action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn view-btn"
                                startIcon={<RiEyeLine />}
                                disableRipple
                                onClick={() =>
                                  navigate(`/asset-tickets/view/${item?.id}`)
                                }
                              ></Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </Box>
        </TableContainer>
      </Box>
    </>
  );
};

export default OpenTicketInfoList;
