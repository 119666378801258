import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Paper,
  TextareaAutosize,
  FormHelperText,
  CircularProgress,
  ListItem,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import {RiDeleteBinLine} from 'react-icons/ri';
import { RiArrowLeftLine } from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchAsset } from "../../../store/slices/assetSlice";
import { useDispatch } from "react-redux";
import {
  editAssetTicket,
  fectchAssetTicketById,
  uploadFile,
} from "../../../store/slices/assetTicketSlice";

import { successToast } from "../../../response/successToast";
import { fetchUsers } from "../../../store/slices/userSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchVendor } from "../../../store/slices/vendorSlice";
import { getUserRole, roundToTwoDecimal } from "../../../utils/helper";
import getuserDataFromCookies from "../../../utils/userData";
import moment from "moment";

// STATUS FOR OPTIONS
const statusFor = [
  { value: "new", label: "New" },
  { value: "pending", label: "Pending" },
  { value: "completed", label: "Completed" },
  { value: "rejected", label: "Rejected" },
];

const AssetTicketsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const [errormessage, setErrorMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deletedFile,setDeletedFile]=useState(null)
  const [selectedassetFile, setSelectedAssetFile] = useState(null);
  const {
    getRootProps: getRepairingRootProps,
    getInputProps: getRepairingInputProps,
  } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/svg": [".svg"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 2,
    maxSize: 2 * 1024 * 1024,
    onDrop: (acceptedFiles, fileRejections) => {
      console.log("ACCEPTED FILES",acceptedFiles)
      if (fileRejections.length > 0) {
        const rejectedFile = fileRejections[0];

        if (rejectedFile.errors.some((err) => err.code === "file-too-large")) {
          setErrorMessage(
            "File size greater than 2MB, Could not upload, please try again"
          );
        } else if (rejectedFile.file) {
          const acceptedFormats = [
            "image/png",
            "image/jpeg",
            "image/svg+xml",
            "application/pdf",
          ];
          const fileFormat = rejectedFile.file.type;
          
          if (!acceptedFormats.includes(fileFormat)) {
            setErrorMessage(
              "Unsupported file format, Could not upload, please try again"
            );
          }
        }
      } 
      
      else if (acceptedFiles.length > 0) {
        setSelectedFiles(prev => [...prev,...acceptedFiles]);
        setErrorMessage("");
      }
    },
  });
  const { getRootProps: getAssetRootProps, getInputProps: getAssetInputProps } =
    useDropzone({
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpeg"],
        "image/svg": [".svg"],
        "application/pdf": [".pdf"],
      },
      maxFiles: 1,
      maxSize: 2 * 1024 * 1024,
      onDrop: (acceptedFiles, fileRejections) => {
        if (fileRejections.length > 0) {
          const rejectedFile = fileRejections[0];

          if (
            rejectedFile.errors.some((err) => err.code === "file-too-large")
          ) {
            setErrorMessage(
              "File size greater than 2MB, Could not upload, please try again"
            );
          } else if (rejectedFile.file) {
            const acceptedFormats = [
              "image/png",
              "image/jpeg",
              "image/svg+xml",
              "application/pdf",
            ];
            const fileFormat = rejectedFile.file.type;

            if (!acceptedFormats.includes(fileFormat)) {
              setErrorMessage(
                "Unsupported file format, Could not upload, please try again"
              );
            }
          }
        } else if (acceptedFiles.length > 0) {
          setSelectedAssetFile(acceptedFiles[0]);
          setErrorMessage("");
        }
      },
    });
  const [loader, setLoader] = useState(false);
  const [assetsOptions, setAssetsOptions] = useState([]);
  const [assetTicket, setAssetTicket] = useState([]);
  const [userList, setUserList] = useState([]);
  console.log("🚀 ~ AssetTicketsEdit ~ userList:", userList);
  const [vendorList, setVendorList] = useState([]);
  const { id } = useParams();
  const role = getUserRole();
  const numberInputRef = useRef(null);
  const purchaseInputRef = useRef(null);
  const formData = new FormData();
  const handleDeleteBill=(path,key)=>{
    const updatedDocs=selectedFiles.filter(
      (item)=>item.path!==path
    )
    if(key===0)
    setDeletedFile("repairing_bills")
    if(key===1)
    setDeletedFile("repairing_bills_2")
  
    setSelectedFiles(updatedDocs)
  }

  const fetchAssets = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAsset("", 1, "", "", 10000));
      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((asset) => ({
          value: asset.id,
          label: asset?.asset_code + " - " + asset.asset_name,
        }));
        setAssetsOptions(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchVendorList = async () => {
    setLoader(true);
    try {
      if (role === "admin") {
        const response = await dispatch(fetchVendor(true));

        if (response?.success) {
          const formattedOptions = response?.data?.result?.map((vendor) => ({
            value: vendor.id,
            label: vendor.company_name,
          }));
          setVendorList(formattedOptions);
        } else {
          console.error("Failed! Something went wrong.");
        }
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const fetchUsersList = async () => {
    setLoader(true);
    try {
      if (role === "admin") {
        const response = await dispatch(
          fetchUsers({ pagination: false, allusers: "yes" })
        );
        if (response?.payload) {
          const formattedOptions = response?.payload?.results?.map((user) => ({
            value: user.id,
            label:
              user?.employee_number +
              " - " +
              user.first_name +
              " " +
              user.last_name,
          }));
          setUserList(formattedOptions);
        } else {
          console.error("Failed! Something went wrong.");
        }
      } else {
        const userData = getuserDataFromCookies();

        const createdFor = [
          {
            value: parseInt(userData?.id),
            label: userData?.employee_number+" - "+userData?.fname + "  " + userData?.lname,
          },
        ];

        setUserList(createdFor);
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(()=>{
    if(selectedFiles.length===2){
      if(selectedFiles[0].name==="Repairing Bill-2"){
        setSelectedFiles([selectedFiles[1],selectedFiles[0]])
      }
    }
  },[selectedFiles])

  const fetchData = async () => {
    try {
      setLoader(true);
      console.log("EXECUTED TWICE")
      const response = await dispatch(fectchAssetTicketById(id));
      if (response?.success) {
        setAssetTicket(response?.data);
        if(response?.data?.repairing_bill!==null){
          console.log(response?.data?.repairing_bill)
          setSelectedFiles(prev => [...prev,{path:response?.data?.repairing_bill[0],name:"Repairing Bill-1"}])
        }
        if(response?.data?.repairing_bill_2!==null){
          setSelectedFiles(prev => [...prev,{path:response?.data?.repairing_bill_2[0],name:"Repairing Bill-2"}])
        }
        if(response?.data?.repairing_bill_2===null&&response?.data?.repairing_bill===null){
          setSelectedFiles(prev=>[])
        }
      } else {
        console.error("Failed ! Something went wrong");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleWheelChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
      "Home",
      "End",
    ];
    const isAllowedKey = allowedKeys.includes(e.key);
    const isNumeric = /^[0-9.]+$/.test(e.key);

    if (!isAllowedKey && !isNumeric) {
      e.preventDefault();
    }
  };

  let validationSchema=null

  if(role=="admin"){
  validationSchema = Yup.object().shape({
    asset_id: Yup.string().required("Please select asset"),
    title: Yup.string().required("Please enter ticket title"),
    description: Yup.string().required("Please enter ticket description"),
    created_for: Yup.string().required("Please select ticket assignee"),
    request_date: Yup.string().required("Please select request date"),
    vendor_id: Yup.string().when("status",{
      is:(value) => ["new","pending", "completed"].includes(value),
      then: () => Yup.string().required("Please select vendor"),
      otherwise: () => Yup.string().nullable(), // Define validation when status is not "completed"
    }),
    resolution_date: Yup.string().when("status", {
      is: "completed",
      then: () => Yup.string().required("Please select resolution date"),
      otherwise: () => Yup.string().nullable(), // Define validation when status is not "completed"
    }),
    repairing_cost: Yup.string().when("status", {
      is: "completed",
      then: () =>
        Yup.string()
          .required("Please enter repairing cost"),
          // .matches(
          //   /^(0|[1-9]\d*)(\.\d{1,2})?$/,
          //   "Must be a positive number or a decimal up to 2 digits"
          // ),
      otherwise: () => Yup.string().nullable(), // Define validation when status is not "completed"
    }),
    // repairing_bill: Yup.string().required("This is required field"),
    status: Yup.string().required("Select ticket status"),
    // charges: Yup.string()
    //   .required("This is a required field")
    //   .matches(
    //     /^([1-9]\d*)(\.\d{1,2})?$/,
    //     "Must be a positive number or a decimal up to 2 digits"
    //   ),
    // vendor: Yup.string().required("This is required field"),
  });
}
else{
  validationSchema = Yup.object().shape({
    asset_id: Yup.string().required("Please select asset"),
    title: Yup.string().required("Please enter ticket title"),
    description: Yup.string().required("Please enter ticket description"),
    created_for: Yup.string().required("Please select ticket assignee")})
}

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
    helperText: formik.touched[field.name] && formik.errors[field.name] && (
      <FormHelperText className="error-message">
        {formik.errors[field.name]}
      </FormHelperText>
    ),
  });
  useEffect(()=>{
    console.log(selectedFiles,"SELECTED FILES")
  },[selectedFiles])

  const formik = useFormik({
    initialValues: {
      asset_id: "",
      title: "",
      description: "",
      created_for: "",
      request_date: "",
      vendor_id: "",
      resolution_date: "",
      repairing_cost: "",
      status: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        values.repairing_cost=values.repairing_cost!==null&&values.repairing_cost!==""?values.repairing_cost.replace(/,/g, ''):null;
        setLoader(true);
        
        for (const key in values) {
          if (Object.hasOwnProperty.call(values, key)) {
            if(values[key]!==null)
            {
              formData.append(key, values[key]);
            }
          }
      }
      console.log("FORM DATA: ",formData)

        // Check if a file is selected
        if (selectedFiles.length==1) {
          if(selectedFiles[0].lastModified!==undefined){
            formData.append("repairing_bill",selectedFiles[0])
          }
          formData.append(deletedFile?deletedFile:"repairing_bills_2",true)
          
        }
        else if(selectedFiles.length==2){
          if(selectedFiles[0].lastModified!==undefined)
          formData.append("repairing_bill",selectedFiles[0])
          if(selectedFiles[1].lastModified!==undefined)
          formData.append("repairing_bill_2",selectedFiles[1])
          console.log(selectedFiles,"Form data files")
        }
        else{
          formData.append("repairing_bills_2",true)
          formData.append("repairing_bills",true)
        }

        const assetTicketResponse = await dispatch(editAssetTicket(formData,id));
        if (assetTicketResponse?.success) {
          successToast(
            assetTicketResponse?.message || "Asset ticket updated Successfully"
          );
          navigate("/asset-tickets");
        } else {
          console.error("Failed! Something went wrong.");
        }
      } catch (err) {
        console.error("Error:", err);
      } finally {
        setLoader(false);
      }
    },
  });
  useEffect(() => {
    fetchAssets();
    fetchData();
    fetchUsersList();
    fetchVendorList();
  }, []);

  useEffect(() => {
    if(assetTicket.length!==0)
    formik.setValues({
      asset_id: assetTicket?.asset_id,
      title: assetTicket?.title,
      description: assetTicket?.description,
      created_for:
        assetTicket?.created_for,
      request_date: new Date(assetTicket?.request_date),
      vendor_id: assetTicket?.vendor_id,
      resolution_date: assetTicket?.resolution_date
        ? new Date(assetTicket?.resolution_date)
        : null,
      repairing_cost: assetTicket?.repairing_cost&&assetTicket?.repairing_cost!=="NaN"?roundToTwoDecimal(assetTicket?.repairing_cost+""):null,
      status: assetTicket?.status,
    });
  }, [assetTicket]);

  //Prevent the default scroll behavior for number inputs
  const handleWheel = (e) => {
    if (numberInputRef.current === document.activeElement) {
      e.preventDefault();
      const delta = Math.sign(e.deltaY);
      if (delta !== 0) {
        const step = parseFloat(numberInputRef.current.step) || 1;
        const newValue =
          parseFloat(numberInputRef.current.value || 0) + step * delta;
        numberInputRef.current.value = newValue.toFixed(2); // Set value to the nearest two decimal places
        formik.setFieldValue("repairing_cost", roundToTwoDecimal(newValue+"")); // Update formik value
      }
    }
  };
  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/asset-tickets", { state: state })}
          ></Button>
          Edit Asset Ticket
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper" sx={{ height: "100%" }}>
          <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset"
                  name="asset_id"
                  options={assetsOptions}
                  className={`muilt-select-field ${
                    formik.touched.asset_id && formik.errors.asset_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("asset_id", selectedOption?.value);
                    formik.setFieldTouched("asset_id", false);
                  }}
                  value={assetsOptions.find(
                    (option) => option.value === formik.values.asset_id
                  )}
                  isSearchable //optional
                />
                {formik.touched.asset_id && formik.errors.asset_id && (
                  <FormHelperText className="error-message">
                    {formik.errors.asset_id}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Title
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={
                    formik.touched.title && (
                      <span className="error-message">
                        {formik.errors.title}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Description
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  multiline
                  rows={4.5}
                  className="input-field"
                  {...getFieldProps({
                    name: "description",
                    required: true,
                    placeholder: "Add Description",
                  })}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                />
                {/* {formik.touched.description && formik.errors.description && (
                  <FormHelperText className="error-message">
                    {formik.errors.description}
                  </FormHelperText>
                )} */}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body1" component="label">
                      Created For
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Select
                    
                      placeholder="Select Created For"
                      // isMulti
                      name="created_for"
                      options={userList}
                      className={`muilt-select-field ${
                        formik.touched.asset_id && formik.errors.asset_id
                          ? "has-error"
                          : ""
                      }`}
                      classNamePrefix="select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "var(--green)",
                        },
                      })}
                      onBlur={formik.handleBlur}
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          "created_for",
                          selectedOption?.value
                        );
                        formik.setFieldTouched("created_for", false);
                      }}
                      value={userList.find(
                        (option) => option.value === formik.values.created_for
                      )}
                      isSearchable //optional
                    />
                    {formik.touched.created_for &&
                      formik.errors.created_for && (
                        <FormHelperText className="error-message">
                          {formik.errors.created_for}
                        </FormHelperText>
                      )}
                  </Box>
                  {role === "admin" && (
                    <Box>
                      <Typography variant="body1" component="label">
                        Request Date
                        <Typography
                          variant="body1"
                          component="label"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              fullWidth
                              id="outlined-basic-date"
                              label="Purchase Date"
                              variant="outlined"
                              size="small"
                              placeholder="Select Assign Date"
                              name="request_date"
                              value={formik.values.request_date}
                              onBlur={() =>
                                formik.setFieldTouched("request_date", true)
                              }
                            />
                          )}
                          className={`input-field ${
                            formik.touched.request_date &&
                            formik.errors.request_date
                              ? "error"
                              : ""
                          }`}
                          value={formik.values.request_date}
                          onChange={(date) => {
                            formik.setFieldValue("request_date", date);
                          }}
                          maxDate={new Date(moment())}
                          format="dd-MM-yyyy"
                        />
                        {formik.touched.request_date &&
                          formik.errors.request_date && (
                            <FormHelperText className="error-message">
                              {formik.errors.request_date}
                            </FormHelperText>
                          )}
                      </LocalizationProvider>
                    </Box>
                  )}
                </Stack>
              </Grid>
              {role === "admin" && (
                <>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Status
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Select
                      placeholder="Select Status"
                      name="status"
                      options={statusFor}
                      className={`muilt-select-field ${
                        formik.touched.status && formik.errors.status
                          ? "has-error"
                          : ""
                      }`}
                      classNamePrefix="select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "var(--green)",
                        },
                      })}
                      onBlur={formik.handleBlur}
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          "status",
                          selectedOption?.value || ""
                        );
                        formik.setFieldTouched("status", true);
                        formik.setFieldTouched("repairing_cost", false);
                      }}
                      value={statusFor.find(
                        (option) => option.value === formik.values.status
                      )}
                      isSearchable // Optional: Enable searching in the dropdown
                    />
                    {formik.touched.status && formik.errors.status && (
                      <FormHelperText className="error-message">
                        {formik.errors.status}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Repairing Vendor
                      {formik.values.status !== "rejected" && (
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                      )}
                    </Typography>
                    <Select
                      placeholder="Select Repairing Vendor"
                      name="vendor_id"
                      options={vendorList}
                      className={`muilt-select-field ${
                        formik.touched.vendor_id && formik.errors.vendor_id
                          ? "has-error"
                          : ""
                      }`}
                      classNamePrefix="select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "var(--green)",
                        },
                      })}
                      onBlur={formik.handleBlur}
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          "vendor_id",
                          selectedOption?.value || ""
                        );
                        formik.setFieldTouched("vendor_id", false);
                      }}
                      value={vendorList.find(
                        (option) => option.value === formik.values.vendor_id
                      )}
                      isSearchable // Optional: Enable searching in the dropdown
                    />
                    {formik.touched.vendor_id && formik.errors.vendor_id && (
                      <FormHelperText className="error-message">
                        {formik.errors.vendor_id}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Resolution Date
                      {formik.values.status === "completed" && (
                        <Typography
                          variant="body1"
                          component="label"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      )}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            fullWidth
                            id="outlined-basic-date"
                            label="Assign Date"
                            variant="outlined"
                            size="small"
                            placeholder="Select Assign Date"
                            name="resolution_date"
                            value={formik.values.resolution_date}
                            // onBlur={() =>
                            //   formik.setFieldTouched("resolution_date", true)
                            // }
                          />
                        )}
                        className="input-field-123"
                        value={formik.values.resolution_date}
                        onChange={(date) => {
                          formik.setFieldValue("resolution_date", date);
                        }}
                        maxDate={new Date(moment())}
                        format="dd-MM-yyyy"
                      />
                      {formik.touched.resolution_date &&
                        formik.errors.resolution_date && (
                          <FormHelperText className="error-message">
                            {formik.errors.resolution_date}
                          </FormHelperText>
                        )}
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Repairing Cost
                      {formik.values.status === "completed" && (
                        <Typography
                          variant="body1"
                          component="label"
                          className="asterisk"
                        >
                          *
                        </Typography>
                      )}
                    </Typography>
                    <Box className="field-icon">
                      <div style={{ position: "relative" }}>
                        <TextField
                          inputRef={numberInputRef}
                          onWheel={handleWheel}
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Enter Repairing Cost"
                          name="repairing_cost"
                          value={formik.values.repairing_cost}
                          onChange={formik.handleChange}
                          onKeyDown={handleKeyDown}
                          //onBlur={formik.handleBlur}
                          error={
                            formik.touched.repairing_cost &&
                            Boolean(formik.errors.repairing_cost)
                          }
                          helperText={
                            formik.touched.repairing_cost && (
                              <span className="error-message">
                                {formik.errors.repairing_cost}
                              </span>
                            )
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography
                                  variant="body1"
                                  component="label"
                                  className="rupee-symbol"
                                >
                                  ₹
                                </Typography>
                              </InputAdornment>
                            ),
                            style: { paddingLeft: "40px" },
                          }}
                          // type="number"
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            const { value } = e.target;
                            const roundedValue = roundToTwoDecimal(value+"");
                            formik.setFieldValue(
                              "repairing_cost",
                              roundedValue
                            );
                          }}
                        />
                      </div>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Repairing Bill
                    </Typography>
                    <Box variant="div" component="div" className="drop-zone">
                     {selectedFiles.length!==2&& <Paper
                        className="drop-zone-inner"
                        {...getRepairingRootProps()}
                      >
                        <input
                          {...getRepairingInputProps()}
                          className="repairing_bill"
                        />
                        <Box className="msg-wrap">
                          <FiUploadCloud />
                          <Typography variant="h6" component="h6">
                            Drag & drop file or <u>Browse</u>
                          </Typography>

                          <Typography
                            variant="body1"
                            component="p"
                            className="sub-msg"
                          >
                            <Typography variant="body2" component="span">
                              Maximum Number of files:
                            </Typography>{" "}
                            2
                          </Typography>
                          <Typography
                            variant="body1"
                            component="p"
                            className="sub-msg"
                          >
                            <Typography variant="body2" component="span">
                              File Size:
                            </Typography>{" "}
                            not more than 2MB
                          </Typography>
                        </Box>
                      </Paper>
                      }
                      {selectedFiles.length!==0 && !errormessage && (
                        <Typography variant="body1" component="label">
                          {
                            selectedFiles.map((item,key)=><div key={key}>
                            <Box
                                className="file-delete"
                                gap={1}
                                style={{
                                    paddingTop: '10px',
                                }}
                            >
                                <Typography
                                    className="file-name-delete value"
                                    variant="body1"
                                    component="p"
                                >
                                    
                                        {
                                            item?.name
                                        }
                                    
                                </Typography>
                                <Button
                                    variant="outlined"
                                    className="action-btn dlt-btn file-action-btn-delete"
                                    startIcon={
                                        <RiDeleteBinLine />
                                    }
                                    disableRipple
                                    key={key}
                                    onClick={(e) => 
                                      {
                                        e.preventDefault()
                                      // console.log(filtredArray, selectedFiles)   
                                      handleDeleteBill(item.path,key)
  
                                      
                                      }
                                    }
                                ></Button>
                            </Box>
                            </div>
                              

                            )
                          }
                            
                        
                        </Typography>
                      )}
                      {/* Display error message if any */}
                      {errormessage && (
                        <FormHelperText className="error-message">
                          {errormessage}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </>
              )}

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="footer-wrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className="primary-btn btn w-120"
                    disableRipple
                    disabled={loader}
                    endIcon={
                      loader && <CircularProgress size={20} color="inherit" />
                    }
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn w-120"
                    disableRipple
                    onClick={() => {
                      navigate("/asset-tickets");
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AssetTicketsEdit;
