import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Loader from "../../../components/common/Loader";

const HighestPurchaseVendorList = ({ hightestPurchaseVendorList, loader }) => {
  return (
    <Box variant="div" component="div" className="tab-wrapper user-listing">
      <TableContainer className="table-listing" style={{ minHeight: "100px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="table" className="listing-table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="left">Contact Person Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Contact No.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loader ? (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : hightestPurchaseVendorList?.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  Highest Purchasing Vendor Detail Not Found
                </TableCell>
              </TableRow>
            ) : (
              hightestPurchaseVendorList?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{item?.vendor?.company_name}</TableCell>
                  <TableCell align="left">{item?.vendor?.contact_person}</TableCell>
                  <TableCell align="left">{item?.vendor?.email === "" ? "-" :item?.vendor?.email}</TableCell>
                  <TableCell align="left">
                    {item?.vendor?.phone_number === null ? "-" : item?.vendor?.phone_number}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HighestPurchaseVendorList;
