import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";
import { setUserPermissions } from "./permissionSlice";

const initialState = {
  user: null,
  isLoading: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure } = authSlice.actions;

export default authSlice.reducer;

//API CALL
export const login = (values) => async (dispatch) => {
  try {
    dispatch(loginStart());

    const response = await api.post("/auth/login", values);
    const data = response.data;
    dispatch(loginSuccess(data));
    const resData = data;
    console.log(resData, data);
    console.log(resData);
    const permissions = resData?.role_permissions?.permissions || [];

    dispatch(setUserPermissions(permissions));
    // console.log(response, "data");

    return { success: true, message: data.message, detail: data };
  } catch (error) {
    console.log(error);
    dispatch(loginFailure(error.response.data.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};
