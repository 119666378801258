import { createSlice } from "@reduxjs/toolkit";
// import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";
import Cookies from "js-cookie";

const initialState = {
  isLoading: false,
  error: null,
};

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    logoutFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { logoutStart, logoutSuccess, logoutFailure } =
  logoutSlice.actions;

export default logoutSlice.reducer;

export const logout = (token) => async (dispatch) => {
  try {
    dispatch(logoutStart());
    // eslint-disable-next-line
    const response = await api.get("/auth/logout");
    dispatch(logoutSuccess());
    Cookies.remove(process.env.REACT_APP_USER_DATA_COOKIE);
    Cookies.remove(process.env.REACT_APP_COOKIE_NAME);
    Cookies.remove(process.env.REACT_APP_USER_ID_COOKIE);
  } catch (error) {
    dispatch(logoutFailure(error.response.data.message));
  }
};
