import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  TextareaAutosize,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchAssetCategory } from "../../../store/slices/assetCategorySlice";
import moment from "moment/moment";
import { successToast } from "../../../response/successToast";
import { createPurchaseRequest } from "../../../store/slices/purchaseRequestSlice";

const PurchaseRequestCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [assetCategoryList, setAssetCategoryList] = useState([]);
  const currentDate = moment().format("MM/DD/YYYY");
  const companyList = [
    { value: 1, label: "Crest Infosystems Pvt. Ltd." },
    { value: 2, label: "Crest Skillserve Pvt. Ltd." },
  ];
  const validationSchema = Yup.object().shape({
    asset_category_id: Yup.string().required("Please select asset category"),
    asset_company_id: Yup.string().required("Please select asset company"),
    title: Yup.string().required("Please enter purchase request title"),
    description: Yup.string().required("Please enter description"),
  });

  const formik = useFormik({
    initialValues: {
      asset_category_id: "",
      asset_company_id: "",
      title: "",
      description: "",
      comments: "",
      status: "requested",
      request_date: currentDate,
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        setLoader(true);
        dispatch(createPurchaseRequest(values)).then((response) => {
          if (response?.success) {
            successToast(
              response?.message || "Purchase request Added Successfully"
            );
            navigate("/purchase-request");
          } else {
            console.error("Failed! Something went wrong.");
          }
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoader(false);
      }
    },
  });

  const fetchAssetCategoryList = async () => {
    setLoader(true);
    try {
      const listing = true;
      const response = await dispatch(fetchAssetCategory(1, "", listing));

      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((category) => ({
          value: category.id,
          label: category.name,
        }));
        setAssetCategoryList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
    helperText: formik.touched[field.name] && formik.errors[field.name] && (
      <FormHelperText className="error-message">
        {formik.errors[field.name]}
      </FormHelperText>
    ),
  });

  useEffect(() => {
    fetchAssetCategoryList();
  }, []);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/purchase-request")}
          ></Button>
          Create Asset Purchase Request
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Category
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *{" "}
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset Category"
                  name="asset_category_id"
                  options={assetCategoryList}
                  className={`muilt-select-field ${
                    formik.touched.asset_category_id &&
                    formik.errors.asset_category_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "asset_category_id",
                      selectedOption?.value
                    );
                    formik.setFieldTouched("asset_category_id", true);
                  }}
                  value={assetCategoryList.find(
                    (option) => option.value === formik.values.asset_category_id
                  )}
                  isSearchable //optional
                />

                {formik.touched.asset_category_id &&
                  formik.errors.asset_category_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.asset_category_id}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Company
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset Company"
                  name="asset_company"
                  options={companyList}
                  className={`muilt-select-field ${
                    formik.touched.asset_company_id && formik.errors.asset_company_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "asset_company_id",
                      selectedOption?.value
                    );
                    formik.setFieldTouched("asset_company_id", false);
                  }}
                  value={companyList.find(
                    (option) => option.value === formik.values.asset_company_id
                  )}
                  isSearchable // Optional: Enable searching in the dropdown
                />
                {formik.touched.asset_company_id &&
                  formik.errors.asset_company_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.asset_company_id}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Title
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched("title", false)}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={
                    formik.touched.title && (
                      <span className="error-message">
                        {formik.errors.title}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Description
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    {" "}
                    *{" "}
                  </Typography>
                </Typography>
                <TextField
                  multiline
                  rows={3}
                  className="input-field"
                  placeholder="Add Asset Description"
                  {...getFieldProps({
                    name: "description",
                    required: true,
                    placeholder: "Add Asset Description",
                  })}
                  onBlur={() => formik.setFieldTouched("description", false)}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                />
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="footer-wrapper">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className="primary-btn btn w-120"
                  disableRipple
                  disabled={loader}
                  endIcon={
                    loader && <CircularProgress size={20} color="inherit" />
                  }
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn w-120"
                  disableRipple
                  onClick={() => {
                    navigate("/purchase-request");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default PurchaseRequestCreate;
