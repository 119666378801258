import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  TextareaAutosize,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { RiArrowLeftLine, RiPercentLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { successToast } from "../../../response/successToast";
import * as Yup from "yup";
import { createAssetCategory } from "../../../store/slices/assetCategorySlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter category name"),
  description: Yup.string(),
  depreciation_company_act: Yup.string()
    .required("Please enter depreciation(as per Company Act)")
    .test(
      "is-integer-or-float",
      "Must be an integer or decimal upto 2 digits",
      (value) => {
        if (!value) {
          return false; // It's required, and empty values are not allowed
        }
        // Check if the value is a valid integer or float
        return /^-?\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  depreciation_it_act: Yup.string()
    .required("Please enter depreciation(as per IT Act)")
    .test(
      "is-integer-or-float",
      "Must be an integer or decimal upto 2 digits",
      (value) => {
        if (!value) {
          return false; // It's required, and empty values are not allowed
        }
        // Check if the value is a valid integer or float
        return /^-?\d+(\.\d{1,2})?$/.test(value);
      }
    ),
});

const AssetCategoryCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      depreciation_company_act: "",
      depreciation_it_act: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      dispatch(createAssetCategory(values))
        .then((response) => {
          if (response?.success) {
            successToast(
              response?.message || "Asset Category Added Successfully"
            );
            navigate("/asset-category");
          } else {
            console.error("failed! Something went wrong");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
      setLoader(false);
    },
  });

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
    helperText: formik.touched[field.name] && formik.errors[field.name] && (
      <FormHelperText className="error-message">
        {formik.errors[field.name]}
      </FormHelperText>
    ),
  });

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/asset-category")}
          ></Button>
          Create Asset Category
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper" sx={{ height: "100%" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Category Name
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Category Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched("name", false)}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched.name && (
                      <span className="error-message">
                        {formik.errors.name}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Depreciation As Per Company Act
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Box className="field-icon">
                  <div>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Depreciation As Per Company Act"
                      name="depreciation_company_act"
                      value={formik.values.depreciation_company_act}
                      onChange={formik.handleChange}
                      onBlur={() =>
                        formik.setFieldTouched(
                          "depreciation_company_act",
                          false
                        )
                      }
                      error={
                        formik.touched.depreciation_company_act &&
                        Boolean(formik.errors.depreciation_company_act)
                      }
                      helperText={
                        formik.touched.depreciation_company_act && (
                          <span className="error-message">
                            {formik.errors.depreciation_company_act}
                          </span>
                        )
                      }
                      type="number"
                    />
                  </div>
                  <Box className="icon">
                    <RiPercentLine />
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Category Description
                </Typography>
                <TextField
                  multiline
                  rows={3}
                  className="input-field"
                  {...getFieldProps({
                    name: "description",
                    required: true,
                    placeholder: "Add Category Description",
                  })}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body1" component="label">
                      Depreciation As Per IT Act
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Box className="field-icon">
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Depreciation As Per IT Act"
                        name="depreciation_it_act"
                        value={formik.values.depreciation_it_act}
                        onChange={formik.handleChange}
                        onBlur={() =>
                          formik.setFieldTouched("depreciation_it_act", false)
                        }
                        error={
                          formik.touched.depreciation_it_act &&
                          Boolean(formik.errors.depreciation_it_act)
                        }
                        helperText={
                          formik.touched.depreciation_it_act && (
                            <span className="error-message">
                              {formik.errors.depreciation_it_act}
                            </span>
                          )
                        }
                        type="number"
                      />
                      <Box className="icon">
                        <RiPercentLine />
                      </Box>
                    </Box>
                  </Box>
                  
                </Stack>
              </Grid>
            </Grid>
            <Box className="footer-wrapper">
              <Button
                type="submit"
                variant="contained"
                size="large"
                className="primary-btn btn w-120"
                disableRipple
                disabled={loader}
                endIcon={
                  loader && <CircularProgress size={20} color="inherit" />
                }
              >
                {" "}
                Save
              </Button>
              <Button
                variant="contained"
                size="large"
                className="secondary-btn btn w-120"
                disableRipple
                onClick={() => {
                  navigate("/asset-category");
                }}
              >
                {" "}
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AssetCategoryCreate;
