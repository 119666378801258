import "./styles/globals.scss";
import "./styles/dashboard.scss";
import "./styles/assets.scss";
import "./styles/assets-ticket.scss";
import "./styles/settings.scss";

import Roots from "./routes";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import getTokenFromCookies from "./utils/token";
import { useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import getuserDataFromCookies from "./utils/userData";
import { fetchPermissions } from "./store/slices/permissionSlice";
import { fetchApplicationPermissions } from "./store/slices/applicationPermissionSlice";

export const getUserRole = () => {
  const user = getuserDataFromCookies();
  let role = user?.app_roles?.["Asset Tracker"];
  if (role === "Admin") {
    return "admin";
  } else if (role === "User") {
    return "user";
  } else {
    return "";
  }
};

function App() {
  const dispatch = useDispatch();
  const token = getTokenFromCookies();
  useLayoutEffect(() => {
    if (token) dispatch(fetchPermissions({}));
  }, [dispatch]);
  useLayoutEffect(() => {
    if (token) dispatch(fetchApplicationPermissions({}));
  }, [token, dispatch]);

  return (
    <>
      <BrowserRouter>
        {/* <Layout> */}
        <ToastContainer />
        <Roots />
        {/* </Layout> */}
      </BrowserRouter>
    </>
  );
}

export default App;
