import api from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchUsers = createAsyncThunk(
  "users",
  async (
    { page, rows, search, pagination, status, allusers },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.get(`/users`, {
        params: {
          status,
          page: page,
          rows: rows,
          search: search,
          pagination: pagination,
          allusers: allusers,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  users: [],
  status: "idle",
  error: null,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("users/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default userSlice.reducer;
