import { Box, Fade, Modal, Typography, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import api from "./commonfunctionforapi";
import { setFeedback } from "../../store/slices/feedbackSlice";
import { successToast } from "../../response/successToast";
import { useDispatch } from "react-redux";
import axios from 'axios';

const ExportToPdf = ({ exportCancel, isExcel, status, onCloseExport,reportId }) => {
  const dispatch = useDispatch();
  const [isExporting, setIsExporting] = useState(false);
  let pdf_url=null
  let excel_url=null
  let report_name=null
  if(reportId===1){
    console.log("REPORT ID IS ONE")
    pdf_url="/reports/assets/export/pdf"
    excel_url="/reports/assets/export/excel"
    report_name=`${status}-asset`
  }
  
  if(reportId===2){
    console.log("REPORT ID IS TWO")
    pdf_url="/reports/assetstickets/export/pdf"
    excel_url="/reports/assetstickets/export/excel"
    report_name="asset-tickets"
  }
  const exportPDF = async () => {
    setIsExporting(true);
    try {
      const response = await api.get(pdf_url, {
        responseType: "blob",
      });

      const data = response;
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      const currentDate = new Date();
      const timestamp = currentDate.toISOString().replace(/[-T:]/g, '').split('.')[0]; // Format: YYYYMMDDHHmmss

      const filename = `${report_name}-report-${timestamp}.pdf`;
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
      onCloseExport();
      successToast("Pdf exported successfully!");
      setIsExporting(false);
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.message,
          code: error.response.status_code,
        })
      );
      setIsExporting(false);
      onCloseExport();
    }
  };

  const exportExcel = async () => {
    setIsExporting(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}${excel_url}`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const currentDate = new Date();
      const timestamp = currentDate.toISOString().replace(/[-T:]/g, '').split('.')[0];
      const filename = `${report_name}-report-${timestamp}.xlsx`;
      link.download = filename;
      link.click();
  
      onCloseExport();
      successToast("Excel exported successfully!");
      setIsExporting(false);
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.message,
          code: error.response.status_code,
        })
      );
      setIsExporting(false);
      onCloseExport();
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={exportCancel}
        closeAfterTransition
      >
        <Fade in={exportCancel}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to export this {report_name} report as{" "}
                  {isExcel ? "Excel" : "pdf"}?
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={isExcel ? exportExcel : exportPDF}
                    disabled={isExporting}
                    endIcon={
                      isExporting && (
                        <CircularProgress size={20} color="secondary" />
                      )
                    }
                  >
                    Export
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={onCloseExport}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ExportToPdf;
