import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  TextareaAutosize,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { successToast } from "../../../response/successToast";
import Select from "react-select";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import {
  editCompanyBranch,
  fetchCompanyBranchById,
} from "../../../store/slices/companyBranchSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter company branch name"),
  description: Yup.string().required("Please enter branch description"),
  branch_company_id: Yup.number().required("Please select a company"),
});

const companyList = [
  { value: 1, label: "Crest Infosystems Pvt. Ltd." },
  { value: 2, label: "Crest Skillserve Pvt. Ltd." },
];

const CompanyBranchEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [companyBranch, setCompanyBranch] = useState({});
  const { id } = useParams();

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchCompanyBranchById(id));
      if (response?.success) {
        setCompanyBranch(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    formik.setValues({
      name: companyBranch?.name,
      description: companyBranch?.description,
      branch_company_id:companyBranch?.branch_company_id
    });
  }, [companyBranch]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      branch_company_id:""
    },
    validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      dispatch(editCompanyBranch(values, id))
        .then((response) => {
          if (response?.success) {
            successToast(
              response?.message || "Company Branch Updated Successfully"
            );
            navigate(-1);
          } else {
            console.error("failed! Something went wrong");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
      setLoader(false);
    },
  });

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
    helperText: formik.touched[field.name] && formik.errors[field.name] && (
      <FormHelperText className="error-message">
        {formik.errors[field.name]}
      </FormHelperText>
    ),
  });

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/branches")}
          ></Button>
          Edit Company Branch
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Company Branch Name
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Company Branch Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={
                    formik.touched.name && (
                      <span className="error-message">
                        {formik.errors.name}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Company Name
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Company Name"
                  name="branch_company_id"
                  options={companyList}
                  className={`muilt-select-field ${
                    formik.touched.branch_company_id &&
                    formik.errors.branch_company_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "branch_company_id",
                      selectedOption?.value
                    );
                    formik.setFieldTouched("branch_company_id", false);
                  }}
                  value={companyList.find(
                    (option) => option.value === formik.values.branch_company_id
                  )}
                  isSearchable // Optional: Enable searching in the dropdown
                />
                {formik.touched.branch_company_id &&
                  formik.errors.branch_company_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.branch_company_id}
                    </FormHelperText>
                  )}
                {/* {formik.touched.description && formik.errors.description && (
                  <FormHelperText className="error-message">
                    {formik.errors.description}
                  </FormHelperText>
                )} */}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Description
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  multiline
                  rows={3}
                  className="input-field"
                  {...getFieldProps({
                    name: "description",
                    required: true,
                    placeholder: "Add Description",
                  })}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                />
                {/* {formik.touched.description && formik.errors.description && (
                  <FormHelperText className="error-message">
                    {formik.errors.description}
                  </FormHelperText>
                )} */}
              </Grid>
            </Grid>
            <Box className="footer-wrapper">
              <Button
                type="submit"
                variant="contained"
                size="large"
                className="primary-btn btn w-120"
                disableRipple
                disabled={loader}
                endIcon={
                  loader && <CircularProgress size={20} color="inherit" />
                }
              >
                {" "}
                Save
              </Button>
              <Button
                variant="contained"
                size="large"
                className="secondary-btn btn w-120"
                disableRipple
                onClick={() => {
                  navigate(-1);
                }}
              >
                {" "}
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default CompanyBranchEdit;
