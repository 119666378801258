import React, { useEffect, useState } from "react";
import { Button, Box, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { fetchCompanyBranchById } from "../../../store/slices/companyBranchSlice";
import Loader from "../../../components/common/Loader";

const CompanyBranchView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [companyBranch, setCompanyBranch] = useState({});
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchCompanyBranchById(id));
      if (response?.success) {
        setCompanyBranch(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {/* START :: ASSET CATEGORY VIEW HEADER */}
      <Box className="content-header view-lead-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/branches")}
          ></Button>
          Details for Company Branch
        </Typography>
      </Box>
      {/* END :: ASSET CATEGORY VIEW HEADER */}

      {/* START :: ASSET CATEGORY VIEW CONTENT */}
      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <Grid container spacing={2} className="view-info">
            {loader ? (
              <Loader />
            ) : !companyBranch ? (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="h4"
                  component="h4"
                  className="card-title"
                  sx={{ paddingBottom: "8px", margin: "16px 0" }}
                >
                  <Box className="app-list text-center">
                    Company Branch Not Found
                  </Box>
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Branch Name
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="card-title"
                    sx={{ paddingBottom: "8px" }}
                  >
                    <Box className="app-list">{companyBranch?.name}</Box>
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="card-title"
                    sx={{ paddingBottom: "8px" }}
                  >
                    <Box className="app-list">{companyBranch?.branch_company?.company_name}</Box>
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="description"
                  >
                    {companyBranch?.description}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
      {/* END :: ASSET CATEGORY VIEW CONTENT */}
    </>
  );
};

export default CompanyBranchView;
