import React from "react";

import { Box, Typography } from "@mui/material";
import AssetReportsList from "./AssetReportsList";

const AssetReports = () => {

  return (
    <>
      {/*  START :: ASSETS LISTING VIEW */}
      <Box className="content-header project-list-header">
        <Typography variant="h2" component="h2">
          Reports
        </Typography>
      </Box>
      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <AssetReportsList />
        </Box>
      </Box>
      {/* END :: ASSETS LISTING VIEW */}
    </>
  );
};

export default AssetReports;
