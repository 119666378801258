import React, { useState } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";
import {
  RiAddCircleLine,
  RiCloseCircleLine,
  RiSearchLine,
} from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";

import AssetTicketsList from "./AssetTicketsList";



const AssetTickets = () => {
  const navigate = useNavigate();
  const location=useLocation;
  const {state}=location
  const [searchAssetTicket, setSearchAssetTicket] = useState();

  const clearSearchHandler = () => {
    setSearchAssetTicket("");
  };

  return (
    <>
      {/*  START :: ASSETS LISTING VIEW */}
      <Box className="content-header asset-ticket-module-wrapper">
        <Typography variant="h2" component="h2">
          Asset Tickets
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic-asset-search"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search"
              onChange={(e) => setSearchAssetTicket(e.target.value)}
              value={searchAssetTicket}
            />
            {searchAssetTicket && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => navigate("/asset-tickets/create",state)}
          >
            Create Asset Ticket
          </Button>
        </Box>
      </Box>
      <Box className="content-layout">
        <AssetTicketsList searchAssetTicket={searchAssetTicket} />
      </Box>
      {/* END :: ASSETS LISTING VIEW */}
    </>
  );
};

export default AssetTickets;
