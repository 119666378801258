import React from "react";
import store from "./store/store";
import { Provider } from "react-redux";

import ReactDOM from "react-dom/client";

import App from "./App";
import FeedbackModal from "./response/feedbackModal";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <FeedbackModal />
    </Provider>

);
