import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";

const initialState = {
  report: [],
  reportFilter: [],
  ticketReportFilter:[],
  isLoading: false,
  success: false,
  error: null,
};

const ReportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    fetchReportDetailsListStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchReportDetailsListSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.report = action.payload;
    },
    fetchReportDetailsListFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchTicketsDetailsListStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchTicketsDetailsListSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.report = action.payload;
    },
    fetchTicketsDetailsListFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    
    fetchReportFilterListStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchReportFilterListSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.reportFilter = action.payload;
    },
    fetchReportFilterListFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    createReportFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createReportFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    createReportFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editReportFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    editReportFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    editReportFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteReportFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteReportFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    deleteReportFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchTicketsReportFilterListStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchTicketsReportFilterListSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.ticketReportFilter =action.payload;
    },
    fetchTicketsReportFilterListFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    createTicketsReportFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createTicketsReportFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    createTicketsReportFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editTicketsReportFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    editTicketsReportFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    editTicketsReportFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteTicketsReportFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteTicketsReportFilterSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    deleteTicketsReportFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchReportDetailsListStart,
  fetchReportDetailsListSuccess,
  fetchReportDetailsListFailure,
  fetchTicketsDetailsListStart,
  fetchTicketsDetailsListSuccess,
  fetchTicketsDetailsListFailure,
  fetchTicketsReportFilterListStart,
  fetchTicketsReportFilterListFailure,
  fetchTicketsReportFilterListSuccess,
  editTicketsReportFilterStart,
  editTicketsReportFilterFailure,
  editTicketsReportFilterSuccess,
  deleteTicketsReportFilterFailure,
  deleteTicketsReportFilterStart,
  deleteTicketsReportFilterSuccess,
  createTicketsReportFilterStart,
  createTicketsReportFilterSuccess,
  createTicketsReportFilterFailure,
  fetchReportFilterListStart,
  fetchReportFilterListSuccess,
  fetchReportFilterListFailure,
  createReportFilterStart,
  createReportFilterSuccess,
  createReportFilterFailure,
  editReportFilterStart,
  editReportFilterSuccess,
  editReportFilterFailure,
  deleteReportFilterStart,
  deleteReportFilterSuccess,
  deleteReportFilterFailure,
} = ReportSlice.actions;

export default ReportSlice.reducer;

export const fetchReportDetailsList =
  (value, page, applyedFilter,rowperPage=10) => async (dispatch) => {
    try {
      dispatch(fetchReportDetailsListStart());
      let url = `/reports/assets?status=${value}&page=${page}&rows=${rowperPage}${
        applyedFilter?.asset_category_id
          ? `&asset_category_id=${applyedFilter?.asset_category_id}`
          : ""
      }${
        applyedFilter?.vendor_id ? `&vendor_id=${applyedFilter?.vendor_id}` : ""
      }${
        applyedFilter?.assign_to ? `&assign_to=${applyedFilter.assign_to}` : ""
      }${
        applyedFilter?.purchase_date?.length
          ? `&purchase_date=[${applyedFilter.purchase_date.map(
              (i) => `"${i}"`
            )}]`
          : ""
      }`;

      const response = await api.get(url);
      const report = response;
      dispatch(fetchReportDetailsListSuccess(report?.data));
      return { success: true, data: report?.data };
    } catch (error) {
      dispatch(fetchReportDetailsListFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error?.response?.data?.message,
          code: error?.response?.status,
        })
      );
    }
  };

  export const fetchTicketsDetailsList =
  (value,page,applyedFilter,rowperPage) => async (dispatch) => {
    try {
      dispatch(fetchTicketsDetailsListStart());
      let url = `/reports/assetstickets?status=${value}&page=${page}&rows=${rowperPage}${
        applyedFilter?.asset_id
          ? `&asset_id=${applyedFilter?.asset_id}`
          : ""
      }${
        applyedFilter?.vendor_id ? `&vendor_id=${applyedFilter?.vendor_id}` : ""
      }${
        applyedFilter?.requested_date?.length
          ? `&request_date=[${applyedFilter.requested_date.map(
              (i) => `"${i}"`
            )}]`
          : ""
      }`;

      const response = await api.get(url);
      const report = response;
      dispatch(fetchTicketsDetailsListSuccess(report?.data));
      return { success: true, data: report?.data };
    } catch (error) {
      dispatch(fetchReportDetailsListFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error?.response?.data?.message,
          code: error?.response?.status,
        })
      );
    }
  };


export const fetchReportFilterList = () => async (dispatch) => {
  try {
    dispatch(fetchReportFilterListStart());

    let url = `/reports/assets/filter`;
    const response = await api.get(url);
    const report = response;
    dispatch(fetchReportFilterListSuccess(report?.data?.assetFilters));
    return { success: true, data: report?.data?.assetFilters };
  } catch (error) {
    dispatch(fetchReportFilterListFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};

export const fetchTicketsReportFilterList = () => async (dispatch) => {
  try {
    dispatch(fetchTicketsReportFilterListStart());

    let url = `/reports/assetstickets/filter`;
    const response = await api.get(url);
    const report = response;
    dispatch(fetchTicketsReportFilterListSuccess(report?.data?.assetticketsFilters));
    return { success: true, data: report?.data?.assetticketsFilters };
  } catch (error) {
    dispatch(fetchTicketsReportFilterListFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};


export const editReportFilter = (id, filterData) => async (dispatch) => {
  try {
    dispatch(editReportFilterStart());

    const response = await api.patch(`reports/assets/filter/${id}`, filterData);

    dispatch(editReportFilterSuccess(response?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(editReportFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const editTicketsReportFilter = (id, filterData) => async (dispatch) => {
  try {
    dispatch(editTicketsReportFilterStart());

    const response = await api.patch(`/reports/assetstickets/filter/${id}`, filterData);

    dispatch(editTicketsReportFilterSuccess(response?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(editTicketsReportFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const createReportFilter = (filterData) => async (dispatch) => {
  try {
    dispatch(createReportFilterStart());

    const response = await api.post(
      `/reports/assets/filter`,
      Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== null)
      )
    );

    dispatch(createReportFilterSuccess(response?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(createReportFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const createTicketsReportFilter = (filterData) => async (dispatch) => {
  try {
    dispatch(createTicketsReportFilterStart());

    const response = await api.post(
      `/reports/assetstickets/filter`,
      Object.fromEntries(
        Object.entries(filterData).filter(([key, value]) => value !== null)
      )
    );

    dispatch(createTicketsReportFilterSuccess(response?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(createTicketsReportFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const deleteReportFilter = (reportFilterId) => async (dispatch) => {
  try {
    dispatch(deleteReportFilterStart());
    const response = await api.delete(
      `/reports/assets/filter/${reportFilterId}`
    );
    dispatch(deleteReportFilterSuccess(reportFilterId));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(deleteReportFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};


export const deleteTicketsReportFilter = (reportFilterId) => async (dispatch) => {
  try {
    dispatch(deleteTicketsReportFilterStart());
    const response = await api.delete(
      `/reports/assetstickets/filter/${reportFilterId}`
    );
    dispatch(deleteTicketsReportFilterSuccess(reportFilterId));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(deleteTicketsReportFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};