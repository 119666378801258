import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  TextareaAutosize,
  CircularProgress,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import {
  RiArrowLeftLine,
  RiAddCircleLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchCompanyBranch } from "../../../store/slices/companyBranchSlice";
import { fetchVendor } from "../../../store/slices/vendorSlice";
import { editAsset, fetchAssetById } from "../../../store/slices/assetSlice";
import { successToast } from "../../../response/successToast";
import { fetchAssetCategory } from "../../../store/slices/assetCategorySlice";
import { fetchUsers } from "../../../store/slices/userSlice";
import moment from "moment";
import { roundToTwoDecimal } from "../../../utils/helper";

// STATUS OPTIONS
const statusList = [
  { value: "in_service", label: "In Service" },
  { value: "under_repair", label: "Under Repair" },
  { value: "out_of_service", label: "Out Of Service" },
  { value: "in_stock_available", label: "In Stock" },
  { value: "scrap", label: "Scrap" },
];

// Month OPTIONS
const monthList = [
  { value: "0", label: "0" },
  { value: "1", label: "1 Month" },
  { value: "2", label: "2 Months" },
  { value: "3", label: "3 Months" },
  { value: "4", label: "4 Months" },
  { value: "5", label: "5 Months" },
  { value: "6", label: "6 Months" },
  { value: "7", label: "7 Months" },
  { value: "8", label: "8 Months" },
  { value: "9", label: "9 Months" },
  { value: "10", label: "10 Months" },
  { value: "11", label: "11 Months" },
];

// Year OPTIONS
const YearList = [
  { value: "0", label: "0" },
  { value: "1", label: "1 Year" },
  { value: "2", label: "2 Years" },
  { value: "3", label: "3 Years" },
  { value: "4", label: "4 Years" },
  { value: "5", label: "5 Years" },
  { value: "6", label: "6 Years" },
  { value: "7", label: "7 Years" },
  { value: "8", label: "8 Years" },
  { value: "9", label: "9 Years" },
  { value: "10", label: "10 Years" },
];

const validationSchema = Yup.object().shape({
  current_status: Yup.string().required("Please select current status"),
  vendor_id: Yup.string().required("Please select asset vendor"),
  asset_name: Yup.string().required("Please enter asset name"),
  purchase_date: Yup.date().required("Please select purchase date"),
  assign_date: Yup.date().required("Please select assign date"),
  asset_category_id: Yup.string().required("Please select asset category"),
  purchase_price: Yup.string().required("Please enter purchase price"),
  asset_branch_id: Yup.string().required("Select asset branch"),
  current_value_per_company_act: Yup.string().required(
    "Please enter Current value(Per Company Act)"
  ),
  asset_description: Yup.string().required("Please enter asset description"),
  Warranty_description: Yup.string().required(
    "Please enter warranty description"
  ),
  current_value_per_it_act: Yup.string().required(
    "Please enter current value(Per Company Act)"
  ),
  warranty_period: Yup.string().required("Please select warranty period"),
  assign_to: Yup.string().required("Please select assignee"),
});

const AssetEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      childvalue: 0,
      currentPage: 1,
      rowperPage: 10,
      searchAsset: "",
      filterApplied: false,
    };
  }
  const [loader, setLoader] = useState(false);
  const [asset, setAsset] = useState([]);
  const [vendorList, setVendorList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const { id } = useParams();
  const [userList, setUserList] = useState([]);
  const purchaseInputRef = useRef(null);
  const CompanyInputRef = useRef(null);
  const ITValueInputRef = useRef(null);
  const companyList = [
    { value: 1, label: "Crest Infosystems Pvt. Ltd." },
    { value: 2, label: "Crest Skillserve Pvt. Ltd." },
  ];
  useEffect(() => {
    fetchData();
    fetchAssetCategoryList();
    fetchVendorList();
    fetchBranchList();
    fetchUsersList();
  }, [id]);

  useEffect(() => {
    if (asset.length !== 0) {
      formik.setValues({
        asset_code: asset?.asset_code,
        asset_category_id: asset?.asset_category_id,
        asset_branch_id: asset?.asset_branch_id,
        asset_company_id: asset?.asset_company_id,
        asset_name: asset?.asset_name,
        asset_description: asset?.asset_description,
        Warranty_description: asset?.Warranty_description,
        asset_modification: asset?.asset_modification.length
          ? asset?.asset_modification.map((item) => ({
              description: item.description,
              date: item.date === null ? null : new Date(item.date),
            }))
          : [{ description: "", date: null }],
        current_status: asset?.current_status,
        assign_to: asset?.assign_to,
        assign_date: new Date(asset?.assign_date),
        vendor_id: asset?.vendor_id,
        purchase_date: new Date(asset?.purchase_date),
        purchase_price: roundToTwoDecimal(asset?.purchase_price),
        current_value_per_company_act: roundToTwoDecimal(
          asset?.current_value_per_company_act
        ),
        current_value_per_it_act: roundToTwoDecimal(
          asset?.current_value_per_it_act
        ),
        warranty_period: asset?.warranty_period,
      });
      setYear(asset?.warranty_period?.split("-")?.[0]);
      setMonth(asset?.warranty_period?.split("-")?.[1]);
    }
  }, [asset]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAssetById(id));
      if (response?.success) {
        setAsset(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      asset_code: "",
      asset_category_id: null,
      asset_branch_id: null,
      asset_company_id: null,
      asset_name: "",
      asset_description: "",
      Warranty_description: "",
      current_status: "",
      assign_to: null,
      assign_date: null,
      vendor_id: null,
      purchase_date: null,
      purchase_price: "",
      current_value_per_company_act: "",
      current_value_per_it_act: "",
      warranty_period: null,
      asset_modification: [
        {
          description: "",
          date: null,
        },
      ],
    },
    validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      values.warranty_period = year + "-" + month;
      values.purchase_price = values.purchase_price.replace(/,/g, "");
      values.current_value_per_company_act =
        values.current_value_per_company_act.replace(/,/g, "");
      values.current_value_per_it_act = values.current_value_per_it_act.replace(
        /,/g,
        ""
      );
      dispatch(editAsset(values, id))
        .then((response) => {
          if (response?.success) {
            successToast(response?.message || "Asset updated Successfully");
            navigate("/assets");
          } else {
            console.error("failed! Something went wrong");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
      setLoader(false);
    },
  });

  const fetchAssetCategoryList = async () => {
    setLoader(true);
    try {
      const listing = true;
      const response = await dispatch(fetchAssetCategory(1, "", listing));
      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((category) => ({
          value: category.id,
          label: category.name,
        }));
        setCategoryList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const fetchVendorList = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchVendor(true));

      if (response?.success) {
        const formattedOptions = response?.data?.result?.map((vendor) => ({
          value: vendor.id,
          label: vendor.company_name,
        }));
        setVendorList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const fetchBranchList = async (companyId) => {
    setLoader(true);
    try {
      const response = await dispatch(fetchCompanyBranch(false,undefined,undefined,undefined,companyId));
      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((branch) => ({
          value: branch.id,
          label: branch.name,
        }));
        setBranchList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchUsersList = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchUsers({ pagination: false, allusers: "yes" })
      );
      if (response?.payload) {
        const formattedOptions = response?.payload?.results?.map((user) => ({
          value: user.id,
          label:
            user?.employee_number +
            " - " +
            user.first_name +
            " " +
            user.last_name,
        }));
        setUserList(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
    helperText: formik.touched[field.name] && formik.errors[field.name] && (
      <FormHelperText className="error-message">
        {formik.errors[field.name]}
      </FormHelperText>
    ),
  });

  //Prevent the default scroll behavior for number inputs
  const handleWheelChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
      "Home",
      "End",
    ];
    const isAllowedKey = allowedKeys.includes(e.key);
    const isNumeric = /^[0-9.]+$/.test(e.key);

    if (!isAllowedKey && !isNumeric) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/assets", {
                state: {
                  childvalue: state.childvalue,
                  currentPage: state.currentPage,
                  searchAsset: state.searchAsset,
                  rowperPage: state.rowperPage,
                  filterApplied: state.filterApplied,
                  applyedFilter: state.applyedFilter,
                },
              })
            }
          ></Button>
          Edit Asset
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Code
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>

                <TextField
                  className="input-field"
                  {...getFieldProps({ name: "asset_code" })}
                  disabled
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Vendor
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Vendor"
                  name="vendor_id"
                  options={vendorList}
                  className={`muilt-select-field ${
                    formik.touched.vendor_id && formik.errors.vendor_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("vendor_id", selectedOption?.value);
                    formik.setFieldTouched("vendor_id", true);
                  }}
                  value={vendorList.find(
                    (option) => option.value === formik.values.vendor_id
                  )}
                  isSearchable // Optional: Enable searching in the dropdown
                />
                {formik.touched.vendor_id && formik.errors.vendor_id && (
                  <FormHelperText className="error-message">
                    {formik.errors.vendor_id}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Name
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  {...getFieldProps({
                    name: "asset_name",
                    required: true,
                    placeholder: "Asset Name",
                  })}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Purchase Date
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        id="outlined-basic-date"
                        label="Purchase Date"
                        variant="outlined"
                        size="small"
                        placeholder="Select Assign Date"
                        name="purchase_date"
                        value={formik.values.purchase_date}
                        onBlur={() =>
                          formik.setFieldTouched("purchase_date", true)
                        }
                      />
                    )}
                    className={`input-field ${
                      formik.touched.purchase_date &&
                      formik.errors.purchase_date
                        ? "error"
                        : ""
                    }`}
                    value={formik.values.purchase_date}
                    onChange={(date) => {
                      formik.setFieldValue("purchase_date", date);
                    }}
                    maxDate={new Date(moment())}
                    format="dd-MM-yyyy"
                  />
                  {formik.touched.purchase_date &&
                    formik.errors.purchase_date && (
                      <FormHelperText className="error-message">
                        {formik.errors.purchase_date}
                      </FormHelperText>
                    )}
                </LocalizationProvider>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Category
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset Category"
                  name="asset_category_id"
                  options={categoryList}
                  className={`muilt-select-field ${
                    formik.touched.asset_category_id &&
                    formik.errors.asset_category_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "asset_category_id",
                      selectedOption?.value
                    );
                    formik.setFieldTouched("asset_category_id", true);
                  }}
                  value={categoryList.find(
                    (option) => option.value === formik.values.asset_category_id
                  )}
                  isSearchable // Optional: Enable searching in the dropdown
                />
                {formik.touched.asset_category_id &&
                  formik.errors.asset_category_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.asset_category_id}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Purchase Price
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Box className="field-icon">
                  <div style={{ position: "relative" }}>
                    <TextField
                      inputRef={purchaseInputRef}
                      onWheel={handleWheelChange}
                      onKeyDown={handleKeyDown}
                      // type="number"
                      className="input-field"
                      {...getFieldProps({
                        name: "purchase_price",
                        required: true,
                        placeholder: "Purchase Price",
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography
                              variant="body1"
                              component="label"
                              className="rupee-symbol"
                            >
                              ₹
                            </Typography>
                          </InputAdornment>
                        ),
                        style: { paddingLeft: "40px" },
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        const { value } = e.target;
                        const roundedValue = roundToTwoDecimal(value);
                        formik.setFieldValue("purchase_price", roundedValue);
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Company
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset Branch"
                  name="asset_company_id"
                  options={companyList}
                  className={`muilt-select-field ${
                    formik.touched.asset_company_id &&
                    formik.errors.asset_company_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "asset_company_id",
                      selectedOption?.value
                    );
                    formik.setFieldTouched("asset_company_id", true);
                    formik.setFieldValue(
                      "asset_branch_id",
                       null
                    );
                    fetchBranchList(selectedOption?.value)
                  }}
                  value={companyList.find(
                    (option) => option.value === formik.values.asset_company_id
                  )}
                  isSearchable // Optional: Enable searching in the dropdown
                />
                {formik.touched.asset_company_id &&
                  formik.errors.asset_company_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.asset_company_id}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Current Value (Per Company Act)
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Box className="field-icon">
                  <div style={{ position: "relative" }}>
                    <TextField
                      inputRef={CompanyInputRef}
                      onWheel={handleWheelChange}
                      onKeyDown={handleKeyDown}
                      // type="number"
                      className="input-field"
                      {...getFieldProps({
                        name: "current_value_per_company_act",
                        required: true,
                        placeholder: "Enter Current Value(Per Company Act)",
                      })}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography
                              variant="body1"
                              component="label"
                              className="rupee-symbol"
                            >
                              ₹
                            </Typography>
                          </InputAdornment>
                        ),
                        style: { paddingLeft: "40px" },
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        const { value } = e.target;
                        const roundedValue = roundToTwoDecimal(value);
                        formik.setFieldValue(
                          "current_value_per_company_act",
                          roundedValue
                        );
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Branch
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset Branch"
                  name="asset_branch_id"
                  options={branchList}
                  className={`muilt-select-field ${
                    formik.touched.asset_branch_id &&
                    formik.errors.asset_branch_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue(
                      "asset_branch_id",
                      selectedOption?.value
                    );
                    formik.setFieldTouched("asset_branch_id", true);
                  }}
                  value={
                    formik.values.asset_branch_id
                      ? branchList.find((option) => option.value === formik.values.asset_branch_id)
                      : null
                  }
                  isSearchable // Optional: Enable searching in the dropdown
                />
                {formik.touched.asset_branch_id &&
                  formik.errors.asset_branch_id && (
                    <FormHelperText className="error-message">
                      {formik.errors.asset_branch_id}
                    </FormHelperText>
                  )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Typography variant="body1" component="label">
                    Current Value (Per IT Act)
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Box className="field-icon">
                    <div style={{ position: "relative" }}>
                      <TextField
                        inputRef={ITValueInputRef}
                        onWheel={handleWheelChange}
                        onKeyDown={handleKeyDown}
                        // type="number"
                        className="input-field"
                        {...getFieldProps({
                          name: "current_value_per_it_act",
                          required: true,
                          placeholder: "Enter Current Value(Per IT Act)",
                        })}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                variant="body1"
                                component="label"
                                className="rupee-symbol"
                              >
                                ₹
                              </Typography>
                            </InputAdornment>
                          ),
                          style: { paddingLeft: "40px" },
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          const { value } = e.target;
                          const roundedValue = roundToTwoDecimal(value);
                          formik.setFieldValue(
                            "current_value_per_it_act",
                            roundedValue
                          );
                        }}
                      />
                    </div>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Typography variant="body1" component="label">
                    Current Status
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="current_status"
                    options={statusList}
                    className={`muilt-select-field ${
                      formik.touched.current_status &&
                      formik.errors.current_status
                        ? "has-error"
                        : ""
                    }`}
                    placeholder="Select Month"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "var(--green)",
                      },
                    })}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) => {
                      formik.setFieldValue(
                        "current_status",
                        selectedOption?.value || ""
                      );
                      formik.setFieldTouched("current_status", true);
                    }}
                    value={statusList.find(
                      (option) => option.value === formik.values.current_status
                    )}
                    isSearchable // Optional: Enable searching in the dropdown
                  />
                  {formik.touched.current_status &&
                    formik.errors.current_status && (
                      <FormHelperText className="error-message">
                        {formik.errors.current_status}
                      </FormHelperText>
                    )}
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Typography variant="body1" component="label">
                    Assign To
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    placeholder="Select Assign To"
                    name="assign_to"
                    options={userList}
                    className={`muilt-select-field ${
                      formik.touched.assign_to && formik.errors.assign_to
                        ? "has-error"
                        : ""
                    }`}
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "var(--green)",
                      },
                    })}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) => {
                      formik.setFieldValue("assign_to", selectedOption?.value);
                      formik.setFieldTouched("assign_to", true);
                    }}
                    value={userList.find(
                      (option) => option.value === formik.values.assign_to
                    )}
                    isSearchable
                  />
                  {formik.touched.assign_to && formik.errors.assign_to && (
                    <FormHelperText className="error-message">
                      {formik.errors.assign_to}
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box>
                  <Typography variant="body1" component="label">
                    Warranty
                    <Typography
                      variant="body1"
                      component="label"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <div className="warranty-wrapper">
                    <Box className="w-full">
                      <Select
                        name="warranty_period"
                        options={YearList}
                        className={`muilt-select-field ${
                          formik.touched.warranty_period &&
                          formik.errors.warranty_period
                            ? "has-error"
                            : ""
                        }`}
                        placeholder="Select Year"
                        classNamePrefix="select"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 10,
                          colors: {
                            ...theme.colors,
                            primary25: "var(--secondary-text-color)",
                            primary: "var(--green)",
                          },
                        })}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          setYear(selectedOption?.value);
                          formik.setFieldValue(
                            "warranty_period",
                            selectedOption?.value || "0" + "-" + month
                              ? month
                              : "0"
                          );
                          formik.setFieldTouched("warranty_period", true);
                        }}
                        value={YearList.find((option) => option.value === year)}
                        isSearchable // Optional: Enable searching in the dropdown
                      />
                    </Box>
                    <Box className="w-full">
                      <Select
                        name="warranty_period"
                        options={monthList}
                        className={`muilt-select-field ${
                          formik.touched.warranty_period &&
                          formik.errors.warranty_period
                            ? "has-error"
                            : ""
                        }`}
                        placeholder="Select Month"
                        classNamePrefix="select"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 10,
                          colors: {
                            ...theme.colors,
                            primary25: "var(--secondary-text-color)",
                            primary: "var(--green)",
                          },
                        })}
                        onBlur={formik.handleBlur}
                        onChange={(selectedOption) => {
                          setMonth(selectedOption?.value);
                          formik.setFieldValue(
                            "warranty_period",
                            year ? year : "0" + "-" + selectedOption?.value
                          );
                          formik.setFieldTouched(
                            "warranty_period",
                            year ? year : "0" - selectedOption?.value
                          );
                        }}
                        value={monthList.find(
                          (option) => option.value === month
                        )}
                        isSearchable // Optional: Enable searching in the dropdown
                      />
                    </Box>
                  </div>
                  {formik?.touched?.warranty_period &&
                    formik?.errors?.warranty_period && (
                      <FormHelperText className="error-message">
                        {formik?.errors?.warranty_period}
                      </FormHelperText>
                    )}
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Assign Date
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        fullWidth
                        id="outlined-basic-date"
                        label="Assign Date"
                        variant="outlined"
                        size="small"
                        placeholder="Select Assign Date"
                        name="assign_date"
                        value={formik.values.assign_date}
                        onBlur={() =>
                          formik.setFieldTouched("assign_date", false)
                        }
                      />
                    )}
                    className={`input-field ${
                      formik.touched.assign_date && formik.errors.assign_date
                        ? "error"
                        : ""
                    }`}
                    value={formik.values.assign_date}
                    onChange={(date) => {
                      formik.setFieldValue("assign_date", date);
                    }}
                    format="dd-MM-yyyy"
                  />
                  {formik.touched.assign_date && formik.errors.assign_date && (
                    <FormHelperText className="error-message">
                      {formik.errors.assign_date}
                    </FormHelperText>
                  )}
                </LocalizationProvider>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Description
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  multiline
                  rows={4.5}
                  id="asset_description"
                  className="input-field"
                  {...getFieldProps({
                    name: "asset_description",
                    required: true,
                    placeholder: "Add Asset Description",
                  })}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Warranty Description
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  multiline
                  rows={4.5}
                  id="Warranty_description"
                  name="Warranty_description"
                  className="input-field"
                  {...getFieldProps({
                    name: "Warranty_description",
                    required: true,
                    placeholder: "Add Warranty Description",
                  })}
                  onBlur={formik.handleBlur}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* <Grid container spacing={2}>
                  <Grid item xs={7}> */}
                <Typography variant="body1" component="label">
                  Asset Modifications
                </Typography>
                {/* </Grid>
                </Grid> */}
                {formik.values.asset_modification.map((field, index) => {
                  return (
                    // <Grid container spacing={2} mb={4} key={index}>
                    // <Grid item lg={12} md={6} sm={12} xs={12}>
                    <TextField
                      multiline
                      rows={2}
                      id={`asset_modification.${index}.description`}
                      name={`asset_modification.${index}.description`}
                      className="input-field"
                      placeholder="Add Asset Modification"
                      value={
                        formik.values.asset_modification[index].description
                      }
                      onChange={(e) => {
                        const newValues = [...formik.values.asset_modification];
                        newValues[index].description = e.target.value;
                        formik.setFieldValue("asset_modification", newValues);
                      }}
                      onBlur={() => {
                        formik.setFieldTouched(
                          `asset_modification.${index}.description`,
                          true
                        );
                      }}
                      error={
                        formik.touched[
                          `asset_modification.${index}.description`
                        ] &&
                        Boolean(
                          formik.errors[
                            `asset_modification.${index}.description`
                          ]
                        )
                      }
                      helperText={
                        formik.touched[
                          `asset_modification.${index}.description`
                        ] &&
                        formik.errors[
                          `asset_modification.${index}.description`
                        ] && (
                          <FormHelperText className="error-message">
                            {
                              formik.errors[
                                `asset_modification.${index}.description`
                              ]
                            }
                          </FormHelperText>
                        )
                      }
                    />
                    // </Grid>
                    // </Grid>
                  );
                })}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* <Grid container spacing={2}>
                  <Grid item xs={4}> */}
                <Typography variant="body1" component="label">
                  Date
                </Typography>
                {/* </Grid>
                </Grid> */}
                {formik.values.asset_modification.map((field, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                    >
                      {/* <Grid item xs={4}> */}
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              fullWidth
                              id={`asset_modification.${index}.date`}
                              label="modification_date"
                              variant="outlined"
                              size="small"
                              placeholder="Select Asset Modification Date"
                              name={`asset_modification.${index}.date`}
                              value={
                                new Date(
                                  formik.values.asset_modification[index].date
                                )
                              }
                              onBlur={() =>
                                formik.setFieldTouched(
                                  `asset_modification.${index}.date`,
                                  true
                                )
                              }
                            />
                          )}
                          className={`input-field ${
                            formik.touched[
                              `asset_modification.${index}.date`
                            ] &&
                            formik.errors[`asset_modification.${index}.date`]
                              ? "error"
                              : ""
                          }`}
                          value={formik.values.asset_modification[index].date}
                          onChange={(date) => {
                            const newValues = [
                              ...formik.values.asset_modification,
                            ];
                            newValues[index].date = date;
                            formik.setFieldValue(
                              "asset_modification",
                              newValues
                            );
                          }}
                          maxDate={new Date(moment())}
                          format="dd-MM-yyyy"
                        />
                        {formik.touched[`asset_modification.${index}.date`] &&
                          formik.errors[`asset_modification.${index}.date`] && (
                            <FormHelperText className="error-message">
                              {
                                formik.errors[
                                  `asset_modification.${index}.date`
                                ]
                              }
                            </FormHelperText>
                          )}
                      </LocalizationProvider>
                      {/* </Grid> */}
                      <Grid item xs={1}>
                        <Box className="action-btn-wrap">
                          {index ===
                            formik.values.asset_modification.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn edit-btn"
                              startIcon={<RiAddCircleLine />}
                              disableRipple
                              onClick={() => {
                                const array = [
                                  ...formik.values.asset_modification,
                                ];
                                array.push({ description: "", date: null });
                                formik.setFieldValue(
                                  `asset_modification`,
                                  array
                                );
                              }}
                            ></Button>
                          )}
                          {index !==
                            formik.values.asset_modification.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => {
                                const newModifications = [
                                  ...formik.values.asset_modification,
                                ];
                                newModifications.splice(index, 1);
                                formik.setFieldValue(
                                  "asset_modification",
                                  newModifications
                                );
                              }}
                            ></Button>
                          )}
                        </Box>
                      </Grid>
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="footer-wrapper">
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  className="primary-btn btn w-120"
                  disableRipple
                  disabled={loader}
                  endIcon={
                    loader && <CircularProgress size={20} color="inherit" />
                  }
                >
                  {" "}
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn w-120"
                  disableRipple
                  onClick={() => {
                    navigate("/assets", {
                      state: {
                        childvalue: state.childvalue,
                        currentPage: state.currentPage,
                        searchAsset: state.searchAsset,
                        rowperPage: state.rowperPage,
                        filterApplied: state.filterApplied,
                        applyedFilter: state.applyedFilter,
                      },
                    });
                  }}
                >
                  {" "}
                  Cancel
                </Button>
              </Box>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AssetEdit;
