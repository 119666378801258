import Cookies from "js-cookie";

const getTokenFromCookies = () => {
  // const userData = JSON.parse(localStorage.getItem("token"));
  const UserToken = Cookies.get(process.env.REACT_APP_COOKIE_NAME);
  //const token = userData?.token;
  return UserToken;
};

export default getTokenFromCookies;
