import { score } from "../constants/constant";

const getHasPermission = (requiredPermission, userPermissions) => {
  const requiredPermissionKeys = requiredPermission
    ? Object.keys(requiredPermission)
    : [];
  if (!requiredPermissionKeys.length) return true;

  const hasAccess = requiredPermissionKeys.every((module) => {
    const userPermission = userPermissions[module];
    if (!userPermission) return false;

    const userPermissionScore = score[userPermission];
    const requiredPermissionScore = score[requiredPermission[module]];

    return userPermissionScore >= requiredPermissionScore;
  });

  return hasAccess;
};

const getUserPermissions = (rolePermissions) => {
  const requiredPermissions = {};
  if (rolePermissions.length) {
    for (const { module_name, permissions } of rolePermissions) {
      requiredPermissions[module_name] = permissions;
    }
  }

  return requiredPermissions;
};

export { getHasPermission, getUserPermissions };
