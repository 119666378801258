import React from "react";

import { Box,Button,Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";
import pagenotfound from "../../assets/images/404.png";
import PatternBig from "../../assets/images/Big-Ellipse.png";
import PatternSmall from "../../assets/images/Small-Ellipse.png";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box className="page-not-found">
        
        <Box className="page-inner">
          <img src={pagenotfound} alt="logo-main" loading="lazy" className="page-404" />
          <Typography variant="h3" component="h3">
            Page not found
          </Typography>
          <Typography variant="body1" component="p">
            Oops! The page you are looking for does not exist. It might have been moved or delete.
          </Typography>

          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            disableRipple
            onClick={() => navigate(`/`)}
          >
            Back to Home
          </Button>

          <Box variant="div" component="div" className="pattern-big">
            <img src={PatternBig} alt="pattern" loading="lazy" />
          </Box>
          <Box variant="div" component="div" className="pattern-small">
            <img src={PatternSmall} alt="pattern" loading="lazy" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageNotFound;
