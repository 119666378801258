import {createSlice} from "@reduxjs/toolkit"
import { setFeedback } from "./feedbackSlice"
import api from "../../components/common/commonfunctionforapi"

const initialState ={
    assetTicket:[],
    isLoading:false,
    success:false,
    error:null,
}

const assetTicketSlice = createSlice({
    name:'assetTicket',
    initialState,
    reducers:{
        createAssetTicketStart:(state)=>{
            state.isLoading=true;
            state.success=false;
            state.error=null;
        },
        createAssetTicketSuccess:(state,action)=>{
            state.isLoading=false;
            state.success=true;
        },
        createAssetTicketFailure:(state,action)=>{
            state.isLoading=false;
            state.success=false;
            state.error=action.payload;
        },
        editAssetTicketStart:(state)=>{
            state.isLoading=true;
            state.success=false;
            state.error=null;
        },
        editAssetTicketSuccess:(state,action)=>{
            state.isLoading=false;
            state.success=true;
            const assetTicketIndex = state.assetTicket.findIndex((item)=>item.id === action.payload.id
            );
            if(assetTicketIndex !==-1){
                state.assetTicket[assetTicketIndex]=action.payload;
            }
        },
        editAssetTicketFailure:(state,action)=>{
            state.isLoading=false;
            state.success=false;
            state.error=action.payload;

        },
        deleteAssetTicketStart:(state)=>{
            state.isLoading=true;
            state.success=false;
            state.error=null;

        },
        deleteAssetTicketSuccess:(state,action)=>{
            state.isLoading=false;
            state.success=true;

        },
        deleteAssetTicketFailure:(state,action)=>{
            state.isLoading=false;
            state.success=false;
            state.error=action.payload;
        },
        fetchAssetTicketStart:(state)=>{
            state.isLoading=true;
            state.success=false;
            state.error=null;
        },
        fetchAssetTicketSuccess:(state,action)=>{
            state.isLoading=false;
            state.success=true;
            state.assetTicket=action.payload;
        },
        fetchAssetTicketFailure:(state,action)=>{
            state.isLoading=false;
            state.success=false;
            state.error=action.payload;
        },
        fetchAssetTicketByIdStart:(state)=>{
            state.isLoading=true;
            state.success=false;
            state.error=null;
        },
        fetchAssetTicketByIdSuccess:(state,action)=>{
            state.isLoading=false;
            state.success=true;
        },
        fetchAssetTicketByIdFailure:(state,action)=>{
            state.isLoading=false;
            state.success=false;
            state.error=action.payload;
        },
        fileUploadStart:(state)=>{
            state.isLoading=true;
            state.success=false;
            state.error=null;
        },
        fileUploadSuccess:(state,action)=>{
            state.isLoading=false;
            state.success=true;
        },
        fileUploadFailure:(state,action)=>{
            state.isLoading=false;
            state.success=false;
            state.error=action.payload;
        }, 
        fetchAssetTicketActivityStart: (state) => {
            state.isLoading = true;
            state.success = false;
            state.error = null;
          },
          fetchAssetTicketActivitySuccess: (state, action) => {
            state.isLoading = false;
            state.success = true;
            // state.Asset = action.payload;
          },
          fetchAssetTicketActivityFailure: (state, action) => {
            state.isLoading = false;
            state.success = false;
            state.error = action.payload;
          },
    }
})

export const {
    createAssetTicketStart,
    createAssetTicketSuccess,
    createAssetTicketFailure,
    editAssetTicketStart,
    editAssetTicketSuccess,
    editAssetTicketFailure,
    deleteAssetTicketStart,
    deleteAssetTicketSuccess,
    deleteAssetTicketFailure,
    fetchAssetTicketStart,
    fetchAssetTicketSuccess,
    fetchAssetTicketFailure,
    fetchAssetTicketByIdStart,
    fetchAssetTicketByIdSuccess,
    fetchAssetTicketByIdFailure,
    fileUploadStart,
    fileUploadSuccess,
    fileUploadFailure,
    fetchAssetTicketActivityStart,
    fetchAssetTicketActivitySuccess,
    fetchAssetTicketActivityFailure,
}= assetTicketSlice.actions;
export default assetTicketSlice.reducer;


export const createAssetTicket = (assetTicketData)=>async (dispatch)=>{
    try {
       dispatch( createAssetTicketStart());
       const response = await api.post(`/asset-ticket`,assetTicketData)
       dispatch(createAssetTicketSuccess(response?.data));
       return {success:true,message:response?.message}
    } catch (error) {
        dispatch(createAssetTicketFailure(error.message))
        dispatch(setFeedback({
            status:"fail",
            message:error?.response?.data?.message,
            code:error?.response?.status,
        }));
        return {success:false, error:error?.response?.data?.message}
    }
}

export const editAssetTicket = (assetTicketData,id)=>async(dispatch)=>{
    try {
        dispatch(editAssetTicketStart());
        const response = await api.patch(`/asset-ticket/${id}`,assetTicketData,{headers: {
            'Content-Type': 'multipart/form-data',
        }},)
        return {success:true, message:response?.data?.message};
    } catch (error) {
        dispatch(editAssetTicketFailure(error.message));
        dispatch(setFeedback({
            status:'fail',
            message:error?.response?.data?.message,
            code:error?.response?.status,
        }));
        return {success:false, error:error?.response?.data?.message};
    }
};

export const uploadFile = (selectedFile) => async(dispatch) => {
    try {
        const formData = new FormData();
        formData.append('repairing_bill', selectedFile);
        dispatch(fileUploadStart());
        const response = await api.post(`/asset-ticket/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });       
        return {success:true, message:response?.data?.message};
    } catch (error) {
        dispatch(fileUploadFailure(error.message));
        dispatch(setFeedback({
            status:'fail',
            message:error?.response?.data?.message,
            code:error?.response?.status,
        }));
        return {success:false, error:error?.response?.data?.message};
    }
};



export const deleteAssetTicket = (assetTicketId)=>async(dispatch)=>{
    try {
        dispatch(deleteAssetTicketStart());
        const response = await api.delete(`/asset-ticket/${assetTicketId}`);
        dispatch(deleteAssetTicketSuccess(assetTicketId));
        return {success:true, message:response?.message}
    } catch (error) {
        dispatch(deleteAssetTicketFailure(error.message))
        dispatch(setFeedback({
            status:'fail',
            message:error?.response?.data?.message,
            code:error?.response?.status,
        }));
        return {success:false, error:error?.response?.data?.message}
    }
};

export const fetchAssetTicket=(page=1, search, status,order,rowperPage=10)=>async(dispatch)=>{
    try {
        dispatch(fetchAssetTicketStart());
        let url = `/asset-ticket?status=${status}&page=${page}&rows=${rowperPage}&order=${order}`;
        if(search){
            url+=`&search=${search}`
        }

        const response = await api.get(url);
        const assetTicket = response;
        dispatch(fetchAssetTicketSuccess(assetTicket?.data));
        return { success: true, data: assetTicket?.data };
    } catch (error) {
        dispatch(fetchAssetTicketFailure(error.message));
        dispatch(setFeedback({
            status:'fail',
            message:error?.response?.data?.message,
            code:error?.response?.status,
        }));
    }
}

export const fectchAssetTicketById=(assetTicketId)=>async(dispatch)=>{
    try {
        dispatch(fetchAssetTicketByIdStart());
        const response = await api.get(`/asset-ticket/${assetTicketId}`)
        dispatch(fetchAssetTicketByIdSuccess(response?.data))
        return {success:true,
        message:response?.message, data:response?.data
        }
    } catch (error) {
        dispatch(fetchAssetTicketByIdFailure(error.message));
        dispatch(setFeedback({
            status:'fail',
            message:error?.response?.data?.message,
            code:error?.response?.status,
        }))
    }
}

export const fetchAssetTicketActivity =(ticketId,para) => async(dispatch) =>{
    try{

        dispatch(fetchAssetTicketStart());
        const response =await api.get(`/activity-log/asset-ticket/${ticketId}?action=${para}`);
        const assetTicket = response?.data;
        dispatch(fetchAssetTicketActivitySuccess(assetTicket))
        return { success: true, message: response?.message, data: assetTicket };

    }catch(error){

    dispatch(fetchAssetTicketActivityFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    
    }
}