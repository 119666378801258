import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  Drawer,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  RiAlarmLine,
  RiArrowLeftLine,
  RiCloseLine,
  RiCoupon3Line,
} from "react-icons/ri";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  fetchPurchaseRequestById,
  fetchPurchaseRequestActivity,
} from "../../../store/slices/purchaseRequestSlice";
import Loader from "../../../components/common/Loader";
import moment from "moment";

// START :: SIDEBAR DRAWER TABBING PANEL
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// END :: SIDEBAR DRAWER TABBING PANEL

const PurchaseRequestView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      value: 0,
      currentPage: 1,
      rowperPage: 10,
      searchRequest: "",
      asset_company: "0",
    };
  }
  const [loader, setLoader] = useState(false);
  const [activity, setActivity] = useState([]);
  const [updates, setUpdates] = useState([]);
  const [purchaseRequest, setPurchaseRequest] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    fetchData();
    fetchPurchaseActivity();
    fetchPurchaseUpdates();
  }, [id]);

  const getStatusValueString = (value) => {
    switch (value) {
      case "requested":
        return "Requested";
      case "under_process":
        return "Under Process";
      case "procured":
        return "Procured";
      case "rejected":
        return "Rejected";
      default:
        return "Requested";
    }
  };

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchPurchaseRequestById(id));
      if (response?.success) {
        setPurchaseRequest(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchPurchaseActivity = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchPurchaseRequestActivity(id, "status")
      );
      if (response?.success) {
        setUpdates(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchPurchaseUpdates = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchPurchaseRequestActivity(id, "update")
      );
      if (response?.success) {
        setActivity(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const [open, setOpen] = useState(false);
  // SIDEBAR DRAWER TABBING PANEL
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* START :: PURCHASE REQUEST VIEW HEADER */}
      <Box className="content-header view-lead-header purchase-request-view-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/purchase-request", {
                state: {
                  value: state.value,
                  currentPage: state.currentPage,
                  searchRequest: state.searchRequest,
                  rowperPage: state.rowperPage,
                  asset_company: state.asset_company,
                },
              })
            }
          ></Button>
          Details for Asset Purchase Request Code #{purchaseRequest?.id}
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAlarmLine />}
            disableRipple
            onClick={() => setOpen(!open)}
          >
            Activity Log
          </Button>
        </Box>
      </Box>
      {/* END :: PURCHASE REQUEST VIEW HEADER */}

      {/* START :: PURCHASE REQUEST VIEW CONTENT */}
      <Box className="content-wrapper">
        <Box className="card-wrapper">
          {loader ? (
            <Loader />
          ) : purchaseRequest?.length === 0 ? (
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h4"
                className="card-title"
                sx={{ paddingBottom: "8px", margin: "16px 0" }}
              >
                <Box className="app-list text-center">
                  Purchase Request Not Found
                </Box>
              </Typography>
            </Grid>
          ) : (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={3}
              className="view-info"
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  className="card-title"
                  sx={{ paddingBottom: "8px" }}
                >
                  {purchaseRequest?.title}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Description
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                  sx={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                  }}
                >
                  {purchaseRequest?.description}
                </Typography>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Company
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {purchaseRequest?.asset_company?.company_name}
                </Typography>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: { xs: "block", sm: "Block", md: "none", lg: "none" },
                }}
              >
                <Divider className="divider" />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset Category
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {purchaseRequest?.asset_category?.name}
                </Typography>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider className="divider" />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Status
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {purchaseRequest?.status
                    ? getStatusValueString(purchaseRequest?.status)
                    : "-"}
                </Typography>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: { xs: "block", sm: "Block", md: "none", lg: "none" },
                }}
              >
                <Divider className="divider" />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Requested Date
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {purchaseRequest?.request_date
                    ? moment(purchaseRequest?.request_date).format("DD-MM-YYYY")
                    : "-"}
                </Typography>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider className="divider" />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Comment
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {purchaseRequest?.comments || "-"}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </Box>
      {/* END :: PURCHASE REQUEST VIEW CONTENT */}

      {/*  START :: ASSET TICKET  ACTIVITY PANEL*/}
      <Drawer
        className="side-drawer view-activity-log"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box className="sidebar-tab-wrapper activity-log-wrapper">
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={() => setOpen(false)}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs aria-label="basic tabs example">
                <Tab
                  className="tab-label-btn"
                  label="Activity Log"
                  style={{ color: "var(--primary-text-color)" }}
                />
              </Tabs>
            </Box>
          </Box>
          <Box className="sidebar-tab-wrapper inner-tab-wrapper">
            <Box className="sidebar-tab" style={{ paddingLeft: "12px" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                label="Activity Log"
              >
                <Tab
                  className="tab-label-btn"
                  label="Updates"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Status"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              {loader ? (
                <Box className="activity-log-list-wrapper">
                  <Loader />
                </Box>
              ) : (
                <Box className="activity-log-list-wrapper">
                  {activity?.length === 0 ? (
                    <Box className="activity-card-wrapper">
                      <Box className="activity-log-icon activity-call">
                        <RiCoupon3Line />
                      </Box>
                      <Box className="activity-content-wrapper">
                        <Typography
                          variant="body1"
                          component="span"
                          className="date-time-drawer"
                          style={{
                            lineHeight: "19px !important",
                          }}
                        >
                          {purchaseRequest?.createdAt
                            ? moment(purchaseRequest?.createdAt).format("HH:mm")
                            : "-"}
                          <Typography
                            variant="body1"
                            component="span"
                            className="mobile-time-drawer"
                          >
                            |{" "}
                            {purchaseRequest?.createdAt
                              ? moment(purchaseRequest?.createdAt).format(
                                  "MMM DD, YYYY"
                                )
                              : "-"}
                          </Typography>
                        </Typography>
                        <Box className="activity-content">
                          <Box className="activity-content-header">
                            <Typography variant="h6" component="h6">
                              {purchaseRequest?.title} Purchase request created.
                            </Typography>
                            <Typography variant="body1" component="span">
                              {purchaseRequest?.createdAt
                                ? moment(purchaseRequest?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <>
                        {activity?.map((activities) => (
                          <Box
                            className="activity-card-wrapper"
                            key={activities.id}
                          >
                            <Box className="activity-log-icon activity-call">
                              <RiCoupon3Line />
                            </Box>
                            <Box className="activity-content-wrapper">
                              <Typography
                                variant="body1"
                                component="span"
                                className="date-time-drawer"
                                style={{
                                  lineHeight: "19px !important",
                                }}
                              >
                                {activities?.updatedAt
                                  ? moment(activities?.updatedAt).format(
                                      "HH:mm"
                                    )
                                  : "-"}
                                <Typography
                                  variant="body1"
                                  component="span"
                                  className="mobile-time-drawer"
                                >
                                  |{" "}
                                  {activities?.updatedAt
                                    ? moment(activities?.updatedAt).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "-"}
                                </Typography>
                              </Typography>
                              <Box className="activity-content">
                                <Box className="activity-content-header">
                                  <Typography variant="h6" component="h6">
                                    {activities.details}
                                  </Typography>
                                  <Typography variant="body1" component="span">
                                    {activities?.updatedAt
                                      ? moment(activities?.updatedAt).format(
                                          "MMM DD, YYYY"
                                        )
                                      : "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </>
                      <>
                        <Box className="activity-card-wrapper">
                          <Box className="activity-log-icon activity-call">
                            <RiCoupon3Line />
                          </Box>
                          <Box className="activity-content-wrapper">
                            <Typography
                              variant="body1"
                              component="span"
                              className="date-time-drawer"
                              style={{
                                lineHeight: "19px !important",
                              }}
                            >
                              {purchaseRequest?.createdAt
                                ? moment(purchaseRequest?.createdAt).format(
                                    "HH:mm"
                                  )
                                : "-"}
                              <Typography
                                variant="body1"
                                component="span"
                                className="mobile-time-drawer"
                              >
                                |{" "}
                                {purchaseRequest?.createdAt
                                  ? moment(purchaseRequest?.createdAt).format(
                                      "MMM DD, YYYY"
                                    )
                                  : "-"}
                              </Typography>
                            </Typography>
                            <Box className="activity-content">
                              <Box className="activity-content-header">
                                <Typography variant="h6" component="h6">
                                  {purchaseRequest?.title} Purchase request
                                  created.
                                </Typography>
                                <Typography variant="body1" component="span">
                                  {purchaseRequest?.createdAt
                                    ? moment(purchaseRequest?.createdAt).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "-"}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    </>
                  )}
                </Box>
              )}
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              {loader ? (
                <Box className="activity-log-list-wrapper">
                  <Loader />
                </Box>
              ) : (
                <Box className="activity-log-list-wrapper">
                  {updates?.length === 0 ? (
                    <Box className="activity-card-wrapper">
                      <Box className="activity-log-icon activity-call">
                        <RiCoupon3Line />
                      </Box>
                      <Box className="activity-content-wrapper">
                        <Typography
                          variant="body1"
                          component="span"
                          className="date-time-drawer"
                          style={{
                            lineHeight: "19px !important",
                          }}
                        >
                          {purchaseRequest?.createdAt
                            ? moment(purchaseRequest?.createdAt).format("HH:mm")
                            : "-"}
                          <Typography
                            variant="body1"
                            component="span"
                            className="mobile-time-drawer"
                          >
                            |{" "}
                            {purchaseRequest?.createdAt
                              ? moment(purchaseRequest?.createdAt).format(
                                  "MMM DD, YYYY"
                                )
                              : "-"}
                          </Typography>
                        </Typography>
                        <Box className="activity-content">
                          <Box className="activity-content-header">
                            <Typography variant="h6" component="h6">
                              {purchaseRequest?.title} Purchase request created
                              and status set to {purchaseRequest.status}
                            </Typography>
                            <Typography variant="body1" component="span">
                              {purchaseRequest?.createdAt
                                ? moment(purchaseRequest?.createdAt).format(
                                    "MMM DD, YYYY"
                                  )
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <>
                        {updates?.map((activities) => (
                          <Box
                            className="activity-card-wrapper"
                            key={activities.id}
                          >
                            <Box className="activity-log-icon activity-call">
                              <RiCoupon3Line />
                            </Box>
                            <Box className="activity-content-wrapper">
                              <Typography
                                variant="body1"
                                component="span"
                                className="date-time-drawer"
                                style={{
                                  lineHeight: "19px !important",
                                }}
                              >
                                {activities?.updatedAt
                                  ? moment(activities?.updatedAt).format(
                                      "HH:mm"
                                    )
                                  : "-"}
                                <Typography
                                  variant="body1"
                                  component="span"
                                  className="mobile-time-drawer"
                                >
                                  |{" "}
                                  {activities?.updatedAt
                                    ? moment(activities?.updatedAt).format(
                                        "MMM DD, YYYY"
                                      )
                                    : "-"}
                                </Typography>
                              </Typography>
                              <Box className="activity-content">
                                <Box className="activity-content-header">
                                  <Typography variant="h6" component="h6">
                                    {activities.details}
                                  </Typography>
                                  <Typography variant="body1" component="span">
                                    {activities?.updatedAt
                                      ? moment(activities?.updatedAt).format(
                                          "MMM DD, YYYY"
                                        )
                                      : "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </>
                    </>
                  )}
                </Box>
              )}
            </Stack>
          </CustomTabPanel>
        </Box>
      </Drawer>
      {/* END :: ASSET TICKET  ACTIVITY PANEL*/}
    </>
  );
};

export default PurchaseRequestView;
