import { createSlice } from "@reduxjs/toolkit";
import { getUserPermissions } from "../../utils/userPermissions";
import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";

// export const fetchPermissions = createAsyncThunk(

const initialState = {
  status: "idle",
  error: null,
  rolePermissions: {},
};
const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    fetchPermissionsStart: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    fetchPermissionSuccess: (state, action) => {
      const rolePermissions =
        action.payload?.role_permissions?.permissions || [];

      state.status = "succeeded";
      state.rolePermissions = getUserPermissions(rolePermissions);
    },
    fetchPermissionFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
    setUserPermissions: (state, action) => {
      const rolePermissions = action.payload;
      state.rolePermissions = getUserPermissions(rolePermissions);
    },
  },
});

export const {
  setUserPermissions,
  fetchPermissionsStart,
  fetchPermissionSuccess,
  fetchPermissionFailure,
} = permissionSlice.actions;
export default permissionSlice.reducer;

export const fetchPermissions = () => async (dispatch) => {
  try {
    dispatch(fetchPermissionsStart());

    const response = await api.get(`/auth/permission`);

    const data = response.data;
    dispatch(fetchPermissionSuccess(data));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(fetchPermissionFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};
