import React, { useEffect, useState } from "react";
import { RiDeleteBinLine, RiEyeLine, RiPencilLine } from "react-icons/ri";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AssetCategoryDeleteModal from "../../../components/common/AssetCategoryDeleteModal";
import { fetchAssetCategory } from "../../../store/slices/assetCategorySlice";
import { useDispatch } from "react-redux";
import Loader from "../../../components/common/Loader";
import {
  fullSettings,
  updateSettings,
  viewAssetCategory,
  viewSettings,
} from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

const AssetCategoryList = ({ searchCategory }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const [loader, setLoader] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const [rowperPage,setRowsPerPage]=useState(10);
  const [count, setCount] = useState(1);
  const [assetCategory, setAssetCategory] = useState([]);
  const [assetCategoryId, setAssetCategoryId] = useState("");

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setcurrentPage(1);
};

  // START :: DELETE MODAL
  const [assetCategoryDelete, setAssetCategoryDelete] = useState(false);
  // END :: DELETE MODAL

  const handlePageChange = (event, newPage) => {
    setcurrentPage(newPage);
  };

  useEffect(() => {
    fetchData();
  }, [searchCategory, currentPage,rowperPage]);

  useEffect(() => {
    if (currentPage > 1) {
      setcurrentPage(1);
    }
  }, [searchCategory]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchAssetCategory(currentPage,searchCategory,"false",rowperPage)
      );
      if (response?.success) {
        setAssetCategory(response?.data?.results);
        setCount(response?.data?.totalCount);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Asset Category</TableCell>
                <TableCell
                  align="left"
                  sx={{ whiteSpace: "break-spaces !important" }}
                >
                  Category Description
                </TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            {loader ? (
              <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={3}>
                  {" "}
                  <Loader />
                </TableCell>
              </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {assetCategory?.length === 0 ? (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" colSpan={3}>
                      Asset Category Not Found..
                    </TableCell>
                  </TableRow>
                ) : (
                  assetCategory?.map((item) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">
                        <Box className="app-list">
                          {item?.name}
                        </Box>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ whiteSpace: "break-spaces !important" }}
                      >
                        <div className="more-desc">
                          {item?.description ? item?.description : "-"}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <Box className="action-btn-wrap">
                          <WithPermission
                            permission={viewSettings}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn view-btn"
                                startIcon={<RiEyeLine />}
                                disableRipple
                                onClick={() =>
                                  navigate(`/asset-category/view/${item?.id}`)
                                }
                              ></Button>
                            }
                          />
                          <WithPermission
                            permission={updateSettings}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn edit-btn"
                                startIcon={<RiPencilLine />}
                                disableRipple
                                onClick={() =>
                                  navigate(`/asset-category/edit/${item?.id}`)
                                }
                              ></Button>
                            }
                          />
                          <WithPermission
                            permission={fullSettings}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() => {
                                  setAssetCategoryDelete(true);
                                  setAssetCategoryId(item?.id);
                                }}
                              ></Button>
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            )}
          </Table>
          </TableContainer>
          <CustomTablePaginationToolbar
              count={count}
              page={currentPage}
              rowsPerPage={rowperPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </Box>

      <AssetCategoryDeleteModal
        assetCategoryDelete={assetCategoryDelete}
        setAssetCategoryDelete={setAssetCategoryDelete}
        assetCategoryId={assetCategoryId}
        setAssetCategoryId={setAssetCategoryId}
        fetchData={fetchData}
      />
    </>
  );
};

export default AssetCategoryList;
