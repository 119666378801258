import React from "react";

import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import noaccess from "../../assets/images/noAccess.png";
import PatternBig from "../../assets/images/Big-Ellipse.png";
import PatternSmall from "../../assets/images/Small-Ellipse.png";

const NoAccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box className="page-not-found">
        <Box className="page-inner">
          <img
            src={noaccess}
            alt="logo-main"
            loading="lazy"
            className="page-404 mar-bot"
          />
          <Typography variant="h3" component="h3">
            Access Denied
          </Typography>
          <Typography variant="body1" component="p">
            You aren't authorized to view this page
          </Typography>

          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            disableRipple
            onClick={() => navigate(`/`)}
          >
            Back to Home
          </Button>

          <Box variant="div" component="div" className="pattern-big">
            <img src={PatternBig} alt="pattern" loading="lazy" />
          </Box>
          <Box variant="div" component="div" className="pattern-small">
            <img src={PatternSmall} alt="pattern" loading="lazy" />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NoAccess;
