import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  TextareaAutosize,
  CircularProgress,
  FormHelperText,
  Paper,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import { RiArrowLeftLine } from "react-icons/ri";
import { FiUploadCloud } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import { successToast } from "../../../response/successToast";
import { fetchAsset } from "../../../store/slices/assetSlice";
import { createAssetTicket } from "../../../store/slices/assetTicketSlice";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fetchUsers } from "../../../store/slices/userSlice";
import { getUserRole } from "../../../utils/helper";
import getuserDataFromCookies from "../../../utils/userData";
import { roundToTwoDecimal } from "../../../utils/helper";
import moment from "moment";

const AssetTicketsCreate = () => {
  const navigate = useNavigate();
  const location=useLocation();
  const {state}=location
  console.log("STATE: ",state)
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [assetsOptions, setAssetsOptions] = useState([]);
  const [userList, setUserList] = useState([]);
  const role = getUserRole();
  const [selectedFile, setSelectedFile] = useState(null);
  const [errormessage, setErrorMessage] = useState("");
  const purchaseInputRef = useRef(null);
  const userData = getuserDataFromCookies();

  const handleWheelChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };

  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
      "Home",
      "End",
    ];
    const isAllowedKey = allowedKeys.includes(e.key);
    const isNumeric = /^[0-9.]+$/.test(e.key);

    if (!isAllowedKey && !isNumeric) {
      e.preventDefault();
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/svg": [".svg"],
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    maxSize: 2 * 1024 * 1024,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        const rejectedFile = fileRejections[0];

        if (rejectedFile.errors.some((err) => err.code === "file-too-large")) {
          setErrorMessage(
            "File size greater than 2MB, Could not upload, please try again"
          );
        } else if (rejectedFile.file) {
          const acceptedFormats = [
            "image/png",
            "image/jpeg",
            "image/svg+xml",
            "application/pdf",
          ];
          const fileFormat = rejectedFile.file.type;

          if (!acceptedFormats.includes(fileFormat)) {
            setErrorMessage(
              "Unsupported file format, Could not upload, please try again"
            );
          }
        }
      } else if (acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0]);
        setErrorMessage("");
      }
    },
  });
  const { ref, ...rootProps } = getRootProps();
  const fetchAssets = async () => {
    setLoader(true);
    try {
      const response = await dispatch(
        fetchAsset("in_service", 1, "", "", 10000)
      );
      if (response?.success) {
        const formattedOptions = response?.data?.results?.map((asset) => ({
          value: asset.id,
          label: asset?.asset_code + " - " + asset.asset_name,
        }));
        setAssetsOptions(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const fetchUsersList = async () => {
    setLoader(true);
    try {
      if (role === "admin") {
        const response = await dispatch(
          fetchUsers({ pagination: false, allusers: "yes" })
        );
        if (response?.payload) {
          const formattedOptions = response?.payload?.results?.map((user) => ({
            value: String(user.id),
            label:
              user?.employee_number +
              " - " +
              user.first_name +
              " " +
              user.last_name,
          }));
          setUserList(formattedOptions);
        } else {
          console.error("Failed! Something went wrong.");
        }
      } else {
        const createdFor = [
          {
            value: userData?.id,
            label: userData?.fname + "  " + userData?.lname,
          },
        ];
        setUserList(createdFor);
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
    helperText: formik.touched[field.name] && formik.errors[field.name] && (
      <FormHelperText className="error-message">
        {formik.errors[field.name]}
      </FormHelperText>
    ),
  });

  const validationSchema = Yup.object().shape({
    asset_id: Yup.string().required("Please select asset"),
    title: Yup.string().required("Please enter ticket title"),
    description: Yup.string().required("Please enter ticket description"),
    created_for: Yup.string().required("Please select ticket assignee"),
    request_date: Yup.string().required("Please select request date"),
  });

  
  const formik = useFormik({
    initialValues: {
      asset_id: state?.asset===null?"":state?.asset+"",
      title: "",
      description: "",
      created_for: state===null?"":userData?.id,
      request_date: "",
      status: "new",
    },
    

   
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        setLoader(true);
        dispatch(createAssetTicket(values)).then((response) => {
          if (response?.success) {
            successToast(
              response?.message || "Asset ticket added successfully"
            );
            navigate("/asset-tickets");
          } else {
            console.error("Failed! Something went wrong.");
          }
        });
      } catch (err) {
        console.error("API call error:", err);
      }
      setLoader(false);
    },
  });

  console.log(userList.find(
    (option) => option.value === formik.values.created_for
  ),"FOUND")

  console.log(formik.values,"FORMIK VALUES")

  useEffect(() => {
    fetchAssets();
    fetchUsersList();
  }, []);


  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/asset-tickets")}
          ></Button>
          Create Asset Ticket
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper" sx={{ height: "100%" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Asset
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  placeholder="Select Asset"
                  // isMulti
                  name="asset_id"
                  options={assetsOptions}
                  className={`muilt-select-field ${
                    formik.touched.asset_id && formik.errors.asset_id
                      ? "has-error"
                      : ""
                  }`}
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                  onBlur={formik.handleBlur}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("asset_id", selectedOption?.value);
                    formik.setFieldTouched("asset_id", false);
                  }}
                  value={assetsOptions.find(
                    (option) => option.value === parseInt(formik.values.asset_id)
                  )}
                  isSearchable //optional
                />
                {formik.touched.asset_id && formik.errors.asset_id && (
                  <FormHelperText className="error-message">
                    {formik.errors.asset_id}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Title
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={() => formik.setFieldTouched("title", false)}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={
                    formik.touched.title && (
                      <span className="error-message">
                        {formik.errors.title}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Description
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  multiline
                  rows={4.5}
                  className="input-field"
                  {...getFieldProps({
                    name: "description",
                    required: true,
                    placeholder: "Add Description",
                  })}
                  onBlur={() => formik.setFieldTouched("description", false)}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body1" component="label">
                      Created For
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Select
                      placeholder="Select Created For"
                      // isMulti
                      name="created_for"
                      options={userList}
                      className={`muilt-select-field ${
                        formik.touched.created_for && formik.errors.created_for
                          ? "has-error"
                          : ""
                      }`}
                      classNamePrefix="select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "var(--green)",
                        },
                      })}
                      onBlur={formik.handleBlur}
                      onChange={(selectedOption) => {
                        formik.setFieldValue(
                          "created_for",
                          selectedOption?.value
                        );
                        formik.setFieldTouched("created_for", false);
                      }}
                      value={userList.find(
                        (option) => option.value === formik.values.created_for
                      )}
                      isSearchable //optional
                    />
                    {formik.touched.created_for &&
                      formik.errors.created_for && (
                        <FormHelperText className="error-message">
                          {formik.errors.created_for}
                        </FormHelperText>
                      )}
                  </Box>
                  <Box>
                    <Typography variant="body1" component="label">
                      Request Date
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        renderInput={(props) => (
                          <TextField
                            {...props}
                            fullWidth
                            id="outlined-basic-date"
                            label="Request Date"
                            variant="outlined"
                            size="small"
                            placeholder="Select request Date"
                            name="request_date"
                            value={formik.values.request_date}
                            onBlur={() =>
                              formik.setFieldTouched("request_date", false)
                            }
                          />
                        )}
                        className={`input-field ${
                          formik.touched.request_date &&
                          formik.errors.request_date
                            ? "error"
                            : ""
                        }`}
                        onChange={(date) => {
                          formik.setFieldValue("request_date", date);
                        }}
                        maxDate={new Date(moment())}
                        format="dd-MM-yyyy"
                      />
                      {formik.touched.request_date &&
                        formik.errors.request_date && (
                          <FormHelperText className="error-message">
                            {formik.errors.request_date}
                          </FormHelperText>
                        )}
                    </LocalizationProvider>
                  </Box>                  
                </Stack>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="footer-wrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className="primary-btn btn w-120"
                    disableRipple
                    disabled={loader}
                    endIcon={
                      loader && <CircularProgress size={20} color="inherit" />
                    }
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn w-120"
                    disableRipple
                    onClick={() => {
                      navigate("/asset-tickets");
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AssetTicketsCreate;
