import React, { useCallback, useEffect, useState } from "react";

import {
  Box,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiArrowDownSLine,
  RiCloseCircleLine,
  RiSearchLine,
} from "react-icons/ri";
import { useLocation, useNavigate } from "react-router-dom";
import PurchaseRequestList from "./PurchaseRequestList";
import { updateRequest } from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";
import { styled } from "@mui/material/styles";

const PurchaseRequest = () => {
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      value: 0,
      currentPage: 1,
      rowperPage: 10,
      searchRequest: "",
      asset_company: "0",
    };
  }
  const navigate = useNavigate();
  const [searchRequest, setsearchRequest] = useState(state.searchRequest);
  const [currentPage, setcurrentPage] = useState(state.currentPage);
  const [rowperPage, setRowsPerPage] = useState(state.rowperPage);
  const [value, setValue] = useState(state.value);
  const [asset_company, setAsset_company] = useState(state.asset_company);
  const handleCompanyChange = (event) => {
    setAsset_company(event.target.value);
    setcurrentPage(1);
  };
  const clearSearchHandler = () => {
    setsearchRequest("");
    setcurrentPage(1);
    setRowsPerPage(10);
  };
  const handlePageChange = (newPage) => {
    setcurrentPage(newPage);
  };

  const handleSearchRequest = (s) => {
    setsearchRequest(s);
    setcurrentPage(1);
  };
  useCallback(() => {
    if (currentPage > 1) {
      setcurrentPage(1);
    }
  }, [searchRequest, value]);
  const CustomSelect = styled(Select)(({ theme }) => ({
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Hide the border
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Hide the border on hover
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent", // Hide the border when focused
    },
    "& .MuiSelect-select": {
      fontSize: "15px", // Reduce font size
    },
  }));
  return (
    <>
      {/*  START :: PURCHASE REQUEST VIEW */}
      <Box className="content-header project-list-header purchage-request-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          Purchase Requests
        </Typography>

        <Box variant="div" component="div" className="content-header-right">
          {/* <Box className='first-box'> */}
          <CustomSelect
            id="demo-simple-select"
            className=" select-company"
            value={asset_company}
            onChange={handleCompanyChange}
            classNamePrefix="select"
            size="small"
            IconComponent={RiArrowDownSLine}
            sx={{
              width: "225px",
              backgroundColor: "white",
              borderRadius: "8px",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
              // border: "0",
            }}
          >
            <MenuItem value={"0"} sx={{ fontSize: "15px" }}>
              All Companies
            </MenuItem>
            <MenuItem value={"1"} sx={{ fontSize: "15px" }}>
              Crest Infosystems Pvt. Ltd.
            </MenuItem>
            <MenuItem value={"2"} sx={{ fontSize: "15px" }}>
              Crest Skillserve Pvt. Ltd.
            </MenuItem>
          </CustomSelect>
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic-asset-search"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchRequest(e.target.value)}
              value={searchRequest}
            />
            {searchRequest && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>

          {/* </Box> */}
          <WithPermission
            permission={updateRequest}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn add-purchase"
                endIcon={<RiAddCircleLine />}
                disableRipple
                onClick={() => navigate("/purchase-request/create")}
              >
                Create Purchase Request
              </Button>
            }
          />
        </Box>
      </Box>
      <Box className="content-layout">
        <PurchaseRequestList
          searchRequest={searchRequest}
          value={value}
          onValueChange={setValue}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          rowperPage={rowperPage}
          onChangerowsPerPage={setRowsPerPage}
          asset_company={asset_company}
        />
      </Box>
      {/* END :: PURCHASE REQUEST VIEW */}
    </>
  );
};

export default PurchaseRequest;
