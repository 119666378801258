import { Box, Button, Grid, Typography, Stack} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { RiArrowLeftLine } from "react-icons/ri";
import { useDispatch} from "react-redux";
import { fetchUsers } from "../../../store/slices/userSlice";
import axios from "axios";
import { createSetting } from "../../../store/slices/CreateActivitySetting";
import { successToast } from "../../../response/successToast";

const ActivitySetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [members, setMembers] = useState([]);
  const [memberspayload, setMembersPayload] = useState([]);
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const fetchUsersList = async () => {
  
    try {
      const response = await dispatch(
        fetchUsers({ pagination: false, allusers: "yes" })
      );
      if (response?.payload) {
        const formattedOptions = response?.payload?.results?.map((user) => ({
          value: user.id,
          label:
            user.first_name +
            " " +
            user.last_name,
        }));
        setMembers(formattedOptions);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {

    }
  };

  const getAssignedUsers=async()=>{
    try{
      const response=await axios.get(`${process.env.REACT_APP_API_URL}/assignee_setting`)
      const list=response.data.data
      console.log(list)
      const formattedOptions=list.map((item)=>({
        value:item.assignee_user_id,
        label:item.users.first_name+" "+item.users.last_name
      }))
      console.log(formattedOptions)
      setMembersPayload(formattedOptions)

    }
    catch(error){
      console.log(error)
    }

  }

  useEffect(()=>{
    fetchUsersList()
    getAssignedUsers()
  },[])

  const handleOptionChange = (selectedOptions) => {
    setMembersPayload(selectedOptions);
  };

  const submitHandler = async () => {
    const selectedUserIds=memberspayload.map((item)=>item.value)
    const allUserIds=members.map((item)=>item.value)
    const deletedUserIds=allUserIds.filter((item)=>!selectedUserIds.includes(item))
    
    const payload={
      "assignee_user_id":selectedUserIds,
      "delete_assignee_user_id":deletedUserIds
    }

    try {
      const response = await dispatch(createSetting(payload));
      if (response.success) {
        successToast("Email settings updated successfully");
        setRemoveKeyId([]);
      } else {
        console.error("Error creating bid:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
    }
  };

  return (
    <>
      {/*  START :: KEYWORDS SETTING PAGE */}
        <Box className="content-header">
          <Typography variant="h2" component="h2" className="title-with-btn">
            <Button
              variant="outlined"
              className="action-btn back-btn"
              startIcon={<RiArrowLeftLine />}
              disableRipple
              onClick={() => navigate("/settings")}
            ></Button>
            Notifications
          </Typography>
        </Box>
        <Box className="content-wrapper">
          <Box className="card-wrapper p-24">
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="h3" component="h3" className="card-title" style={{ marginBottom: "20px" }} >Asset ticket resolution email </Typography>

                <Typography variant="body1" component="label">
                  Email Recipients
                </Typography>
                <Select
                  isMulti
                  name="colors"
                  options={members}
                  value={memberspayload}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                      setRemoveKeyId([...removeKeyId, removedOptionId]);
                    }
                    handleOptionChange(selectedOptions);
                  }}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  placeholder="Select Additional Recepients"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

      {/* END :: KEYWORDS SETTING PAGE */}
      <Box className="footer-wrapper">
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={submitHandler}
          // disabled={isButtonDisabled}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/settings")}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default ActivitySetting;
