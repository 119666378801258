import api from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchUsersById = createAsyncThunk(
  "users/fetchUsersById",
  async ({ userId }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/users/${userId}`);
      // console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  users: null,
  status: "idle",
  error: null,
};
const userByIdSlice = createSlice({
  name: "usersbyId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsersById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("users/fetchUsers/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default userByIdSlice.reducer;
