import React, { useEffect, useState } from "react";
import { Button, Box, Grid, Typography, Divider, Link } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { fectchVendorById } from "../../../store/slices/vendorSlice";
import Loader from "../../../components/common/Loader";

const VendorView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [vendorData, setVendorData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fectchVendorById(id));
      if (response?.success) {
        setVendorData(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {/* START :: VENDOR HEADER */}
      <Box className="content-header view-lead-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/vendors")}
          ></Button>
          Details for Vendor
        </Typography>
      </Box>
      {/* END :: VENDOR HEADER */}

      {/* START :: VENDOR CONTENT */}
      <Box className="content-wrapper">
        {loader ? (
          <Loader />
        ) : (
          <Box className="card-wrapper">
            <Grid container spacing={2} className="view-info">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h3" component="h3" className="card-title">
                  {vendorData?.company_name} 
                </Typography>
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="body1" component="label">
                  Contact Person
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {vendorData?.contact_person?.trim()&&vendorData?.contact_person!=="Nil"?vendorData?.contact_person:"-"}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                  <Divider className="divider" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="body1" component="label">
                  Address
                </Typography>
                <Typography variant="body1" component="span" className="value description">
                  {vendorData?.address.trim()?vendorData?.address:"-"}
                </Typography>
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider className="divider" />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="body1" component="label">
                  Website
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {vendorData && vendorData.website_url.trim() ? (
                    <Link
                      href={
                        vendorData.website_url.startsWith("http")
                          ? vendorData.website_url
                          : `https://${vendorData.website_url}`
                      }
                      target="_blank"
                      underline="always"
                      sx={{wordBreak:"break-all"}}
                    >
                      {vendorData.website_url}
                    </Link>
                  ) : "-"}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                  <Divider className="divider" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="body1" component="label">
                  City
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {vendorData?.city.trim()?vendorData?.city:"-"}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider className="divider" />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="body1" component="label">
                  Email Id
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {vendorData?.email.trim()?vendorData?.email:"-"}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                  <Divider className="divider" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="body1" component="label">
                  State
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {vendorData?.state.trim()?vendorData?.state:"-"}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider className="divider" />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="body1" component="label">
                  Contact No.
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {vendorData?.phone_number.trim()&&vendorData?.phone_number!=="Nil"?vendorData?.phone_number:"-"}
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                  <Divider className="divider" />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography variant="body1" component="label">
                  Zipcode
                </Typography>
                <Typography variant="body1" component="span" className="value">
                  {vendorData?.zipcode.trim()?vendorData?.zipcode:"-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      {/* END :: VENDOR CONTENT */}
    </>
  );
};

export default VendorView;
