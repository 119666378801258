import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";

const initialState = {
  assetCategory: [],
  isLoading: false,
  success: false,
  error: null,
};

const assetCategorySlice = createSlice({
  name: "assetCategory",
  initialState,
  reducers: {
    createAssetCategoryStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createAssetCategorySuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.assetCategory.push(action.payload);
    },
    createAssetCategoryFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editAssetCategoryStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null; 
    },
    editAssetCategorySuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      const categoryIndex = state.assetCategory.findIndex(
        (category) => category.id === action.payload.id
      );
      if (categoryIndex !== -1) {
        state.assetCategory[categoryIndex] = action.payload;
      }
    },
    editAssetCategoryFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteAssetCategoryStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteAssetCategorySuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.assetCategory = state.assetCategory.filter(
      //   (category) => category.id !== action.payload
      // );
    },
    deleteAssetCategoryFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchAssetCategoryStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchAssetCategorySuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.assetCategory = action.payload;
    },
    fetchAssetCategoryFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchAssetCategoryByIdStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchAssetCategoryByIdSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.assetCategory.push(action.payload);
    },
    fetchAssetCategoryByIdFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createAssetCategoryStart,
  createAssetCategorySuccess,
  createAssetCategoryFailure,
  editAssetCategoryStart,
  editAssetCategorySuccess,
  editAssetCategoryFailure,
  deleteAssetCategoryStart,
  deleteAssetCategorySuccess,
  deleteAssetCategoryFailure,
  fetchAssetCategoryStart,
  fetchAssetCategorySuccess,
  fetchAssetCategoryFailure,
  fetchAssetCategoryByIdStart,
  fetchAssetCategoryByIdSuccess,
  fetchAssetCategoryByIdFailure,
} = assetCategorySlice.actions;

export default assetCategorySlice.reducer;

export const createAssetCategory = (categoryData) => async (dispatch) => {
  try {
    dispatch(createAssetCategoryStart());

    const response = await api.post(`/assetCategories`, categoryData);

    const category = response;
    dispatch(createAssetCategorySuccess(category?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(createAssetCategoryFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const editAssetCategory = (categoryData,id) => async (dispatch) => {
  try {
    dispatch(editAssetCategoryStart());

    const response = await api.patch(`/assetCategories/${id}`, categoryData);

    const category = response?.data;
    // category.id = id;
    // dispatch(editAssetCategorySuccess(category));
    return { success: true, message: category?.message };
  } catch (error) {
    dispatch(editAssetCategoryFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const deleteAssetCategory = (categoryId) => async (dispatch) => {
  try {
    dispatch(deleteAssetCategoryStart());

    const response = await api.delete(`/assetCategories/${categoryId}`);

    dispatch(deleteAssetCategorySuccess(categoryId));
    return { success: true, message:  response?.message };
  } catch (error) {
    dispatch(deleteAssetCategoryFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const fetchAssetCategory = (page = 1,search,listing = false,rowperpage=10) => async (dispatch) => {
  try {
    dispatch(fetchAssetCategoryStart());

    let url = `/assetCategories?listing=${listing}&page=${page}&rows=${rowperpage}`;
    if (search) {
      url += `&search=${search}`;
    }

    const response = await api.get(url);
    const Category = response;
    dispatch(fetchAssetCategorySuccess(Category?.data));
    return { success: true, data: Category?.data };
  } catch (error) {
    dispatch(fetchAssetCategoryFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};


export const fetchAssetCategoryById = (categoryId) => async (dispatch) => {
  try {
    dispatch(fetchAssetCategoryByIdStart());

    const response = await api.get(`/assetCategories/${categoryId}`);

    const category = response?.data;
    dispatch(fetchAssetCategoryByIdSuccess(category));
    return { success: true, message:response?.message ,data:category};
  } catch (error) {
    dispatch(fetchAssetCategoryByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};





