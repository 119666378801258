import {
  Box,
  Fade,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";

const ChangepasswordModal = ({ open, handleClose }) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                  <Typography variant="h3" component="h3">
                  Change Password
                  </Typography>
                  <Typography variant="body1" component="p">
                  Strengthen your account security with a new password.
                  </Typography>
              </Box>
              <Box variant="div" component="div" className="body">
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Box variant="div" component="div">
                        <Typography variant="body1" component="label">
                          Current Password
                            <Typography variant="body1" component="span" className="asterisk">*</Typography>
                        </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Enter Current Password"
                        size="small"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Box variant="div" component="div">
                        <Typography variant="body1" component="label">
                          New Password
                            <Typography variant="body1" component="span" className="asterisk">*</Typography>
                        </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Enter New Password"
                        size="small"
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <Box variant="div" component="div">
                      <Typography variant="body1" component="label">
                          Confirm Password
                            <Typography variant="body1" component="span" className="asterisk">*</Typography>
                        </Typography>
                      <TextField
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Confirm Password"
                        size="small"
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                  >
                    {" "}
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={handleClose}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ChangepasswordModal;
