import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";

const initialState = {
  purchaseRequest: [],
  isLoading: false,
  success: false,
  error: null,
};

const purchaseRequestSlice = createSlice({
  name: "purchaseRequest",
  initialState,
  reducers: {
    createPurchaseRequestStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createPurchaseRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.purchaseRequest.push(action.payload);
    },
    createPurchaseRequestFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editPurchaseRequestStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null; 
    },
    editPurchaseRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      const categoryIndex = state.purchaseRequest.findIndex(
        (category) => category.id === action.payload.id
      );
      if (categoryIndex !== -1) {
        state.purchaseRequest[categoryIndex] = action.payload;
      }
    },
    editPurchaseRequestFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deletePurchaseRequestStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deletePurchaseRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.purchaseRequest = state.purchaseRequest.filter(
      //   (category) => category.id !== action.payload
      // );
    },
    deletePurchaseRequestFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchPurchaseRequestStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchPurchaseRequestSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.purchaseRequest = action.payload;
    },
    fetchPurchaseRequestFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchPurchaseRequestByIdStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchPurchaseRequestByIdSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.purchaseRequest.push(action.payload);
    },
    fetchPurchaseRequestByIdFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchPurchaseRequestActivityStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchPurchaseRequestActivitySuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      // state.Asset = action.payload;
    },
    fetchPurchaseRequestActivityFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createPurchaseRequestStart,
  createPurchaseRequestSuccess,
  createPurchaseRequestFailure,
  editPurchaseRequestStart,
  editPurchaseRequestSuccess,
  editPurchaseRequestFailure,
  deletePurchaseRequestStart,
  deletePurchaseRequestSuccess,
  deletePurchaseRequestFailure,
  fetchPurchaseRequestStart,
  fetchPurchaseRequestSuccess,
  fetchPurchaseRequestFailure,
  fetchPurchaseRequestByIdStart,
  fetchPurchaseRequestByIdSuccess,
  fetchPurchaseRequestByIdFailure,
  fetchPurchaseRequestActivityStart,
  fetchPurchaseRequestActivitySuccess,
  fetchPurchaseRequestActivityFailure,
} = purchaseRequestSlice.actions;

export default purchaseRequestSlice.reducer; 

export const createPurchaseRequest = (categoryData) => async (dispatch) => {
  try {
    dispatch(createPurchaseRequestStart());

    const response = await api.post(`/purchaseRequest`, categoryData);

    const category = response;
    dispatch(createPurchaseRequestSuccess(category?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(createPurchaseRequestFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const editPurchaseRequest = (categoryData,id) => async (dispatch) => {
  try {
    console.log("data",categoryData,id)
    dispatch(editPurchaseRequestStart());

    const response = await api.patch(`/purchaseRequest/${id}`, categoryData);

    const category = response?.data;
    // category.id = id;
    // dispatch(editPurchaseRequestSuccess(category));
    return { success: true, message: category?.message };
  } catch (error) {
    dispatch(editPurchaseRequestFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const deletePurchaseRequest = (requestId) => async (dispatch) => {
  try {
    dispatch(deletePurchaseRequestStart());

    const response = await api.delete(`/purchaseRequest/${requestId}`);

    dispatch(deletePurchaseRequestSuccess(requestId));
    return { success: true, message:  response?.message };
  } catch (error) {
    dispatch(deletePurchaseRequestFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const fetchPurchaseRequest = (page = 1,search,status,order,totalCount,asset_company) => async (dispatch) => {
  try {
    dispatch(fetchPurchaseRequestStart());

    let url = `/purchaseRequest?status=${status}&page=${page}&rows=${totalCount}&order=${order}&asset_company=${asset_company}`;
    if (search) {
      url += `&search=${search}`;
    }

    const response = await api.get(url);
    const Category = response;
    dispatch(fetchPurchaseRequestSuccess(Category?.data));
    return { success: true, data: Category?.data };
  } catch (error) {
    dispatch(fetchPurchaseRequestFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};


export const fetchPurchaseRequestById = (requestId) => async (dispatch) => {
  try {
    dispatch(fetchPurchaseRequestByIdStart());

    const response = await api.get(`/purchaseRequest/${requestId}`);

    const category = response?.data;
    dispatch(fetchPurchaseRequestByIdSuccess(category));
    return { success: true, message:response?.message ,data:category};
  } catch (error) {
    dispatch(fetchPurchaseRequestByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};

export const fetchPurchaseRequestActivity = (requestId,para) => async (dispatch) => {
  try {
    dispatch(fetchPurchaseRequestActivityStart());
    const response = await api.get(`/activity-log/purchaseRequest/${requestId}?action=${para}`);
    const asset = response?.data;
    dispatch(fetchPurchaseRequestActivitySuccess(asset));
    return { success: true, message: response?.message, data: asset };
  } catch (error) {
    dispatch(fetchPurchaseRequestActivityFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};




