import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FiActivity } from "react-icons/fi";
import {ReactComponent as AssetTicketReportIcon} from "../../../assets/images/icons/asset-ticket-details.svg"
import {ReactComponent as AssetReportIcon} from "../../../assets/images/icons/asset-detail-valuation.svg"
const ReportCard = ({ title, description, icon, link }) => {
  const navigate = useNavigate();

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} className="report-grid-list">
      <Box className="report-card" onClick={() => navigate(link)}>
        <Box className="report-card-content">
          <Typography variant="h6" component="h6" className="text-white setting-name">
            {title}
          </Typography>
          <Typography className="text-white text-desc" sx={{fontSize: "15px", fontWeight: "500"}}>
            {description}
          </Typography>
        </Box>
        <Box className="setting-icon">{icon}</Box>
      </Box>
    </Grid>
  );
};

const AssetReportsList = () => {
  return (
    <>
      <Box variant="div" component="div" className="activity-card-wrapper">
        <Grid container spacing={3}>
          <ReportCard
            title="Asset Details & Valuation"
            description="See the Category-wise details of assets and their valuation."
            icon={<AssetReportIcon />}
            link="/reports/asset"
          />
          <ReportCard
            title="Asset Tickets Details"
            description="See the details of asset tickets and their maintainance costs."
            icon={<AssetTicketReportIcon />}
            link="/reports/assets-ticket"
          />
        </Grid>
      </Box>
    </>
  );
};

export default AssetReportsList;
