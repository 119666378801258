import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  Box,
  Pagination,
  Skeleton,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RiEyeLine } from "react-icons/ri";
import { viewUsers } from "../../../utils/permissions";
import WithPermission from "../../../components/common/withPermission";
import { CustomTablePaginationToolbar } from "../../../components/common/Pagination";

function CustomTabPanel(props) {
  const { children, value, index, usersPerPage, currentPage, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabLabel = (role, modules) => {
  return (
    <Box>
      <Typography variant="body1" component="span" className="user-name">
        {role}
      </Typography>{" "}
    </Box>
  );
};

const UserList = ({
  onPageChange,
  searchUser,
  onValueChange,
  currentPage,
  value,
  rowperPage,
  onChangerow,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      value: 0,
      currentPage: 1,
      rowperPage: 10,
      searchUser: "",
    };
  }
  const [loading] = useState(false);
  const users = useSelector((state) => state.users.users);

  // START :: DELETE MODAL
  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  const handleChangeRowsPerPage = (event) => {
    onChangerow(parseInt(event.target.value, 10));
    onPageChange(1);
  };

  const handleChange = (event, newValue) => {
    onValueChange(newValue);
  };

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
  };

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-area"
          >
            <Tab label={tabLabel("Active")} {...a11yProps(0)} />

            <Tab label={tabLabel("Archived")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        {loading ? (
          Array.from({ length: 6 }).map((_, index) => (
            <Table>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={`skeleton-${index}`}
                >
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width={40} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ))
        ) : users?.results?.length > 0 ? (
          <>
            <TableContainer className="table-listing">
              <Table
                sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Employee ID</TableCell>
                    <TableCell align="left">Employee Name</TableCell>
                    <TableCell align="left">Email Id</TableCell>
                    <TableCell align="left">Contact No</TableCell>
                    <TableCell align="left">Company</TableCell>
                    <TableCell align="left">Role</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users?.results?.map((user) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={user.id}
                    >
                      <TableCell align="left">
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                        >
                          {user.employee_number ?? "-"}
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        {capitalizeFirstLetter(user.first_name)}{" "}
                        {capitalizeFirstLetter(user.last_name)}
                      </TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{user.phone}</TableCell>
                      <TableCell align="left">
                        {user.companies?.company_name ?? "-"}
                      </TableCell>
                      <TableCell align="left">
                        {user.user_role?.title}
                      </TableCell>

                      <TableCell align="left">
                        <Box className="action-btn-wrap">
                          <WithPermission
                            permission={viewUsers}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn view-btn"
                                startIcon={<RiEyeLine />}
                                disableRipple
                                title="View"
                                onClick={() =>
                                  navigate(`/users/${user.id}/view`, {
                                    state: {
                                      value: value,
                                      currentPage: currentPage,
                                      searchUser: searchUser,
                                      rowperPage: rowperPage,
                                    },
                                  })
                                }
                              ></Button>
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomTablePaginationToolbar
              count={users?.totalCount}
              page={currentPage}
              rowsPerPage={rowperPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <>
            {" "}
            <TableContainer className="table-listing">
              <Table
                sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Employee ID</TableCell>
                    <TableCell align="left">Employee Name</TableCell>
                    <TableCell align="left">Email Id</TableCell>
                    <TableCell align="left">Contact No</TableCell>
                    <TableCell align="left">Role</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="no-msg">
                    <TableCell
                      colSpan={7}
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: "var(--textgrey)",
                        fontSize: "16px",
                      }}
                      className="no-msg"
                    >
                      Users Not Found
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </>
  );
};

export default UserList;
