import { Box, Fade, Modal, Typography, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import { successToast } from "../../response/successToast";
import { deleteCompanyBranch } from "../../store/slices/companyBranchSlice";

const CompanyBranchDeleteModal = ({
  companyBranchDelete,
  setCompanyBranchDelete,
  companyBranchId,
  setCompanyBranchId,
  fetchData,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const companyBranchDeleteModalClose = () => {
    setCompanyBranchDelete(!companyBranchDelete);
    setCompanyBranchId("");
  };

  const onDeleteCompanyBranch = async () => {
    setLoader(true);
    try {
      const response = await dispatch(deleteCompanyBranch(companyBranchId));

      if (response?.success) {
        companyBranchDeleteModalClose();
        successToast(
          response?.message || "Company Branch Deleted Successfully"
        );
        fetchData();
      } else {
        console.error("Failed! Something went wrong.");
        setCompanyBranchDelete(!companyBranchDelete);
        setCompanyBranchId("");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={companyBranchDelete}
        onClose={companyBranchDeleteModalClose}
        closeAfterTransition
      >
        <Fade in={companyBranchDelete}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to delete this branch?
                </Typography>
                <Typography variant="body1" component="p">
                  This action can’t be undone.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={onDeleteCompanyBranch}
                    disabled={loader}
                    endIcon={
                      loader && <CircularProgress size={20} color="inherit" />
                    }
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setCompanyBranchDelete(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CompanyBranchDeleteModal;
