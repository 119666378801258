import React from "react";
import {Box} from "@mui/material";

const Footer = () => {  

  return (
    <>

    {/*  START :: CREATE ASSET TICKET FOOTER FROM ASSET TICKET MODULE */}
      <Box className={window.location.pathname === '/asset-ticket/create' ?  'footer-wrapper' : 'footer-wrapper d-none'} >
      </Box>
    {/*  END :: CREATE ASSET TICKET FOOTER FROM ASSET TICKET MODULE */}

    {/*  START :: EDIT ASSET TICKET FOOTER */}
      <Box className={window.location.pathname === '/asset-ticket/EDIT' ?  'footer-wrapper' : 'footer-wrapper d-none'} >
      </Box>
    {/*  END :: EDIT ASSET TICKET FOOTER */}
    </>

    
  );
};

export default Footer;
