import React from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  TextareaAutosize,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { successToast } from "../../../response/successToast";
import {
  editVendor,
  fectchVendorById,
} from "../../../store/slices/vendorSlice";

const validationSchema = Yup.object().shape({
  company_name: Yup.string().trim().required("Please enter company name"),
  contact_person: Yup.string().trim().required("Please enter contact person name"),
  phone_number: Yup.string()
    .required("Please enter contact number")
    .matches(/^\d+$/, "Must be a valid phone number"),
  website_url: Yup.string()
    .matches(
      /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
      "Must be a valid URL"
    ),
  email: Yup.string()
    .email("Must be a valid email"),
  address: Yup.string().trim().required("Please enter address of the company"),
  city: Yup.string().trim().required("Please enter city"),
  state: Yup.string().trim().required("Please enter state"),
  zipcode: Yup.string()
    .required("Please enter zipcode")
    .matches(/^\d{6}$/, "Must be a valid 6-digit zipcode"),
});

const VendorEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [vendor, setVendor] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, [id]);
  useEffect(() => {
    formik.setValues({
      company_name: vendor?.company_name,
      contact_person: vendor?.contact_person&&vendor?.contact_person!=="Nil"?vendor?.contact_person:"",
      phone_number: vendor?.phone_number&&vendor?.phone_number!=="Nil"?vendor?.phone_number:"",
      website_url: vendor?.website_url,
      email: vendor?.email,
      address: vendor?.address,
      city: vendor?.city,
      state: vendor?.state,
      zipcode: vendor?.zipcode,
    });
  }, [vendor]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fectchVendorById(id));
      if (response?.success) {
        setVendor(response?.data);
      } else {
        console.error("Failed ! Something went wrong");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      company_name: "",
      contact_person: "",
      phone_number: "",
      website_url: "",
      email: "",
      address: "",
      city: "",
      state: "",
      zipcode: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoader(true);
      dispatch(editVendor(values, id))
        .then((response) => {
          if (response?.success) {
            successToast(response?.message || "Vendor Updated Successfully");
            navigate("/vendors");
          } else {
            console.error("Failed!, something went wrong");
          }
        })
        .catch((error) => {
          console.error("API CALL error:", error);
        });
      setLoader(false);
    },
  });

  const getFieldProps = (field) => ({
    id: `outlined-basic-${field.name}`,
    fullWidth: true,
    variant: "outlined",
    size: "small",
    placeholder: field.placeholder || `Enter ${field.label}`,
    name: field.name,
    value: formik.values[field.name],
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    error: formik.touched[field.name] && Boolean(formik.errors[field.name]),
    helperText: formik.touched[field.name] && formik.errors[field.name] && (
      <FormHelperText className="error-message">
        {formik.errors[field.name]}
      </FormHelperText>
    ),
  });

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/vendors")}
          ></Button>
          Edit Vendor
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper" sx={{ height: "100%" }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body1" component="label">
                      Company Name
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company's Name"
                      name="company_name"
                      value={formik.values.company_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.company_name &&
                        Boolean(formik.errors.company_name)
                      }
                      helperText={
                        formik.touched.company_name && (
                          <span className="error-message">
                            {formik.errors.company_name}
                          </span>
                        )
                      }
                    />
                  </Box>
                  <Box>
                    <Typography variant="body1" component="label">
                      Contact Person Name
                      <Typography
                        variant="body1"
                        component="label"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person Name"
                      name="contact_person"
                      value={formik.values.contact_person}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.contact_person &&
                        Boolean(formik.errors.contact_person)
                      }
                      helperText={
                        formik.touched.contact_person && (
                          <span className="error-message">
                            {formik.errors.contact_person}
                          </span>
                        )
                      }
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Address
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  multiline
                  rows={4.5}
                  className="input-field"
                  {...getFieldProps({
                    name: "address",
                    required: true,
                    placeholder: "Enter Address",
                  })}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Contact No
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Contact No"
                  name="phone_number"
                  value={formik.values.phone_number}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phone_number &&
                    Boolean(formik.errors.phone_number)
                  }
                  helperText={
                    formik.touched.phone_number && (
                      <span className="error-message">
                        {formik.errors.phone_number}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  City
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter City"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={
                    formik.touched.city && (
                      <span className="error-message">
                        {formik.errors.city}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Website
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Website"
                  name="website_url"
                  value={formik.values.website_url}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.website_url &&
                    Boolean(formik.errors.website_url)
                  }
                  helperText={
                    formik.touched.website_url && formik.errors.website_url
                      ? formik.errors.website_url
                      : null
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  State
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter State"
                  name="state"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={
                    formik.touched.state && (
                      <span className="error-message">
                        {formik.errors.state}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Email Id
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Email Id"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={
                    formik.touched.email && (
                      <span className="error-message">
                        {formik.errors.email}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Zipcode
                  <Typography
                    variant="body1"
                    component="label"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Zipcode"
                  name="zipcode"
                  value={formik.values.zipcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.zipcode && Boolean(formik.errors.zipcode)
                  }
                  helperText={
                    formik.touched.zipcode && (
                      <span className="error-message">
                        {formik.errors.zipcode}
                      </span>
                    )
                  }
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="footer-wrapper">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className="primary-btn btn w-120"
                    disableRipple
                    disabled={loader}
                    endIcon={
                      loader && <CircularProgress size={20} color="inherit" />
                    }
                  >
                    {" "}
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn w-120"
                    disableRipple
                    onClick={() => {
                      navigate("/vendors");
                    }}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default VendorEdit;
