import React, { useState, useEffect } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Tooltip,
  Grid,
  ListItemIcon,
} from "@mui/material";
import {
  RiMenu3Fill,
  RiArrowDownSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import { FiChevronRight, FiUser, FiLogOut } from "react-icons/fi";
import { MdPublishedWithChanges } from "react-icons/md";
import logo from "../../assets/images/ams_logo.svg";
import Favlogo from "../../assets/images/icons/ams-icon.png";
import SiteLogo from "../../assets/images/sitelogo.png";
import userLogo from "../../assets/images/user.png";
import { Link, useNavigate } from "react-router-dom";
import ChangepasswordModal from "../common/Changepasswordmodal";
import SignoutModal from "../common/Signoutmodal";
import { useCookies } from "react-cookie";
import getuserDataFromCookies from "../../utils/userData";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { IoApps } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { handleSidebar } from "../../store/slices/drawerHandlerSlice";

const Header = ({ setOpenSideMenu, openSideMenu, isAdmin }) => {
  const navigate = useNavigate();
  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);
  const dispatch = useDispatch();
  const [cookies] = useCookies();

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [singOutModalOpen, setSingOutModalOpen] = useState(false);
  const [userData, setUserData] = useState(getuserDataFromCookies());

  const closePasswordModal = () => setPasswordModalOpen(false);

  const handleOpenSingOutModal = () => setSingOutModalOpen(true);
  const hanldecloseSingOutModal = () => setSingOutModalOpen(false);

  const userApplicationPermissions = useSelector(
    (state) => state.applicationPermissions.applicationPermissions
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

const renderMenuItems = () => {
  return userApplicationPermissions?.application_details?.map(
    (item, index) => {
      if (item.app_id === 3) return null;
      return (
        <Grid
          item
          xs={4}
          p={0.5}
          sx={{
            "& a": {
              textDecoration: "none", // Ensure no underline for links
            },
          }}
          key={index} // Unique key for each Grid item
        >
          <MenuItem
            onClick={handleClose}
            component={Link}
            to={item.app_url}
            target="_blank"
            key={item.app_id} // Unique key for each Grid item
            sx={{
              padding: "4px",
              "&:hover": {
                borderRadius: "12px",
                backgroundColor: "#f8f9fa",

                "& .MuiTypography-root": {
                  color: "black !important",
                },
              },
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <img
                  src={item.img_link}
                  alt="logo-main"
                  loading="lazy"
                  height="32px"
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: "white !important",
                    fontSize: "14px",
                  }}
                >
                  {item.app_name}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        </Grid>
      );
    }
  );
};
 

  useEffect(() => {
    // This effect will run whenever cookies change
    const updatedCookieUserData = getuserDataFromCookies();
    setUserData(updatedCookieUserData);
  }, [cookies]);

  return (
    <>
      <AppBar
        position="fixed"
        open={sidebarState}
        className={`navbar ${sidebarState ? "" : "sidebar-closed"} `}
      >
        <Toolbar>
          <Box variant="div" component="div" className="navbar-left">
            {isAdmin ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => {
                  dispatch(handleSidebar(true));
                }}
                className="toggle-btn"
              >
                <RiMenu3Fill className="open-menu" />
                <RiArrowRightSLine className="close-menu" />
              </IconButton>
            ) : null}
          </Box>
          <Box variant="div" component="div" className="navbar-right">
            <Typography
              variant="h1"
              noWrap
              component="h1"
              className="page-title"
            >
              AMS
            </Typography>
            <Box variant="div" component="div" className="user-info">
              <Box className="app-button">
                <IoApps
                  size={30}
                  fill="black !important"
                  style={{ marginRight: "1rem" }}
                  id="basic-button"
                  aria-controls={open2 ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={handleClick2}
                />
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    // Target the root Paper component within the Menu
                    backgroundColor: "var(--primary-text-color)", // Change the background color
                    borderRadius: "12px", // Change the border radius
                    border: "2px solid white",
                    marginTop: "15px", // Add some gap from the top
                    marginLeft: "-30px",
                    width: "280px !important",
                  },
                }}
              >
                <Grid container p={1}>
                  {renderMenuItems()}
                </Grid>
              </Menu>
              <Box
                className="release-button"
                onClick={() => navigate("/release-notes")}
              >
                <Tooltip title="Release Notes">
                  <NewReleasesIcon size={28} className="release-icon" />
                </Tooltip>
              </Box>

              <Avatar
                className="avatar-ui"
                src={userData?.imgUrl ? userData?.imgUrl : userData?.first_name}
                onClick={handleClick}
              ></Avatar>

              <Menu
                className="account-dropdown-menu"
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <Box className="user-details">
                  <Box
                    className="user-img"
                    sx={{
                      padding: `${userData?.imgUrl ? "0px" : "8px"}`,
                    }}
                  >
                    <img
                      src={userData?.imgUrl || userLogo}
                      alt="account img"
                      style={{ padding: !userData?.imgUrl ? "8px" : "" }}
                    />
                  </Box>
                  <Typography
                    variant="h5"
                    component="h5"
                    className="text-green user-name"
                    align="center"
                  >
                    {userData?.fname} {userData?.lname}
                  </Typography>
                  <Typography
                    variant="span"
                    component="span"
                    className="text-grey user-position"
                    align="center"
                    // sx={{border:'2px solid red'}}
                  >
                    {userData?.role?.charAt(0).toUpperCase() +
                      userData?.role?.slice(1)}
                  </Typography>
                </Box>
                <hr className="divider-border" />
                <Box className="organization-details">
                  <Box className="org-img">
                    <img src={SiteLogo} alt="account img" />
                  </Box>
                  <Box className="org-text">
                    <Typography
                      variant="h5"
                      component="h5"
                      className="text-green user-name"
                      align="center"
                    >
                      {userData?.companyId === 1
                        ? "Crest Infosystems Pvt. Ltd."
                        : "Crest Skillserve Pvt. Ltd."}
                    </Typography>
                    <Typography
                      variant="span"
                      component="span"
                      className="text-grey user-position"
                      align="center"
                    >
                      Organization
                    </Typography>
                  </Box>
                </Box>
                <Box className="links">
                  <a
                    href={`${process.env.REACT_APP_CENTRAL_URL}profile`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#232323", textDecoration: "none" }}
                    onclick={handleClose}
                  >
                    <MenuItem className="menu-link">
                      <ListItemIcon className="link-icon">
                        <FiUser />
                      </ListItemIcon>
                      <span class="link-text">My Account</span>
                    </MenuItem>
                  </a>
                  <a
                    href={`${process.env.REACT_APP_CENTRAL_URL}change-password`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#232323", textDecoration: "none" }}
                    onclick={handleClose}
                  >
                    <MenuItem className="menu-link">
                      <ListItemIcon className="link-icon">
                        <MdPublishedWithChanges />
                      </ListItemIcon>
                      <span class="link-text">Change Password</span>
                    </MenuItem>
                  </a>
                  <MenuItem
                    className="menu-link"
                    onClick={handleOpenSingOutModal}
                  >
                    <ListItemIcon className="link-icon">
                      <FiLogOut />
                    </ListItemIcon>
                    <span class="link-text">Sign Out</span>
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <ChangepasswordModal
        open={passwordModalOpen}
        handleClose={closePasswordModal}
      />
      <SignoutModal
        open={singOutModalOpen}
        handleClose={hanldecloseSingOutModal}
      />
    </>
  );
};

export default Header;
