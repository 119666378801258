import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  RiArrowLeftLine
} from "react-icons/ri";
import { fetchAssetCategoryById } from "../../../store/slices/assetCategorySlice";
import { useDispatch } from "react-redux";
import Loader from "../../../components/common/Loader";

const AssetCategoryView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [assetCategory, setAssetCategory] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const response = await dispatch(fetchAssetCategoryById(id));
      if (response?.success) {
        setAssetCategory(response?.data);
      } else {
        console.error("Failed! Something went wrong.");
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <>
      {/* START :: ASSET CATEGORY VIEW HEADER */}
      <Box className="content-header view-lead-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/asset-category")}
          ></Button>
          Details for Asset Category
        </Typography>
      </Box>
      {/* END :: ASSET CATEGORY VIEW HEADER */}

      {/* START :: ASSET CATEGORY VIEW CONTENT */}
      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <Grid container spacing={2} className="view-info">
            {loader ? (
              <Loader />
            ) : assetCategory?.length === 0 ? (
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component="h4"
                  className="card-title"
                  sx={{ paddingBottom: "8px",margin: "16px 0" }}
                >
                  <Box className="app-list text-center">Asset Category Not Found</Box>
                </Typography>
              </Grid>
            ) : (
              <>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                    sx={{ paddingBottom: "8px" }}
                  >
                    <Box className="app-list">
                      {assetCategory?.name}
                    </Box>
                  </Typography>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Category Description
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="description"    
                  >
                    {assetCategory?.description?assetCategory?.description:"-"}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
                    <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Depreciation As Per Company Act
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetCategory?.depreciation_company_act} %
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Depreciation As Per IT Act
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {assetCategory?.depreciation_it_act} %
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
      {/* END :: ASSET CATEGORY VIEW CONTENT */}
    </>
  );
};

export default AssetCategoryView;
