import {Box, CircularProgress, Fade, Modal, Typography} from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { successToast } from "../../response/successToast";
import { deleteVendor } from "../../store/slices/vendorSlice";

const VendorDeleteModal = ({vendorDelete, setVendorDelete, vendorId, setVendorId, fetchData}) => {
  const dispatch = useDispatch();
  const [loader,setLoader] = useState(false)

  const vendorDeleteModalClose = () =>{
    setVendorDelete(!vendorDelete)
    setVendorId('')
  }

  const onDeleteVendor = async () => {
    setLoader(true);
    try {
      const response = await dispatch(deleteVendor(vendorId));
     
      if (response?.success) {
        setVendorId('')
        successToast(response?.message || "AssetCategory Deleted Successfully")
        setVendorDelete(!vendorDelete)
        fetchData()
      } else {
        console.error("Failed! Something went wrong.");
        setVendorDelete(!vendorDelete)
        setVendorId('')
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={vendorDelete}
        onClose={vendorDeleteModalClose}
        closeAfterTransition
      >
        <Fade in={vendorDelete}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to delete this vendor?
                </Typography>
                <Typography variant="body1" component="p">
                    This action can’t be undone.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => {onDeleteVendor()}}
                    disabled={loader}
                    endIcon={loader && <CircularProgress size={20} color="inherit" />}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setVendorDelete(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default VendorDeleteModal;
