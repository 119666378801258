import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import api from "../../components/common/commonfunctionforapi";

const initialState = {
  vendor: [],
  isLoading: false,
  success: false,
  error: null,
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    createVendorStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createVendorSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    createVendorFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    editVendorStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    editVendorSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      const vendorIndex = state.vendor.findIndex(
        (item) => item.id === action.payload.id
      );
      if (vendorIndex !== -1) {
        state.vendor[vendorIndex] = action.payload;
      }
    },
    editVendorFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    deleteVendorStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    deleteVendorSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    deleteVendorFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchVendorStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchVendorSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.vendor = action.payload;
    },
    fetchVendorFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
    fetchVendorByIdStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    fetchVendorByIdSuccess: (state, action) => {
      state.isLoading = false;
      state.success = true;
    },
    fetchVendorByIdFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createVendorStart,
  createVendorSuccess,
  createVendorFailure,
  editVendorStart,
  editVendorSuccess,
  editVendorFailure,
  deleteVendorStart,
  deleteVendorSuccess,
  deleteVendorFailure,
  fetchVendorStart,
  fetchVendorSuccess,
  fetchVendorFailure,
  fetchVendorByIdStart,
  fetchVendorByIdSuccess,
  fetchVendorByIdFailure,
} = vendorSlice.actions;
export default vendorSlice.reducer;

export const createVendor = (vendorData) => async (dispatch) => {
  try {
    dispatch(createVendorStart());
    const response = await api.post(`/vendor`, vendorData);
    dispatch(createVendorSuccess(response?.data));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(createVendorFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const editVendor = (vendorData, id) => async (dispatch) => {
  try {
    dispatch(editVendorStart());
    const response = await api.patch(`/vendor/${id}`, vendorData);
    return { success: true, message: response?.data?.message };
  } catch (error) {
    dispatch(editVendorFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const deleteVendor = (vendorId) => async (dispatch) => {
  try {
    dispatch(deleteVendorStart());
    const response = await api.delete(`/vendor/${vendorId}`);
    dispatch(deleteVendorSuccess(vendorId));
    return { success: true, message: response?.message };
  } catch (error) {
    dispatch(deleteVendorFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
    return { success: false, error: error?.response?.data?.message };
  }
};

export const fetchVendor =
  (listing = false, page = 1, search,rowperPage=10) =>
  async (dispatch) => {
    try {
      dispatch(fetchVendorStart());
      let url = `/vendor?listing=${listing}&page=${page}&rows=${rowperPage}`;
      if (search) {
        url += `&search=${search}`;
      }

      const response = await api.get(url);
      const Vendor = response;
      dispatch(fetchVendorSuccess(Vendor?.data));
      return { success: true, data: Vendor?.data };
    } catch (error) {
      dispatch(fetchVendorFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error?.response?.data?.message,
          code: error?.response?.status,
        })
      );
    }
  };

export const fectchVendorById = (vendorId) => async (dispatch) => {
  try {
    dispatch(fetchVendorByIdStart());
    const response = await api.get(`/vendor/${vendorId}`);
    dispatch(fetchVendorByIdSuccess(response?.data));
    return { success: true, message: response?.message, data: response?.data };
  } catch (error) {
    dispatch(fetchVendorByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error?.response?.data?.message,
        code: error?.response?.status,
      })
    );
  }
};
