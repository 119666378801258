import {Box, CircularProgress, Fade, Modal, Typography} from "@mui/material";
import React,{useState} from "react";
import Button from "@mui/material/Button";
import { successToast } from "../../response/successToast";
import { useDispatch } from "react-redux";
import { deleteAssetCategory } from "../../store/slices/assetCategorySlice";

const AssetCategoryDeleteModal = ({assetCategoryDelete, setAssetCategoryDelete,assetCategoryId,setAssetCategoryId,fetchData}) => {
  const dispatch = useDispatch()
  const [loader,setLoader] = useState(false)

  const AssetCategoryDeleteModalClose = () =>{
    setAssetCategoryDelete(!assetCategoryDelete)
    setAssetCategoryId('')
  }

  const onDeleteAssetCategory = async () => {
    setLoader(true);
    try {
      const response = await dispatch(deleteAssetCategory(assetCategoryId));
     
      if (response?.success) {
        setAssetCategoryId('')
        successToast(response?.message || "AssetCategory Deleted Successfully")
        setAssetCategoryDelete(!assetCategoryDelete)
        fetchData()
      } else {
        console.error("Failed! Something went wrong.");
        setAssetCategoryDelete(!assetCategoryDelete)
        setAssetCategoryId('')
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={assetCategoryDelete}
        onClose={AssetCategoryDeleteModalClose}
        closeAfterTransition
      >
        <Fade in={assetCategoryDelete}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to delete this asset category?
                </Typography>
                <Typography variant="body1" component="p">
                    This action can’t be undone.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => {onDeleteAssetCategory()}}
                    disabled={loader}
                    endIcon={loader && <CircularProgress size={20} color="inherit" />}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setAssetCategoryDelete(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AssetCategoryDeleteModal;
