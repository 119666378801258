import {Box, Fade, Modal, Typography} from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { successToast } from "../../response/successToast";
import { deleteAssetTicket } from "../../store/slices/assetTicketSlice";

const AssetTicketsDeleteModal = ({assetTicketsDelete, setAssetTicketsDelete, assetTicketId, setAssetTicketId, fetchData}) => {
  const dispatch = useDispatch()
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false)

  const AssetTicketsDeleteModalClose = () =>{
    setAssetTicketsDelete(!assetTicketsDelete)
    setAssetTicketId('')
  }

  const onDeleteAsset = async () => {
    setLoader(true);
    try {
      const response = await dispatch(deleteAssetTicket(assetTicketId));
     
      if (response?.success) {
        setAssetTicketId('')
        successToast(response?.message || "Asset Deleted Successfully")
        setAssetTicketsDelete(!assetTicketsDelete)
        fetchData()
      } else {
        console.error("Failed! Something went wrong.");
        setAssetTicketsDelete(!assetTicketsDelete)
        setAssetTicketId('')
      }
    } catch (error) {
      console.error("API call error:", error);
    } finally {
      setLoader(false);
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={assetTicketsDelete}
        onClose={AssetTicketsDeleteModalClose}
        closeAfterTransition
      >
        <Fade in={assetTicketsDelete}>
          <Box className="modal-wrapper modal-ui">
            <Box className="modal-body">
              <Box variant="div" component="div" className="title">
                <Typography variant="h3" component="h3">
                  Do you want to delete this asset ticket?
                </Typography>
                <Typography variant="body1" component="p">
                    This action can’t be undone.
                </Typography>
              </Box>
              <Box variant="div" component="div" className="body"></Box>
              <Box variant="div" component="div" className="footer">
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn"
                    disableRipple
                    onClick={() => {onDeleteAsset()}}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    className="secondary-btn btn"
                    disableRipple
                    onClick={() => setAssetTicketsDelete(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AssetTicketsDeleteModal;
