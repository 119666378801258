import React from 'react'
import { Navigate } from 'react-router-dom';
import getTokenFromCookies from '../../utils/token';

const PublicRouteWrapper = ({ children }) => {
    const token = getTokenFromCookies();

    if(token){
        return <Navigate to={'/'} replace={true} />;
    }

    return children;
}

export default PublicRouteWrapper